import React, { useState } from "react";
import styles from "./SelectDeliveryDate.module.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateRangeSharpIcon from "@material-ui/icons/DateRangeSharp";
import lightBlue from "@material-ui/core/colors/lightBlue";

import { blue } from "@material-ui/core/colors";

// to custom style KeyboardDatePicker

const materialTheme = createMuiTheme({
	overrides: {
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: blue.A100,
			},
		},
		MuiPickersCalendarHeader: {
			switchHeader: {
				backgroundColor: blue["900"],
				color: "white",
			},
		},
		MuiPickersDay: {
			day: {
				// color: lightBlue.A700,
				color: blue["600"],
			},
			daySelected: {
				backgroundColor: blue["900"],
			},
			dayDisabled: {
				color: lightBlue["100"],
			},
			current: {
				color: lightBlue["900"],
			},
		},
		MuiPickersModal: {
			dialogAction: {
				color: lightBlue["400"],
			},
		},

		MuiOutlinedInput: {
			root: {
				"& $input": {
					// padding: "5px",
				},
			},
		},
	},
});

function SelectDeliveryDate({
	scheduledDateSelected,
	setScheduledDateSelected,
}) {
	// to open/close date selector
	const [isOpen, setIsOpen] = useState(false);

	const handleDateChange = (date) => {
		const updatedDate = new Date();
		date.setHours(updatedDate.getHours());
		date.setMinutes(updatedDate.getMinutes());
		date.setSeconds(updatedDate.getSeconds());
		setScheduledDateSelected(date?.getTime());

		setIsOpen(false);

		// date.setUTCHours(0, 0, 0);
		// date.setHours(0,0,0,0)

		// setScheduledDateSelected(date?.getTime());
	};

	return (
		<div className={styles.deliveryContainer}>
			<div className={styles.mainContainer}>
				{/* <h3 className={styles.mainTitle}>When will this be delivered?</h3> */}
				<h3 className={styles.mainTitle}>
					When do you want this to be delivered?
				</h3>
				<div className={styles.centerContainer}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ThemeProvider theme={materialTheme}>
							<KeyboardDatePicker
								style={{marginLeft: 60, width: 200, height: 28}}
								minDate={new Date()}
								disablePast={true}
								disableToolbar={true}
								margin="none"
								variant="inline"
								value={scheduledDateSelected}
								inputVariant="outlined"
								emptyLabel="Select Date"
								format="MM/dd/yyyy"
								onChange={handleDateChange}
								KeyboardButtonProps={{
									onFocus: (e) => {
										setIsOpen(true);
									},
								}}
								PopoverProps={{
									disableRestoreFocus: true,
									onClose: () => {
										setIsOpen(false);
									},
								}}
								InputProps={{
									onFocus: () => {
										setIsOpen(true);
									},
								}}
								open={isOpen}
								keyboardIcon={
									<DateRangeSharpIcon style={{ fill: "#2B08A0" }} />
								}
							/>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</div>
			</div>
			<div className={styles.disclaimer}>
				<p>
					{" "}
					Please choose your suggested target date.
				</p> <br />
				<p>Note: The recipient will
					ultimately determine when their Gesture will be received.</p>

				<br />
			</div>
		</div>
	);
}

export default SelectDeliveryDate;
