import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import styles from "../Styles/ContactSingleStyles";

class ContactSingle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.singleContact}>
          <div className={classes.nameFlexbox}>
            <div className={classes.name}>
              {this.props.first} {this.props.last}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ContactSingle);
