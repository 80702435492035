export default {
  mainContainer: {
    fontFamily: "Muli",
  },

  wrapper: {
    width: "900px",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    display: "flex",
    background: "#fff",
    // boxShadow: "0 0 9px 0 rgba(0, 0, 0, 0.15)",
    // borderRadius: "4px",
    flexDirection: "column",
  },

  title: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "20px",
    marginBottom: "25px",
    width: "900px",
    marginRight: "-50px",
  },

  titlename: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },

  layer2: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    // boxShadow: "0 0 9px 0 rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
    marginBottom: "20px",
  },

  layer1: {
    display: "none",
  },

  ".layer2>*": { flex: "0 0 33.333333%" },

  noimport: {
    display: "flex",
  },

  import: {
    display: "none",
  },

  buttonGroup: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    font: "inherit",
  },

  //   // contactContainer: {
  //   //   maxWidth: "1735px",
  //   //   left: "160px",
  //   //   position: "relative",
  //   //   margin: "0 25px",
  //   //   fontFamily:
  //   //     "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',    sans-serif",
  //   // },

  checkBoxContactBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  importbutton: {
    backgroundColor: "#2B08A0",
    color: "white",
    margin: "0px 10px ",
  },

  templatebutton: {
    textDecoration: "none",
    backgroundColor: "#2B08A0",
    margin: "0px 10px",
  },

  addpersonbutton: {
    backgroundColor: "#2B08A0",
    color: "white",
    margin: "0px 10px",
  },

  searchbar: {
    // width: "45vw",

    // marginBottom: "5px",
    // padding: "7.5px",
    width: "900px",
    marginTop: "15px",
    margin: "20px",
    padding: "7.5px",
    borderRadius: "25px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.4) ",
  },

  userIcon: {
    marginRight: "10px",
  },

  box: {
    width: "900px",
    margin: "5px 5px 12.5px 5px",
    padding: "7.5px",
    borderRadius: "7px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.4)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  contactSingle: {
    marginLeft: "2vw",
    wordBreak:"break-word"
  },

  // name: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "flex-end",
  // },

  // nameFlexbox: {
  //   flex: "0 1 auto",
  //   position: "relative",
  //   left: "50%",
  //   transform: "translateX(-50%)",
  // },

  //   tablecell: {
  //     fontWeight: "bold",
  //     fontSize: "17px",
  //   },
};
