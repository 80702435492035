const loadSkusReducer = (state = {}, action) => {
    switch(action.type){
        case 'LOAD':
            state = action.payload;
            return state;
        case 'READ':
            return state;
        default:
            return state;
    }
};

export default loadSkusReducer;