import React, { useState, useEffect } from "react";
import styles from "./SelectDeliveryDate.module.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateRangeSharpIcon from "@material-ui/icons/DateRangeSharp";
import lightBlue from "@material-ui/core/colors/lightBlue";

import { blue } from "@material-ui/core/colors";
import moment from "moment";

// to custom style KeyboardDatePicker

const materialTheme = createMuiTheme({
	overrides: {
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: blue.A100,
			},
		},
		MuiPickersCalendarHeader: {
			switchHeader: {
				backgroundColor: blue["900"],
				color: "white",
			},
		},
		MuiPickersDay: {
			day: {
				// color: lightBlue.A700,
				color: blue["600"],
			},
			daySelected: {
				backgroundColor: blue["900"],
			},
			dayDisabled: {
				color: lightBlue["100"],
			},
			current: {
				color: lightBlue["900"],
			},
		},
		MuiPickersModal: {
			dialogAction: {
				color: lightBlue["400"],
			},
		},

		MuiOutlinedInput: {
			root: {
				"& $input": {
					// padding: "5px",
				},
			},
		},
	},
});

function SelectSmartSendDate({
	scheduledDateSelected,
	smartSendScheduledDate,
	setSmartSendScheduledDate,
}) {
	// to open/close date selector
	const [isOpen, setIsOpen] = useState(false);

	// to grey out calendar if target date === new Date()

	const [display, setDisplay] = useState(false);

	function getCurrentUTCDateTime(time) {
		return moment.utc(time).format("MMMM Do YYYY");
	}
	useEffect(() => {
		const targetDate = getCurrentUTCDateTime(scheduledDateSelected);

		const today = new Date();

		if (targetDate.toString() === getCurrentUTCDateTime(today).toString()) {
			setSmartSendScheduledDate(scheduledDateSelected);
			setDisplay(true);
		} else {
			setSmartSendScheduledDate(null);
			setDisplay(false);
		}
	}, [scheduledDateSelected]);

	const handleDateChange = (date) => {
		const updatedDate = new Date();
		date.setHours(updatedDate.getHours());
		date.setMinutes(updatedDate.getMinutes());
		date.setSeconds(updatedDate.getSeconds());
		setSmartSendScheduledDate(date?.getTime());

		setIsOpen(false);
	};
	return (
		<div
			className={styles.smartSend}
			style={
				display || scheduledDateSelected === null
					? { display:"none"}
					: { width: "100%" }
			}
		>
			<div className={styles.mainContainer}>
				{/* <h3 className={styles.mainTitle}>When will the recipient get SmartSend notification?</h3> */}
				<h3 className={styles.mainTitle}>
					When should the recipient be notified?
				</h3>
				<div className={styles.centerContainer}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ThemeProvider theme={materialTheme}>
							<KeyboardDatePicker
								style={{marginLeft: 68, width: 200, height: 28}}
								minDate={new Date()}
								maxDate={scheduledDateSelected}
								disableToolbar={true}
								margin="none"
								variant="inline"
								value={smartSendScheduledDate}
								inputVariant="outlined"
								emptyLabel="Select Date"
								format="MM/dd/yyyy"
								onChange={handleDateChange}
								KeyboardButtonProps={{
									onFocus: (e) => {
										setIsOpen(true);
									},
								}}
								PopoverProps={{
									disableRestoreFocus: true,
									onClose: () => {
										setIsOpen(false);
									},
								}}
								InputProps={{
									onFocus: () => {
										setIsOpen(true);
									},
								}}
								open={isOpen}
								keyboardIcon={
									<DateRangeSharpIcon style={{ fill: "#2B08A0" }} />
								}
							/>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</div>
			</div>
			<div className={styles.disclaimer}>
				<p>
					Please choose the date that you want us to notify the recipient that
					their Gesture is on the way.
				</p>{" "}
				<br />
			</div>
		</div>
	);
}

export default SelectSmartSendDate;
