import React, { Component } from "react";
import validator from "validator";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import libphonenumber from "google-libphonenumber";
import parsePhoneNumber from "libphonenumber-js";
import { withStyles } from "@material-ui/styles";
import styles from "../Styles/ImportStyles";
import sharedStyles from "../../SharedStyles.module.css";
import { handlePOST } from "../../../APIManager";
import Papa from "papaparse";
import stringify from "json-stable-stringify";

class Import extends Component {
	constructor(props) {
		super(props);

		this.state = {
			firstTest: false,
			showButton: true,
			success: true,
			emptybody: false,
			missingFields: "",
			tempdata: [],
			tempdataforduplicates: [],
			yellowWarning: false,
			orangeWarning: false,
			redWarning: false,
			page: 0,
			rowsPerPage: 10,
		};

		this.setData = this.setData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handlePOST = this.handlePOST.bind(this);
		this.handle = this.handle.bind(this);
		this.toggle = this.toggle.bind(this);
		this.openDialog = this.openDialog.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.arrayRemove = this.arrayRemove.bind(this);
		this.closeFile = this.closeFile.bind(this);
		this.closeHeaders = this.closeHeaders.bind(this);
		this.firstTestChange = this.firstTestChange.bind(this);
	}

	firstTestChange() {
		this.setState({ firstTest: !this.state.firstTest });
	}

	arrayRemove(arr, value) {
		return arr.filter(function(ele) {
			return ele != value;
		});
	}

	//this will run the handlePOST, give alert if empty
	handle() {
		if (this.state.tempdata === undefined || this.state.tempdata.length === 0) {
			alert("You need to import something!");
		} else {
			this.handlePOST();
		}
	}

	//POST data to the API (THIS IS A MERGE, NOT A REPLACE)
	async handlePOST() {
		this.props.toggle();
		this.props.automaticLoader();
		for (var i = 0; i < this.state.tempdata.length; i++) {
			if (this.state.tempdata[i].color === "#ff8484") {
				this.state.tempdata.splice(i, 1);
				i--;
			}
			if (this.state.tempdata[i].color === "#ffa500") {
				this.state.tempdata.splice(i, 1);
				i--;
			}
		}
		await handlePOST(this.state.tempdata);
		await this.props.handleGETfromManager();
		this.setState({ tempdata: [] });
	}

	//convert csv to json
	handleChange(e) {
		const file = e.target.files[0];
		Papa.parse(file, {
			header: true,
			download: true,
			skipEmptyLines: true,
			complete: this.setData,
		});
	}

	setData(results) {
		const removeExtraSpace = (s) =>
			s
				.trim()
				.split(/ +/)
				.join(" ");
		let contacts = results.data;
		var contactsArray = [];
		contacts?.forEach((tempcontacts) => {
			var contactsObject = {};
			for (const index in tempcontacts) {
				if (
					index === "first" ||
					index === "last" ||
					index === "phone" ||
					index === "email" ||
					index === "company" ||
					index === "title"
				) {
					contactsObject[index] = tempcontacts[index];
				}
			}
			if (
				Object.keys(contactsObject).length === 0 &&
				contactsObject.constructor === Object
			) {
				this.setState({
					firstTest: true,
				});
				this.props.setError();
				throw 0;
			}
			contactsArray.push(contactsObject);
		});

		contacts = contactsArray;
		let tempcontactsarray = [];
		contacts?.forEach((contactsDataItem) => {
			let tempdata = {};
			if (
				contactsDataItem["phone"] !== "" &&
				contactsDataItem["phone"]?.match(/^(?=.*[0-9])[- +()0-9]+$/) != null
			) {
				const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
				const PNF = libphonenumber.PhoneNumberFormat;
				const number = phoneUtil.parseAndKeepRawInput(
					contactsDataItem["phone"],
					"US"
				);
				var format = phoneUtil.format(number, PNF.E164);
				contactsDataItem["phone"] = format;
			} else if (
				contactsDataItem["phone"]?.match(/^(?=.*[0-9])[- +()0-9]+$/) === null
			) {
				contactsDataItem["phone"] = "";
			}

			for (const property in contactsDataItem) {
				if (
					property === "first" ||
					property === "last" ||
					property === "email" ||
					property === "phone" ||
					property === "company" ||
					property === "title"
				) {
					tempdata[property] = removeExtraSpace(contactsDataItem[property]);

					if (!validator.isEmail(contactsDataItem["email"])) {
						contactsDataItem["email"] = "";
					}
				}
			}
			tempcontactsarray.push(tempdata);
		});

		var contactsData = this.props.data;
		this.setState({
			redWarning: false,
			yellowWarning: false,
			orangeWarning: false,
			firstTest: false,
			showButton: true,
			success: true,
			emptybody: false,
		});
		var data = contacts;
		var headers = ["company", "email", "first", "last", "phone", "title"];
		var tempheaders = headers;
		if (data === undefined || data.length == 0) {
			this.state.missingFields =
				" First Name, Last Name, Company Name, Email, Phone Number, Title";
			this.setState({
				emptybody: true,
				tempdata: [],
				redWarning: false,
				yellowWarning: false,
				orangeWarning: false,
			});
			this.props.setError();

			return 0;
		}

		var importheaders = Object.keys(data[0]).sort();
		var importheaders2 = Object.keys(data[0]).sort();
		var temparray = [];
		var temparray2 = [];
		for (var i = 0; i < importheaders.length; i++) {
			temparray.push(importheaders[i].toLowerCase().replace(/\s/g, ""));
		}
		importheaders = temparray;

		for (var i = 0; i < importheaders2.length; i++) {
			temparray2.push(importheaders2[i].toLowerCase().replace(/\s/g, ""));
		}
		importheaders2 = temparray2;

		for (var i = 0; i < importheaders.length; i++) {
			if (importheaders[i] === "__parsed_extra") {
				importheaders.splice(i, 1);
				i--;
			}
		}

		if (tempheaders.length !== importheaders.length) {
			for (var i = 0; i < importheaders.length; i++) {
				if (tempheaders.includes(importheaders[i])) {
					tempheaders = this.arrayRemove(tempheaders, importheaders[i]);
				}
			}
			this.state.missingFields = "";
			for (var i = 0; i < tempheaders.length; i++) {
				if (tempheaders[i] === "first") {
					this.state.missingFields += ` First Name`;
				} else if (tempheaders[i] === "last") {
					this.state.missingFields += ` Last Name`;
				} else if (tempheaders[i] === "company") {
					this.state.missingFields += ` Company Name`;
				} else if (tempheaders[i] === "phone") {
					this.state.missingFields += ` Phone Number`;
				} else if (tempheaders[i] === "title") {
					this.state.missingFields += ` Title`;
				} else if (tempheaders[i] === "email") {
					this.state.missingFields += ` Email`;
				}
				if (i !== tempheaders.length - 1) {
					this.state.missingFields += ",";
				}
				// this.state.missingFields += ` ${tempheaders[i]},`;
			}

			this.setState({
				success: false,
				redWarning: false,
				yellowWarning: false,
				orangeWarning: false,
				tempdata: [],
			});
			this.props.setError();
			return 0;
		}

		var tempheaders2 = [];
		var continuefunction = false;
		for (var i = 0; i < headers.length; i++) {
			if (importheaders.includes(headers[i]) === false) {
				tempheaders2.push(headers[i]);
				continuefunction = true;
			}
		}

		if (continuefunction) {
			this.state.missingFields = "";
			for (var i = 0; i < tempheaders2.length; i++) {
				this.state.missingFields += ` ${tempheaders2[i]},`;
			}

			this.setState({
				success: false,
				redWarning: false,
				yellowWarning: false,
				orangeWarning: false,
				tempdata: [],
			});
			this.props.setError();
			return 0;
		}

		let j = 0;
		data?.forEach((arrayItem) => {
			arrayItem["fullname"] = `${arrayItem["first"]} ${arrayItem["last"]}`;
			if (
				arrayItem["phone"] !== "" &&
				arrayItem["phone"]?.match(/^(?=.*[0-9])[- +()0-9]+$/) != null
			) {
				const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
				const PNF = libphonenumber.PhoneNumberFormat;
				const number = phoneUtil.parseAndKeepRawInput(arrayItem["phone"], "US");
				var format = phoneUtil.format(number, PNF.E164);
				arrayItem["phone"] = format;
			}

			for (const property in arrayItem) {
				arrayItem[property] = removeExtraSpace(arrayItem[property]);
			}

			var count = 0;
			if (
				arrayItem["first"] === "" ||
				arrayItem["first"] === null ||
				arrayItem["first"] === undefined
			) {
				count++;
			}

			if (
				arrayItem["last"] === "" ||
				arrayItem["last"] === null ||
				arrayItem["last"] === undefined
			) {
				count++;
			}

			if (
				arrayItem["email"] === "" ||
				arrayItem["email"] === null ||
				arrayItem["email"] === undefined ||
				!validator.isEmail(arrayItem["email"])
			) {
				count++;
			}

			var count2 = 0;
			for (const property in arrayItem) {
				if (
					arrayItem[property] === "" ||
					arrayItem[property] === null ||
					arrayItem[property] === undefined
				) {
					arrayItem[property] = "N/A";
					count2++;
				}
			}

			contactsData?.forEach((contactsDataItem) => {
				let tempdata = {};
				for (const property in contactsDataItem) {
					if (
						property === "first" ||
						property === "last" ||
						property === "email" ||
						property === "phone" ||
						property === "company" ||
						property === "title"
					) {
						tempdata[property] = contactsDataItem[property];
					}
				}
				let tempdata2 = {};
				for (const property in arrayItem) {
					if (
						property === "first" ||
						property === "last" ||
						property === "email" ||
						property === "phone" ||
						property === "company" ||
						property === "title"
					) {
						tempdata2[property] = arrayItem[property];
					}
				}
				if (stringify(tempdata) === stringify(tempdata2)) {
					arrayItem["dupes"] = true;
					count = 99;
				}
			});

			for (let i = 0; i < tempcontactsarray.length; i++) {
				let temparrayitem = {};
				for (const property in arrayItem) {
					if (
						property === "first" ||
						property === "last" ||
						property === "email" ||
						property === "phone" ||
						property === "company" ||
						property === "title"
					) {
						temparrayitem[property] = removeExtraSpace(arrayItem[property]);
					}
				}
				for (let k = 0; k < j; k++) {
					if (stringify(temparrayitem) === stringify(tempcontactsarray[k])) {
						count = 99;

						if (
							!this.state.tempdataforduplicates.includes(
								stringify(temparrayitem)
							)
						) {
							this.setState((prevState) => ({
								tempdataforduplicates: [
									...prevState.tempdataforduplicates,
									stringify(temparrayitem),
								],
							}));
						}
					}
				}

				for (let k = j + 1; k < tempcontactsarray.length; k++) {
					if (stringify(temparrayitem) === stringify(tempcontactsarray[k])) {
						count = 99;
						if (
							!this.state.tempdataforduplicates.includes(
								stringify(temparrayitem)
							)
						) {
							this.setState((prevState) => ({
								tempdataforduplicates: [
									...prevState.tempdataforduplicates,
									stringify(temparrayitem),
								],
							}));
						}
					}
				}
			}

			if (count === 99) {
				arrayItem["color"] = "#ffa500";
				this.setState({ orangeWarning: true });
			} else if (count === 0) {
				arrayItem["color"] = "rgba(255,255,255,0)";
			} else if ((count > 0 && count < 3) || count2 < 6) {
				//yellow warning
				arrayItem["color"] = "#ffff94";
				this.setState({ yellowWarning: true });
			} else if (count === 3) {
				//red warning
				this.setState({ redWarning: true });
				arrayItem["color"] = "#ff8484";
			}

			j++;
			count = 0;
			count2 = 0;
		});

		for (let i = 0; i < data.length; i++) {
			let temparrayitem = {};
			for (const property in data[i]) {
				if (
					property === "first" ||
					property === "last" ||
					property === "email" ||
					property === "phone" ||
					property === "company" ||
					property === "title"
				) {
					temparrayitem[property] = removeExtraSpace(data[i][property]);
				}
			}

			for (let j = 0; j < this.state.tempdataforduplicates.length; j++) {
				if (stringify(temparrayitem) === this.state.tempdataforduplicates[j]) {
					data[i]["color"] = "rgba(255,255,255,0)";
					var array = [...this.state.tempdataforduplicates];
					array.splice(j, 1);
					this.setState({ tempdataforduplicates: array });
					j--;
				}
				if (data[i]["dupes"] === true) {
					data[i]["color"] = "#ffa500";
				}
			}
		}

		this.setState({
			tempdata: data,
			success: true,
			emptybody: false,
			showButton: false,
		});
	}

	//This will close the import panel and clear the tempdata
	toggle() {
		this.props.toggle();
		this.setState({ tempdata: [] });
	}

	openDialog() {
		document.getElementById("contained-button-file").click();
	}

	handleChangePage(event, newPage) {
		this.setState({ page: newPage });
	}

	handleChangeRowsPerPage = (event) => {
		this.setState({ rowsPerPage: +event.target.value, page: 0 });
	};

	closeFile() {
		this.setState({
			emptybody: !this.state.emptybody,
		});
	}

	closeHeaders() {
		this.setState({
			success: !this.state.success,
		});
	}

	render() {
		const { classes } = this.props;
		const columns = [
			{ id: "first", label: "First", minWidth: 100, align: "center" },
			{ id: "last", label: "Last", minWidth: 100, align: "center" },
			{
				id: "email",
				label: "Email",
				minWidth: 170,
				align: "center",
			},
			{
				id: "phone",
				label: "Phone",
				minWidth: 170,
				align: "center",
			},
			{
				id: "company",
				label: "Company",
				minWidth: 170,
				align: "center",
			},
			{
				id: "title",
				label: "Title",
				minWidth: 150,
				align: "center",
			},
		];
		const rows = this.state.tempdata;

		return (
			<div>
				<div className={classes.button}>
					{this.state.showButton &&
					!this.state.firstTest &&
					!this.state.emptybody &&
					this.state.success ? (
						<div>
							<button
								className={sharedStyles.primaryButton}
								onClick={this.openDialog}
							>
								Upload File
							</button>
							<input
								accept=".csv"
								id="contained-button-file"
								multiple
								name="csv-file"
								type="file"
								onChange={(e) => this.handleChange(e)}
								hidden
							/>
						</div>
					) : (
						<div></div>
					)}
				</div>
				{this.state.firstTest && (
					<div style={{ color: "#1F1E1E", float: "900" }}>
						<div>
							<div>{`We seem to have detected a malformed CSV.`}</div>
							<div>
								If you believe this is incorrect, please contact{" "}
								<a a href="mailto: support@yourgesture.com">
									support@yourgesture.com
								</a>
								and we will assist you.
							</div>
						</div>

						<div style={{ marginTop: "20px" }}>
							<button
								className={sharedStyles.primaryButton}
								style={{ margin: "5px" }}
								onClick={this.toggle}
							>
								Okay
							</button>
						</div>
					</div>
				)}
				{this.state.emptybody && (
					<div style={{ color: "#1F1E1E", float: "900" }}>
						<div>
							<div>{`We seem to have detected a malformed CSV.`}</div>
							<div>{`Please make sure there are contacts in the file`}</div>
							<div>
								If you believe this is incorrect, please contact{" "}
								<a a href="mailto: support@yourgesture.com">
									support@yourgesture.com
								</a>
								and we will assist you.
							</div>
						</div>

						<div style={{ marginTop: "20px" }}>
							<button
								className={sharedStyles.primaryButton}
								style={{ margin: "5px" }}
								onClick={this.toggle}
							>
								Okay
							</button>
						</div>
					</div>
				)}
				{!this.state.success && (
					<div style={{ color: "#1F1E1E", float: "900" }}>
						<div>
							<div>{`We seem to have detected a malformed CSV.`}</div>
							<div>{`You are missing:${this.state.missingFields}`}</div>
							<div>
								If you believe this is incorrect, please contact{" "}
								<a a href="mailto: support@yourgesture.com">
									support@yourgesture.com
								</a>{" "}
								and we will assist you.
							</div>
						</div>

						<div style={{ marginTop: "20px" }}>
							<button
								className={sharedStyles.primaryButton}
								style={{ margin: "5px" }}
								onClick={this.toggle}
							>
								Okay
							</button>
						</div>
					</div>
				)}
				<div>
					{this.state.showButton &&
					!this.state.firstTest &&
					!this.state.emptybody &&
					this.state.success ? (
						<div
							className={
								this.state.tempdata.length === 0
									? classes.noimport
									: classes.import
							}
						>
							Please only upload .CSV files
						</div>
					) : (
						<div></div>
					)}
					<div
						className={
							this.state.tempdata.length === 0 ? classes.layer1 : classes.layer2
						}
					>
						{this.state.yellowWarning && (
							<div style={{ backgroundColor: "#ffff94", marginBottom: "10px" }}>
								You will not be able to send Gestures to these contacts until
								the required fields are completed/corrected
							</div>
						)}
						{this.state.orangeWarning && (
							<div style={{ backgroundColor: "#ffa500", marginBottom: "10px" }}>
								We have detected duplicates in the CSV and/or your contacts
								list. These contacts will not be imported.
							</div>
						)}
						{this.state.redWarning && (
							<div style={{ backgroundColor: "#ff8484", marginBottom: "10px" }}>
								These contacts will not be imported because none of the fields
								are complete
							</div>
						)}
						<div>
							<Paper>
								<TableContainer>
									<Table stickyHeader aria-label="sticky table">
										<TableHead>
											<TableRow>
												{columns?.map((column) => (
													<TableCell
														key={column.id}
														align={column.align}
														style={{ minWidth: column.minWidth }}
													>
														{column.label}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>
											{rows
												.slice(
													this.state.page * this.state.rowsPerPage,
													this.state.page * this.state.rowsPerPage +
														this.state.rowsPerPage
												)
												?.map((row) => {
													return (
														<TableRow
															hover
															role="checkbox"
															tabIndex={-1}
															key={row.code}
															style={{
																backgroundColor: row["color"],
															}}
														>
															{columns?.map((column) => {
																const value = row[column.id];
																return (
																	<TableCell
																		key={column.id}
																		align={column.align}
																	>
																		{column.format && typeof value === "number"
																			? column.format(value)
																			: value}
																	</TableCell>
																);
															})}
														</TableRow>
													);
												})}
										</TableBody>
									</Table>
								</TableContainer>
								<TablePagination
									rowsPerPageOptions={[10, 25, 100]}
									component="div"
									count={rows.length}
									rowsPerPage={this.state.rowsPerPage}
									page={this.state.page}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							</Paper>

							<div className={classes.buttonGroup}>
								<button
									style={{ backgroundColor: "#2B08A0", color: "white" }}
									onClick={this.handle}
									className={sharedStyles.primaryButton}
								>
									Save
								</button>
								<button
									variant="contained"
									onClick={this.toggle}
									className={sharedStyles.primaryButton}
									style={{ marginLeft: "25px" }}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(Import);
