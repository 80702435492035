import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createStore } from 'redux';
import loadSkusReducer from './reducers/loadSkus'
import { Provider } from 'react-redux';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


const stripeKey = process.env.REACT_APP_STRIPE_KEY

const stripePromise = loadStripe(stripeKey);

const store = createStore(loadSkusReducer);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
		<Elements stripe={stripePromise}>
			<App />
		</Elements>{" "}
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
