import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ContainerLayout from "../ContainerLayout";
import styles from "./Checkout.module.css";
import sharedStyles from "../SharedStyles.module.css";
import Payment from "./Payment";
import Summary from "./Summary";
import cardIcon from "../../Assets/icons/credit-card.png"

import { toast } from "react-toastify";

import { checkForToken } from "../../APIManager";

import { handleGETprofileBox } from "../../APIManager";

import { usePromiseTracker, trackPromise } from "react-promise-tracker";

function Checkout(props) {
  // to disable btns until card information has been entered
  const [disable, setDisable] = useState(true);

  // to get userData on page load
  const [userData, setUserData] = useState([]);

  const history = useHistory();

  const importGetUserInfoFunc = async () => {
    const data = await handleGETprofileBox();

    setUserData(data);
  };

  const { promiseInProgress } = usePromiseTracker();

  // useEffect(() => {
  //   trackPromise(importGetUserInfoFunc());
  // }, []);

  // if !token redirect to Login Page

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && !checkForToken()) {
      history.push("/login");
      setTimeout(() => {
        toast.error(
          "You've been logged out. This may have been caused by using more than one device or browser",
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }, 1000);
      return;
    } else {
      trackPromise(importGetUserInfoFunc());
    }

    return () => {
      unmounted = true;
    };
  }, []);


  
	useEffect(() => {
		let unmounted = false;

		if (!unmounted && checkForToken() === "") {
			history.push("/login");
		}

		return () => {
			unmounted = true;
		};
	}, [checkForToken()]);

  return (
    <div className={sharedStyles.mainContainer}>
      <ContainerLayout>
        <div className={styles.checkoutCenterContainer}>
          <div className={styles.header}>
            <img src={cardIcon}
              alt="credit card"
              className={styles.icon}
            />
            <h2 className={sharedStyles.mainTitle}>Checkout</h2>
          </div>
          <div className={styles.CheckoutContentContainer}>
            <div className={styles.leftContent}>
              <Payment
                userData={userData}
                disable={disable}
                setDisable={setDisable}
                promiseInProgress={promiseInProgress}
                handleLogOut={props.handleLogOut}

              />
            </div>

            <div className={styles.rightContent}>
              {/* Order Summary Details */}
              <div className={styles.summaryInfo}>
                <Summary
                  {...props}
                  disable={disable}
                  userData={userData}
                  importGetProfileInfo={props.importGetProfileInfo}
                  handleLogOut={props.handleLogOut}

                />
              </div>
            </div>
          </div>
        </div>
      </ContainerLayout>
    </div>
  );
}

export default Checkout;
