import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";

import sharedStyles from "../../Components/SharedStyles.module.css";
import ModalConfirmation from "./ModalConfirmation";

const FormEditButtons = (props) => {
  const { editFormToggle, toggleForm, discardChanges, saveChanges } = props;
  const [modalToggle, toggleModal] = useState(false);

  return (
    <>
      <ModalConfirmation
        open={modalToggle}
        toggleModal={toggleModal}
        toggleForm={toggleForm}
        saveChanges={saveChanges}
      />
      <Box mt={1.5}>
        {editFormToggle ? (
          <div className="card-edit">
            <button
              onClick={() => {
                discardChanges();
                toggleForm(false);
              }}
              className={sharedStyles.primaryButton}
              style={{
                marginRight: "5px",
                backgroundColor: "transparent",
                color: "#2B08A0",
              }}
            >
              Cancel
            </button>

            <button
              onClick={() => {
                toggleModal(!modalToggle);
              }}
              className={sharedStyles.primaryButton}
              // style={{
              //   backgroundColor: "#2B08A0",
              //   color: "white",
              // }}
            >
              Save
            </button>
          </div>
        ) : (
          <div className="card-edit">
            <button
              className={sharedStyles.primaryButton}
              style={{ marginRight: "5px" }}
              onClick={() => toggleForm(!editFormToggle)}
            >
              Edit
            </button>
          </div>
        )}
      </Box>
    </>
  );
};

export default withStyles(sharedStyles)(FormEditButtons);
