import { toast } from "react-toastify";

// base url

// const URL = "https://us-central1-gesture-dev.cloudfunctions.net/business"

const URL = "https://us-central1-yourgestureapp.cloudfunctions.net/businessV2"



// "https://us-central1-yourgestureapp.cloudfunctions.net/businessV2"
// process.env.NODE_ENV === "development"
// 	? "https://us-central1-gesture-dev.cloudfunctions.net/business_api3"
// 	: "https://us-central1-yourgestureapp.cloudfunctions.net/business";

function checkForErrors(response) {
	try {
		if (response.status >= 200 && response.status <= 299) {
			return response.json();
		} else if (response.status === 400) {
			return response.json();
		} else if (response.status === 401) {
			localStorage.removeItem("token");

			setTimeout(() => {
				toast.error(
					"You've been logged out. This may have been caused by using more than one device or browser",
					{
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}, 1000);

			throw Error(response.statusText);
		} else if (!response.ok) {
			throw Error(response.statusText);
		} else {
			if (response.ok) {
				return response.data;
			}
		}
	} catch (error) {
		console.log("caught error: ", error);
	}
}

function checkForErrorsProfileBox(response) {
	try {
		if (response.status >= 200 && response.status <= 299) {
			return response.json();
		} else if (response.status === 401) {
			localStorage.removeItem("token");

			throw Error(response.statusText);
		} else if (!response.ok) {
			throw Error(response.statusText);
		} else {
			if (response.ok) {
				return response.data;
			}
		}
	} catch (error) {
		console.log("caught error: ", error);
	}
}

// save token

export const saveToken = (token) => {
	if (token === undefined) {
		localStorage.removeItem("token");

		setTimeout(() => {
			toast.error("Please try again", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}, 3000);
	} else {
		localStorage.setItem("token", JSON.stringify(token));
	}
};

// check for token

export const checkForToken = () => {
	const userToken =
		localStorage.getItem("token") == null
			? ""
			: JSON.parse(localStorage.getItem("token"));

	return userToken;
};

// user login

export async function logInUser(object) {
	const url = `${URL}/users/login`;
	const data = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email: object.email, password: object.password }),
	};

	const loginUserInfo = await fetch(url, data)
		.then(async (response) => {
			const result = await response.text();

			const res = JSON.parse(result);

			console.log(res, "res")

			if (res?.code === "SUCCESS") {
				return res?.data;
			} else if (res?.code === "FAILURE") {
				setTimeout(() => {
					toast.error(res?.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}, 5000);

				throw new Error(result);
			}
		})
		.then((res) => {
			return res;
		})
		.catch((error) => console.log(error, "error"));

	// save token

	let token = loginUserInfo?.token;

	saveToken(token);
	return loginUserInfo;
}

// user sign-up

export async function signUpUser(object) {
	const url = `${URL}/users/signUp`;
	const data = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			first: object.firstName,
			last: object.lastName,
			company: object.companyName,
			email: object.email,
			phoneNumber: object.phoneNumber,
			password: object.password,
			size_of_company: object.companySize,
			revenue: object.revenue,
		}),
	};

	const signUpUserInfo = await fetch(url, data)
		.then(async (response) => {
			const result = await response.text();

			const res = JSON.parse(result);

			if (res?.code === "SUCCESS") {
				return res?.data;
			} else if (res?.code === "FAILURE") {
				setTimeout(() => {
					toast.error(res?.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}, 5000);

				throw new Error(result);
			}
		})
		.then((res) => {
			return res;
		})
		.catch((error) => console.log(error, "error"));

	const token = signUpUserInfo?.token;

	saveToken(token);
	return signUpUserInfo;
}

//update user
export async function updateUser(object) {
	const url = `${URL}/users`;
	const data = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
		body: JSON.stringify({
			userInfo: {
				first: object.first,
				last: object.last,
				email: object.email,
				phoneNumber: object.phoneNumber,
				company: object.company,
				revenue: object.revenue,
				size_of_company: object.size_of_company,
				company_logo: object.company_logo,
				sendersForNotification: object?.sendersForNotification
			},
		}),
	};

	const updatedUserInfo = await fetch(url, data)
		.then(async (response) => {
			const result = await response.text();

			const res = JSON.parse(result);

			if (res?.code === "SUCCESS") {
				return res?.data;
			} else if (res?.code === "FAILURE") {
				setTimeout(() => {
					toast.error(res?.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}, 5000);

				throw new Error(result);
			}
		})
		.then((res) => {
			return res;
		})

		.catch((error) => console.log(error, "error"));
	return updatedUserInfo;
}

// get plans

export async function getPlans() {
	const url = `${URL}/plans`;

	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};

	const plansInfo = await fetch(url, obj)
		.then((response) => checkForErrors(response))

		.then((result) => {
			return result.data;
		});

	return plansInfo;
}

// get summary for checkout page

export async function getPlanData() {
	let planId =
		localStorage.getItem("plan-id") == null
			? ""
			: JSON.parse(localStorage.getItem("plan-id"));

	const url = `${URL}/plans/checkout/${planId}`;


	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};

	const plansData = await fetch(url, obj)
		.then((response) => checkForErrors(response))
		.then((result) => {
			return result.data;
		})
		.catch((e) => {
			console.log(e);
		});

	return plansData;
}

export async function getPlanDataWithId(planId) {
	const url = `${URL}/plans/${planId}`;

	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};

	const plansData = await fetch(url, obj)
		.then((response) => checkForErrors(response))
		.then((result) => {
			return result.data;
		})
		.catch((e) => {
			console.log(e);
		});

	return plansData;
}

// get SKUS for Order Page

export async function getSkusData() {
	const url = `${URL}/orders/skus`;

	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};

	const skusData = await fetch(url, obj)
		.then((response) => checkForErrors(response))
		.then(async (result) => {
			if (result?.code === "SUCCESS") {
				return result.data.skus;
			} else if (result?.code === "FAILURE") {
				const result = await res?.text();

				const res = JSON.parse(result);
				setTimeout(() => {
					toast.error(res?.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}, 5000);
			}

			throw new Error(result);
		})

		.catch((e) => {
			console.log(e);
		});

	return skusData;
}

// to get contacts for Order Page( select Recipients)

export async function getClientContact() {
	const url = `${URL}/contacts/`;

	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};
	const contactsData = await fetch(url, obj)
		.then((response) => checkForErrors(response))
		.then(async (result) => {
			if (result?.code === "SUCCESS") {
				return result?.data?.contacts;
			} else if (result?.code === "FAILURE") {
				const result = await result?.text();

				const res = JSON.parse(result);

				setTimeout(() => {
					toast.error(res?.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}, 5000);

				throw new Error(result);
			}
		})
		.catch((e) => {
			console.log(e);
		});

	return contactsData;
}
//   get data from API (People's Page)

export async function handleGET() {
	const url = `${URL}/contacts/`;

	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};

	const contactsData = await fetch(url, obj)
		.then((response) => checkForErrors(response))
		.then((result) => {
			return result.data.contacts;
		})
		.catch((e) => {
			console.log(e);
		});
	return contactsData;
}

// API to post a new contact (ImportSingle.js)

// export async function handleSubmitSingleContact(object) {
// 	const url = `${URL}/contacts/?action=merge`;
// 	const data = {
// 		method: "POST",
// 		headers: {
// 			Accept: "application/json",
// 			"Content-Type": "application/json",
// 			Authorization: "Bearer " + `${checkForToken()}`,
// 		},
// 		body: JSON.stringify({
// 			contacts: [
// 				{
// 					first: object.firstName,
// 					last: object.lastName,
// 					email: object.email,
// 					phone: object.phone,
// 					password: object.password,
// 					delivery_address: object.delivery_address,
// 					id: object.id,
// 				},
// 			],
// 		}),
// 	};

// 	const response = await fetch(url, data)
// 		.then((response) => checkForErrors(response))

// 		// .then((result) => {
// 		// 	return result.data;
// 		// })
// 		.then(async (response) => {

// 			const result = await response.text();

// 			const res = JSON.parse(result);
// 			console.log(res,"res")

// 			if (res?.code === "SUCCESS") {
// 				return response?.json();
// 			} else if (res?.code === "FAILURE") {
// 				setTimeout(() => {
// 					toast.error(res?.message, {
// 						position: "top-center",
// 						autoClose: 5000,
// 						hideProgressBar: true,
// 						closeOnClick: true,
// 						pauseOnHover: true,
// 						draggable: true,
// 						progress: undefined,
// 					});
// 				}, 5000);

// 				throw new Error(result);
// 			}
// 		})
// 		.then((res) => {
// 			return res;
// 		})
// 		.catch((e) => {
// 			console.log(e);
// 		});

// 	return response;
// }

// to post order
export async function postOrder(object) {
	const url = `${URL}/orders`;
	const data = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",

			Authorization: "Bearer " + `${checkForToken()}`,
		},
		body: JSON.stringify({
			cartObject: object.cartObject,
		}),
	};

	const postOrder = await fetch(url, data)
		.then(async (response) => checkForErrors(response))

		.catch((error) => console.log(error, "error"));

	return postOrder;
}

export async function handlePOST(object) {
	const url = `${URL}/contacts/?action=merge`;

	var obj = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
		body: JSON.stringify({ contacts: object }),
	};
	const response = await fetch(url, obj)
		.then(async (response) => {
			const result = await response?.text();
			const res = JSON?.parse(result);
			if (res?.code === "SUCCESS") {
				toast.success(
					`Successfully added ${object?.length} contact${object.length === 1 ? `` : `s`
					}`,
					{
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
				return res?.data;
			} else if (res?.code === "FAILURE") {
				setTimeout(() => {
					toast.error(res?.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}, 5000);

				throw new Error(result);
			}
		})

		.catch((error) => console.log(error, "error"));

	return response;
}

export async function handleGETprofileBox() {
	const url = `${URL}/users`;

	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};

	const contactsData = await fetch(url, obj)
		.then((response) => checkForErrorsProfileBox(response))
		.then((result) => {
			return result;
		})
		.catch((e) => {
			console.log(e);
		});

	return contactsData?.data;
}

export async function handleDELETE(object) {
	const url = `${URL}/contacts`;

	const data = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
		body: JSON.stringify({
			contacts: object,
		}),
	};

	const contactsData = await fetch(url, data)
		.then((response) => response.json())
		.then((result) => {
			toast.success(
				`Successfully deleted ${object.length} contact${object.length === 1 ? `` : `s`
				}`,
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);

			return result;
		})
		.catch((e) => {
			console.log(e);
		});

	return contactsData;
}

// GET History
export async function getHistory() {
	const url = `${URL}/orders`;

	var obj = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
	};

	const historyInfo = await fetch(url, obj)
		.then((response) => checkForErrors(response))
		.then((result) => {
			return result;
		});

	return historyInfo;
}

// to post token

export async function postToken(object) {
	const url = `${URL}/users/update_stripe_token`;
	const data = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",

			Authorization: "Bearer " + `${checkForToken()}`,
		},
		body: JSON.stringify({
			stripe_token: object.stripe_token,
		}),
	};

	const postToken = await fetch(url, data)
		.then(async (response) => checkForErrors(response))

		.catch((error) => console.log(error, "error"));

	return postToken;
}

//  to change plan
export async function handleChangePlan() {
	let planId =
		localStorage.getItem("plan-id") == null
			? ""
			: JSON.parse(localStorage.getItem("plan-id"));

	const url = `${URL}/plans/${planId}`;
	const data = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
		body: "",
	};

	const response = await fetch(url, data)
		.then(async (response) => checkForErrors(response))

		.catch((error) => console.log(error, "error"));

	return response;
}

export async function handlePatch(object) {
	const url = `${URL}/contacts/`;

	var obj = {
		method: "PATCH",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + `${checkForToken()}`,
		},
		body: JSON.stringify({ contacts: object }),
	};
	const response = await fetch(url, obj)
		.then((result) => {
			toast.success("Saved successfully", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return result.data;
		})
		.catch((e) => {
			console.log(e);
		});

	return response;
}

// forgot password

export async function forgotPassword(object) {
	const url = `${URL}/users/reset_password/link`;
	const data = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ email: object.email }),
	};

	const updatePasswordInfo = await fetch(url, data)
		.then(async (response) => {
			if (response.status === 200) {
				return response.json();
			}
			if (response.status === 400) {
				return response.json();
			}
		})
		.then((res) => {
			return res;
		})

		.catch((error) => console.log(error, "error"));

	return updatePasswordInfo;
}

// reset password

export async function resetPassword(object) {
	const url = `${URL}/users/reset_password/password`;
	const data = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ token: object.token, password: object.password }),
	};

	const resetPasswordInfo = await fetch(url, data)
		.then(async (response) => {
			if (response.status === 200) {
				return response.json();
			}
			if (response.status === 400) {
				return response.json();
			}
		})
		.then((res) => {
			return res;
		})
		.catch((error) => console.log(error, "error"));

	return resetPasswordInfo;
}
