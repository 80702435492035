export default {
  layer2: {
    display: "inline",
    marginBottom: "15px",
  },

  layer1: {
    display: "none",
  },

  import: {
    display: "none",
  },

  noimport: {
    paddingTop: "10px",
  },

  uploadbutton: {
    marginBottom: "10px",
  },

  tablecell: {
    fontWeight: "bold",
  },

  buttonGroup: {
    marginTop: "15px",
    display: "flex",
  },

  button: {
    marginBottom: "15px",
    display: "flex",
  },
};
