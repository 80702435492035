import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
  useHistory,
} from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/styles";
import InputBase from "@material-ui/core/InputBase";
import SearchBar from "material-ui-search-bar";
import TextField from "@material-ui/core/TextField";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import peopleLogo from "../../../Assets/icons/mdi_account-multiple-plus-outline.png";
import sharedStyles from "../../SharedStyles.module.css";
import {
  handleGET,
  handleDELETE,
  getPlanData,
  checkForToken,
} from "../../../APIManager";
import { PageLoader } from "../../../Loader";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../Styles/ContactStyles";
import Import from "./Import";
import ContactSingle from "./ContactSingle";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loader: true,
      temp: false,
      checked: false,
      loader: true,
      importWindow: false,
      delete: false,
      data: [],
      filter: "",
      contactsId: [],
      filteredData: [],
    };

    this.toggleImport = this.toggleImport.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.setFalse = this.setFalse.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.clearHandleChecked = this.clearHandleChecked.bind(this);
    this.deleteWindow = this.deleteWindow.bind(this);
    this.automaticLoader = this.automaticLoader.bind(this);
    this.cancelSearch = this.cancelSearch.bind(this);
    this.setError = this.setError.bind(this);
  }

  // This would run when the webpage is first loaded
  componentDidMount() {
    const { history } = this.props;
    this.props.handleLogOut(history);
    if (!checkForToken()) {
      this.props.history.push("/login");
      return 0;
    } else {
      this.handleGETfromManager();
    }
  }

  componentDidUpdate() {
    if (this.state.loader === true) {
      setTimeout(this.handleGETfromManager, 1000);
    }
  }

  setError() {
    this.setState({
      error: true,
    });
  }

  async automaticLoader() {
    this.setState({ loader: true });
    setTimeout(this.setFalse, 1500);
  }

  deleteWindow() {
    this.setState({
      delete: !this.state.delete,
    });
  }

  toggleCheckbox() {
    this.handleChecked();
  }

  setFalse() {
    this.setState({
      loader: false,
    });
  }

  handleChecked(e, id) {
    if (e.target.checked) {
      this.state.contactsId.push(id);
    } else if (!e.target.checked) {
      for (var i = 0; i < this.state.contactsId.length; i++) {
        if (this.state.contactsId[i] === id) {
          this.state.contactsId.splice(i, 1);
          i--;
        }
      }
    }
    if (this.state.contactsId.length > 0) {
      this.setState({ checked: true });
    } else {
      this.setState({ checked: false });
    }
  }

  clearHandleChecked() {
    this.setState({
      contactsId: [],
    });
    this.setState({ checked: false });
    var uncheck = document.getElementsByTagName("input");
    for (var i = 0; i < uncheck.length; i++) {
      if (uncheck[i].type == "checkbox") {
        uncheck[i].checked = false;
      }
    }
  }

  async handleDelete() {
    this.setState({ delete: false });
    this.setState({ loader: true });
    await handleDELETE(this.state.contactsId);
    const { history } = this.props;
    this.props.handleLogOut(history);
    await this.handleGETfromManager();
    this.clearHandleChecked();
    setTimeout(this.setFalse, 250);
  }

  //POST (THIS IS FOR THE CLEAR BUTTON)
  // async handlePOST() {
  //   const response = await fetch(
  //     "https://us-central1-gesture-dev.cloudfunctions.net/business_api2/contacts/tester?action=replace",
  //     {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ contacts: [] }),
  //     }
  //   );
  //   return response.json();
  // }

  // async handlePOST(){
  //   await clear();
  // }

  //GET DATA FROM API
  handleGETfromManager = async () => {
    const { history } = this.props;

    const tempdata = await handleGET();
    this.props.handleLogOut(history);
    if (typeof tempdata === "undefined") {
      console.log("tempdata empty");
    } else {
      // tempdata.forEach((arrayItem) => {
      //   arrayItem.fullname = `${arrayItem["first"]} ${arrayItem["last"]}`;
      // });
      tempdata?.forEach((arrayItem) => {
        arrayItem["fullname"] = `${arrayItem["first"]} ${arrayItem["last"]}`;
        for (const property in arrayItem) {
          if (
            arrayItem[property] === "" ||
            arrayItem[property] === null ||
            arrayItem[property] === undefined
          ) {
            arrayItem[property] = "N/A";
          }
        }
      });

      this.setState({ data: tempdata, tempdata: tempdata });
    }
    setTimeout(this.setFalse, 1000);
    return tempdata;
  };

  //toggle for import button
  toggleImport() {
    this.setState({
      error: false,
      importWindow: !this.state.importWindow,
    });
  }

  cancelSearch() {
    this.state.filter = "";
    this.forceUpdate();
  }

  render() {
    const { classes } = this.props;
    const csvData = [["first", "last", "email", "phone", "company", "title"]];
    const lowercasedFilter = this.state.filter.toLowerCase().replace(/\s/g, "");
    var filteredData = this.state.data.filter((item) => {
      return Object.keys(item).some((keys) =>
        item[keys]
          .toString()
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(lowercasedFilter)
      );
    });

    return (
      <div className={sharedStyles.mainContainer}>
        <ToastContainer />
        {this.state.loader ? (
          <div
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
            }}
          >
            <PageLoader />
          </div>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.title}>
              <div className={classes.titlename}>
                <img src={peopleLogo} className={sharedStyles.logo} />
                <h2 className={sharedStyles.mainTitle}>My Contacts</h2>
              </div>

              {!this.state.checked ? (
                <div className={classes.buttonGroup}>
                  <Link
                    to={{
                      pathname: `/addperson`,
                      state: { data: this.state.tempdata },
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      className={sharedStyles.primaryButton}
                      style={{ marginRight: "5px" }}
                    >
                      <AddIcon /> Add Person
                    </button>
                  </Link>
                  <button
                    onClick={this.toggleImport}
                    className={sharedStyles.primaryButton}
                    style={{ marginRight: "5px" }}
                  >
                    <PublishIcon />
                    Import
                  </button>
                  <CSVLink
                    data={csvData}
                    filename={"template.csv"}
                    className={sharedStyles.primaryButton}
                    style={{
                      marginRight: "5px",
                      textDecoration: "none",
                      fontSize: "15px",
                    }}
                  >
                    Template
                  </CSVLink>
                </div>
              ) : (
                <div className={classes.buttonGroup}>
                  <button
                    onClick={this.clearHandleChecked}
                    className={sharedStyles.primaryButton}
                    style={{ marginRight: "5px" }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.deleteWindow}
                    className={sharedStyles.primaryButton}
                    style={{
                      backgroundColor: "#2B08A0",
                      color: "white",
                      marginRight: "5px",
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>

            <div>
              <Dialog
                open={this.state.delete}
                onClose={this.deleteWindow}
                aria-labelledby="responsive-dialog-title"
                maxWidth="sm"
                fullWidth={true}
              >
                <DialogTitle id="responsive-dialog-title">
                  Delete Contacts?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {`You have selected ${this.state.contactsId.length} people.
                    This action is irreversible!`}
                  </DialogContentText>
                  <div className={classes.buttonGroup}>
                    <button
                      className={sharedStyles.primaryButton}
                      style={{ margin: "5px" }}
                      onClick={this.deleteWindow}
                    >
                      Cancel
                    </button>
                    <button
                      className={sharedStyles.primaryButton}
                      onClick={this.handleDelete}
                      style={{ backgroundColor: "#2B08A0", color: "white" }}
                    >
                      Delete
                    </button>
                  </div>
                </DialogContent>
              </Dialog>
            </div>

            <div className={classes.contentContainer}>
              <div
                className={
                  this.state.data.length === 0
                    ? classes.noimport
                    : classes.import
                }
              >
                You currently don't have anything imported! Import with a CSV
                file or add your contacts individually
              </div>
              <div
                className={
                  this.state.data.length === 0 ? classes.layer1 : classes.layer2
                }
              >
                <div>
                  <SearchBar
                    variant="outlined"
                    value={this.state.filter}
                    onChange={(value) => this.setState({ filter: value })}
                    className={classes.searchbar}
                    placeholder={`Search ${this.state.data.length} people..`}
                    onCancelSearch={this.cancelSearch}
                    cancelOnEscape
                  />
                </div>
                {filteredData?.map((item) => (
                  <div key={item["id"]} className={classes.checkBoxContactBox}>
                    <div className={classes.box}>
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleChecked(e, item["id"])}
                      />

                      <div className={classes.contactSingle}>
                        <ContactSingle
                          first={item["first"]}
                          last={item["last"]}
                          name={item["name"]}
                        />
                      </div>

                      <Link
                        style={{ textDecoration: "none" }}
                        to={{
                          pathname: `/contacts/${item["id"]}`,
                          state: {
                            first: item["first"],
                            last: item["last"],
                            fullname: item["fullname"],
                            phone: item["phone"],
                            email: item["email"],
                            id: item["id"],
                            key: item["id"],
                            company: item["company"],
                            title: item["title"],
                          },
                        }}
                      >
                        <button className={sharedStyles.primaryButton}>
                          View
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <Dialog
                  open={this.state.importWindow}
                  onClose={this.toggleImport}
                  aria-labelledby="responsive-dialog-title"
                  maxWidth="lg"
                  fullWidth={true}
                >
                  {!this.state.error ? (
                    <DialogTitle id="responsive-dialog-title">
                      Import CSV
                    </DialogTitle>
                  ) : (
                    <DialogTitle id="responsive-dialog-title">
                      Error!
                    </DialogTitle>
                  )}
                  <DialogContent>
                    <DialogContentText>
                      <Import
                        setError={this.setError}
                        toggle={this.toggleImport}
                        data={this.state.data}
                        handleGETfromManager={this.handleGETfromManager}
                        automaticLoader={this.automaticLoader}
                      />
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Contact));
