import React, { useState, useRef } from "react";
import FormEditButtons from "./FormEditButtons";
import { Box, CardHeader, Grid } from "@material-ui/core";
import CompanyInfoFormFields from "./CompanyInfoFormFields";

import sharedStyles from "../../Components/SharedStyles.module.css"

export function CompanyInfoCard(props) {
  const [editFormToggle, toggleForm] = useState(false);
  //dom element reference for input field where display = null
  //this allows us to have a fancy button for the photo input
  let photoRef = useRef(null);
  const {
    mountPhotoToState,
    updatedUserProfile,
    handleChange,
    discardChanges,
    saveChanges,
  } = props;
  const default_logo =
    "https://storage.googleapis.com/sku_team_resources/Gesture%20for%20Business%20Resources/Icons/default_logo.png";

  const handleUploadButton = () => {
    photoRef.current.click();
    if (!editFormToggle) toggleForm(!editFormToggle);
  };

  return (
    <Box className="card" p={1}>
      <Box mt={-1} ml={-1} mb={-3}>
        <CardHeader
          titleTypographyProps={{ variant: "h5" }}
          title="Company Info"
          bgcolor="text.disabled"
          action={
            <FormEditButtons
              toggleForm={toggleForm}
              editFormToggle={editFormToggle}
              discardChanges={discardChanges}
              saveChanges={saveChanges}
            />
          }
        />
      </Box>
      <Box m={4} mt={2} p={1} mb={1}>
        <Grid container justify="space-between" direction="row" spacing={0}>
          <Grid
            container
            item
            xs={5}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img
              className="profileAvatar"
              src={updatedUserProfile.company_logo || default_logo}
              alt="profile"
            />
            <Grid item>
              <input
                type="file"
                style={{ display: "none" }}
                onChange={mountPhotoToState}
                ref={photoRef}
                accept=".png, .jpg, .jpeg, .svg"
              />

              <button
                className={sharedStyles.primaryButton}
                onClick={handleUploadButton}
                style={{ marginleft: "7px" }}
              >
                Upload Photo
              </button>
            </Grid>
          </Grid>
          <Grid item xs={7} direction="row" spacing={0} alignItems="center">
            <CompanyInfoFormFields
              user={updatedUserProfile}
              handleChange={handleChange}
              editFormToggle={editFormToggle}
            />
            <Box mt={1}>
              <div className="subText">
                We support JPG, PNG and SVG file types
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
