import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from "./UpdatedPlans.module.css";
import sharedStyles from "../SharedStyles.module.css";

import { usePromiseTracker, trackPromise } from "react-promise-tracker";

import { PageLoader } from "../../Loader";

import { toast } from "react-toastify";
import ContainerLayout from "../ContainerLayout";

import plansLogo from "../../Assets/icons/mdi_clipboard-text-multiple-outline_active.png";

import { ToastContainer } from "react-toastify";

import { checkForToken, handleGETprofileBox, getPlans } from "../../APIManager";

function UpdatedPlans() {
	// to save plans data received from server

	const [plansData, setPlansData] = useState([]);

	// to get user info form API call

	const [userInfo, setUserInfo] = useState([]);

	// to save sorted by order plans
	const [plans, setPlans] = useState();

	const history = useHistory();

	// to get data from API fetch

	const importGetProfileInfo = async () => {
		const data = await handleGETprofileBox();

		setUserInfo(data);
	};

	const importGetPlansFunc = async () => {
		const data = await getPlans();
		// to get sorted plans info
		let plansInfo = Object.values(data?.plans)?.filter((i) =>
			data?.plan_order?.includes(i.id)
		);

		const sorted = plansInfo?.sort((a, b) => {
			const aIndex = data?.plan_order.indexOf(a.id);
			const bIndex = data?.plan_order.indexOf(b.id);
			return aIndex - bIndex;
		});
		var temp = sorted[0];
		sorted[0] = sorted[3];
		var temp2;
		for (var i = 1; i <= 3; i++) {
			temp2 = sorted[i];
			sorted[i] = temp;
			temp = temp2;
		}
		setPlans(sorted);
	};

	useEffect(() => {
		let unmounted = false;

		if (!unmounted && !checkForToken()) {
			history.push("/login");
			setTimeout(() => {
				toast.error(
					"You've been logged out. This may have been caused by using more than one device or browser",
					{
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}, 1000);
			return;
		} else {
			trackPromise(importGetProfileInfo());
			trackPromise(importGetPlansFunc());
		}

		return () => {
			unmounted = true;
		};
	}, []);
	const { promiseInProgress } = usePromiseTracker();

	function handleSubmit(e, id) {
		e.preventDefault();

		if (id === "plan-0005" && userInfo?.credit > 0) {
			toast.error(
				"Cannot purchase Pay As You Go Plan until all the credits on your balance are used",
				{
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);
		} else if (id != "plan-0007") {
			history.push({
				pathname: "/checkout",
				search: `?planId=${id}`,
				id,
			});
		}
	}

	return (
		<div className={styles.mainContainer}>
			{promiseInProgress ? (
				<div
					style={{
						height: "90vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100vw",
					}}
				>
					<PageLoader loading={promiseInProgress} />
				</div>
			) : (
				<div
					style={{
						maxWidth: "1100px",
						display: "flex",
						flexDirection: "column",
						width: "1100px",
					}}
				>
					<ContainerLayout>
						<div className={styles.mainTitleContainer}>
							<img src={plansLogo} className={styles.logo} />
							<h2 className={styles.mainTitle}>Plans</h2>
						</div>

						<ToastContainer />
						<div className={styles.plansContainer}>
							{plans?.map((d) => {
								let id = d?.id;
								return userInfo?.planId === id ? (
									<div className={styles.userPlanBox}>
										<div className={styles.titleContainer}>
											<h4
												className={
													id == "plan-0005"
														? styles.userPlanTitle0
														: id == "plan-0006"
														? styles.userPlanTitle2
														: id == "plan-0007"
														? styles.userPlanTitle5
														: id == "plan-0008"
														? styles.userPlanTitle4
														: id == "plan-0009"
														? styles.userPlanTitle1
														: styles.userPlanTitle3
												}
											>
												{id != "plan-0005" && id != "plan-0007" ? "" : null}
												<h2 className={styles.pay}>Pay</h2>
												{id != "plan-0005" && id != "plan-0007"
													? "$" + Number(d?.name)?.toLocaleString()
													: d?.name}
											</h4>
											<h2 className={styles.yourPlan}>Your Current Plan</h2>{" "}
											{d?.bonus != "0" ? (
												<h2 className={styles.bonus}>
													{d?.bonus}
													<span
														style={{
															transform: "translateX(-0.5px)",
															color: "black",
														}}
													>
														%{" "}
													</span>{" "}
													<span style={{ marginLeft: "5px", color: "black" }}>
														Bonus
													</span>
												</h2>
											) : (
												<h2 className={styles.customText}>
													<h4>Find the perfect</h4>
													<h4>Gesture</h4>
												</h2>
											)}
										</div>

										{userInfo?.planId != "plan-0005" || id != "plan-0005" ? (
											<button
												onClick={(e) => handleSubmit(e, id)}
												className={
													id == "plan-0005"
														? styles.userBtn0
														: id == "plan-0006"
														? styles.userBtn2
														: id == "plan-0007"
														? styles.userBtn5
														: id == "plan-0008"
														? styles.userBtn4
														: id == "plan-0009"
														? styles.userBtn1
														: styles.userBtn3
												}
											>
												Choose
											</button>
										) : null}
									</div>
								) : (
									<div className={styles.planBox}>
										<div className={styles.titleContainer}>
											<h2
												className={
													id == "plan-0005"
														? styles.PlanTitle0
														: id == "plan-0006"
														? styles.PlanTitle2
														: id == "plan-0007"
														? styles.PlanTitle5
														: id == "plan-0008"
														? styles.PlanTitle4
														: id == "plan-0009"
														? styles.PlanTitle1
														: styles.PlanTitle3
												}
											>
												{id != "plan-0005" && id != "plan-0007" ? "" : null}
												{id != "plan-0005" && id != "plan-0007" ? (
													<h2 className={styles.pay}>Pay</h2>
												) : null}
												{id != "plan-0005" && id != "plan-0007" ? (
													"$" + Number(d?.name)?.toLocaleString()
												) : d?.name == "Pay As You Go" ? (
													<h2 className={styles.payAsYouGo}>
														<h4>Pay As</h4>
														<h4>You Go</h4>
													</h2>
												) : (
													d?.name
												)}
											</h2>
											{d?.bonus != "0" ? (
												<h2 className={styles.bonus}>
													{d?.bonus}
													<span style={{ transform: "translateX(-0.5px)" }}>
														%{" "}
													</span>{" "}
													<span style={{ marginLeft: "5px" }}>Bonus</span>
												</h2>
											) : id == "plan-0007" ? (
												<h2 className={styles.customText2}>
													<h4>Let's Build a</h4>
													<h4>Custom Plan for</h4>
													<h4>You</h4>
												</h2>
											) : (
												<h2 className={styles.customText}>
													<h4>Find the perfect</h4>
													<h4>Gesture</h4>
												</h2>
											)}
										</div>

										{id === "plan-0007" ? (
											<a
												className={styles.link}
												target="_blank"
												href="https://gesture.biz/inquiry/"
											>
												<button className={styles.Btn5}>Choose</button>
											</a>
										) : (
											<button
												onClick={(e) => handleSubmit(e, id)}
												className={
													id == "plan-0005"
														? styles.Btn0
														: id == "plan-0006"
														? styles.Btn2
														: id == "plan-0008"
														? styles.Btn4
														: id == "plan-0009"
														? styles.Btn1
														: styles.Btn3
												}
											>
												Choose
											</button>
										)}
									</div>
								);
							})}
						</div>
					</ContainerLayout>
					<a
						className={styles.link}
						target="_blank"
						href="https://gesture.biz/inquiry/"
					>
						<span className={styles.learnMore}>
							Want to see more about plans?
						</span>
					</a>
				</div>
			)}
		</div>
	);
}

export default UpdatedPlans;
