import React, { useState, useEffect } from "react";
import styles from "./Payment.module.css";
import "react-credit-cards/es/styles-compiled.css";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";

// to display error message on cardElement input
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

import { postToken } from "../../APIManager";

import { BtnLoader } from "../../Loader";

import cardIcon from "../../Assets/icons/credit-card.png";

function Payment({ setDisable, userData, promiseInProgress, handleLogOut }) {
	// to display spinner
	const [loading, setLoading] = useState(false);

	// to get error message
	const [error, setError] = useState(null);

	// to display error message
	const [visible, setVisible] = useState(false);

	// to get the new 4 digits

	const [lastDigits, setLastDigits] = useState();

	// to disable Save button until card info is entered
	const [disableSave, setDisableSave] = useState(true);

	// to display container with the new 4 digits
	const [display, setDisplay] = useState(false);

	// to open update card info card element
	const [open, setOpen] = useState(false);

	const stripe = useStripe();

	const elements = useElements();

	useEffect(() => {
		if (userData?.last4 && !promiseInProgress) {
			setDisable(false);
		}
	}, [promiseInProgress]);

	const iframeStyles = {
		base: {
			color: "#1F1E1E",
			fontSize: "16px",
			iconColor: "#ff6600",
			"::placeholder": {
				color: "light-gray",
			},
		},
		invalid: {
			iconColor: "##DC143C",
			color: "##DC143C",
		},
		complete: {
			iconColor: "#2B08A0",
		},
	};

	const cardElementOpts = {
		iconStyle: "solid",
		style: iframeStyles,
		hidePostalCode: true,
	};
	const handleChange = (e) => {
		if (e.error) {
			setError(e.error ? e.error.message : "");
			setDisableSave(true);
		}
		setVisible(true);
		if (e.complete) {
			setDisableSave(false);
			setVisible(false);
		}
	};

	const getToken = async () => {
		if (!stripe || !elements) {
			return;
		}

		const cardElement = elements.getElement(CardElement);

		try {
			const { token } = await stripe.createToken(cardElement);
			return token;
		} catch (err) {
			console.log(err);
		}
	};
	const history = useHistory();

	async function handleSubmit(e) {
		e.preventDefault();

		setLoading(true);
		let token = await getToken();

		if (!token) {
			setError(
				"There seems to be a problem with the payment method you are trying to use, please contact support@yourgesture.com"
			);
			setVisible(true);
			setLoading(false);
			return;
		}

		try {
			let stripe_token = token.id;
			let res = await postToken({ stripe_token });

			if (res?.code === "SUCCESS") {
				setLastDigits(res?.data?.last4);
				setDisable(false);
				setLoading(false);
				setDisplay(true);
			} else if (res?.code === "FAILURE") {
				setError(res?.message);
				setLoading(false);
				setVisible(true);
			}

			// handleLogOut(history)
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	// to open payment update form
	const handleClickOpen = () => {
		setOpen(true);
		setDisable(true);
		setDisplay(false);
	};

	// to close payment update form
	const handleClickClose = () => {
		setOpen(false);
		setDisable(false);
		setDisplay(true);
		setError("");
	};

	return (
		<div className={styles.paymentContainer}>
			<div className={styles.message}>
				<img src={cardIcon} alt="credit card" className={styles.icon} />
				<p className={styles.messageTitle}>
					Your payment and personal details are encrypted and secure
				</p>
			</div> <br />

			<div className={styles.message}>
				<p className={styles.messageTitle}>
				Please enter your credit card information to proceed
				</p>
			</div>


			{promiseInProgress ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						marginTop: "30px",
					}}
				>
					<BtnLoader loading={promiseInProgress} size={50} />
				</div>
			) : null}
			<div className={styles.cardContainer}>
				{userData?.last4 && !open && !promiseInProgress && !lastDigits ? (
					<div className={styles.savedCardContainer}>
						<h3 className={styles.title}>Card ending in {userData?.last4}</h3>
						<button onClick={handleClickOpen} className={styles.btn}>
							Update
						</button>
					</div>
				) : null}

				{lastDigits && display && !promiseInProgress ? (
					<div className={styles.savedCardContainer}>
						<h3 className={styles.title}>Card ending in {lastDigits}</h3>
						<button onClick={handleClickOpen} className={styles.btn}>
							Update
						</button>
					</div>
				) : null}

				{(!userData?.last4 && !lastDigits && !promiseInProgress) ||
				(open && !display) ? (
					<>
						<form onSubmit={handleSubmit} className={styles.form}>
							<div className={styles.cardElement}>
								<CardElement
									onChange={handleChange}
									style={{
										base: {
											color: "#fff",
											fontWeight: 500,
											fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
											fontSize: "15px",
											fontSmoothing: "antialiased",
										},
									}}
									options={cardElementOpts}
									required
								/>
							</div>
							<div>
								{loading && (
									<>
										<button className={styles.btn}>
											<BtnLoader loading={loading} />
											Saving...
										</button>
									</>
								)}

								{open && !loading ? (
									<button
										disabled={!stripe || disableSave}
										className={styles.btn}
									>
										Save
									</button>
								) : null}

								{!open && !loading ? (
									<>
										<button
											disabled={!stripe || disableSave}
											className={styles.btn}
										>
											Save
										</button>
									</>
								) : null}
							</div>
						</form>
						{open && !loading ? (
							<div
								style={{
									display: "flex",
									marginTop: "10px",
									alignItems: "center",
									width: "100%",
									justifyContent: "flex-start",
									padding: "0 30px 30px",
								}}
							>
								<button className={styles.btn} onClick={handleClickClose}>
									Cancel
								</button>
							</div>
						) : null}
						{error && visible ? (
							<Box m={2}>
								<Collapse in={visible}>
									<Alert
										severity="error"
										action={
											<IconButton
												aria-label="close"
												color="inherit"
												size="small"
												onClick={() => {
													setVisible(false);
												}}
											>
												<CloseIcon fontSize="inherit" />
											</IconButton>
										}
									>
										{error}
									</Alert>
								</Collapse>
							</Box>
						) : null}
					</>
				) : null}
			</div>
		</div>
	);
}

export default Payment;
