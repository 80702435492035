import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
	TableRow,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import styles from "./ClientList.module.css";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";

import icon from "../../Assets/icons/info-icon.png";

import Tooltip from "@material-ui/core/Tooltip";

import { PageLoader } from "../../Loader";

import SearchBar from "material-ui-search-bar";

import { getClientContact, checkForToken } from "../../APIManager";

import { toast } from "react-toastify";

import sharedStyles from "../SharedStyles.module.css";

const StyledTableHead = withStyles({
	root: {
		background: "#E3E7F5",
		height: 101,
		color: "#2B08A0",
		padding: "15px",
	},
})(TableHead);

const StyledTableCell = withStyles({
	root: {
		color: "#2B08A0",
		borderBottom: " 1px solid #2B08A0",
		fontWeight: "700",
		width: "100px",
		fontFamily: "Muli",
	},
})(TableCell);

const StyledTableCellBody = withStyles({
	root: {
		fontWeight: "550",
		fontFamily: "Muli",
		fontSize: "14px",
		maxWidth: "200px",
		overflowWrap: "break-word",
		whiteSpace: "break-normal",
	},
})(TableCell);

const StyledTooltip = withStyles({
	tooltip: {
		color: "#1F1E1E",
		backgroundColor: "white",
		boxShadow: "0 2px 10px 0 rgb(0 0 0 / 20%)",
		height: "fit-content",
		fontSize: 16,
		display: "flex",
		alignItems: "center",
		padding: "15px",
	},
})(Tooltip);

function ClientList({ setOpenRecipientList }) {
	// to store data from fetch request
	const [clientList, setClientList] = useState([]);

	// to store checked values in local storage
	const [clientListValue, setClientListValue] = useState([]);

	//  if ClientList is empty redirect to People's Page
	const [redirect, setRedirect] = useState(false);

	// to implement searchBar

	const [searchTerm, setSearchTerm] = useState("");

	const { promiseInProgress } = usePromiseTracker();

	// to get API request fetch from API Manager

	const importGetClientsDataFunc = async () => {
		const data = await getClientContact();

		if (!data?.length) {
			setRedirect(true);
		}
		setClientList(
			data?.map((d) => {
				return {
					select: false,
					id: d.id,
					first: d.first,
					last: d.last,
					email: d.email,
					phone: d.phone,
					// delivery_address: d.delivery_address,
					// delivery_address2: d.delivery_address2,
					smartSend: false,
					personalNote: "",
					title: d.title,
					company: d.company,
				};
			})
		);
	};

	// to save selected contact in localStorage & close dialog popup
	const handleSave = () => {
		setOpenRecipientList(false);
		localStorage.setItem("selectedClientList", JSON.stringify(clientListValue));
	};

	const history = useHistory();

	//  if ClientList is empty redirect to People's Page

	useEffect(() => {
		let isSubscribed = true;

		if (isSubscribed && redirect) {
			history.push("./contacts");
		}

		return () => (isSubscribed = false);
	}, [redirect]);

	useEffect(() => {
		let unmounted = false;

		if (!unmounted && !checkForToken()) {
			history.push("/login");

			setTimeout(() => {
				toast.error(
					"You've been logged out. This may have been caused by using more than one device or browser",
					{
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}, 1000);
			return;
		} else {
			trackPromise(importGetClientsDataFunc());
		}

		return () => {
			unmounted = true;
		};
	}, []);

	// to get selected recipients from localStorage
	useEffect(() => {
		const sp = localStorage.getItem("selectedClientList");
		if (sp) setClientListValue(JSON.parse(sp));
	}, []);

	// to add all recipients on checkbox select
	const handleSelectAll = (e, id) => {
		let checked = e.target.checked;

		if (checked) {
			setClientListValue(
				clientList
					?.filter(
						(item) =>
							(item.first && item.last && item.email != "N/A") ||
							undefined ||
							""
					)

					.map((d) => {
						return {
							select: true,
							id: d.id,
							first: d.first,
							last: d.last,
							email: d.email,
							phone: d.phone,
							smartSend: true,
							personalNote: "",
							title: d.title,
							company: d.company,
						};
					})
			);
		} else {
			setClientListValue([]);
		}
	};

	// to add  only selected recipient

	function toggle(e, id) {
		let checked = e.target.checked;
		if (checked) {
			setClientListValue([
				...clientListValue,
				{
					...clientList.find((pi) => pi.id === id),
					select: true,
					smartSend: true,
				},
			]);
		} else {
			// to remove from localstorage
			setClientListValue(clientListValue?.filter((people) => people.id !== id));
		}
	}

	// to implement searchBar
	const filteredResults = clientList?.filter((item) => {
		return Object.keys(item)?.some((key) => {
			if (item[key] != null) {
				return item[key]
					.toString()
					.toLowerCase()
					.replace(/\s/g, "")
					.includes(searchTerm?.toLowerCase());
			}
		});
	});

	const rows = filteredResults;

	return (
		<div>
			{promiseInProgress ? (
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
					}}
				>
					<PageLoader loading={promiseInProgress} />
				</div>
			) : (
				<div style={{ marginBottom: "7%" }}>
					<TableContainer>
						<div className={styles.mainTableContainer}>
							<h2 className={styles.headerTitle}>Select Recipients</h2>
							<SearchBar
								value={searchTerm}
								onChange={(value) => setSearchTerm(value)}
								placeholder={`Search ${clientList?.length} people..`}
								className={styles.SearchBar}
								onCancelSearch={() => setSearchTerm("")}
								cancelOnEscape
							/>
						</div>

						<Table aria-label="simple table">
							<StyledTableHead>
								<TableRow>
									<StyledTableCell align="center">
										<input
											onChange={(e) => handleSelectAll(e)}
											checked={
												clientListValue.length ==
												clientList?.filter(
													(item) =>
														(item.first && item.last && item.email != "N/A") ||
														undefined ||
														""
												)?.length
											}
											type="checkbox"
										/>
									</StyledTableCell>
									<StyledTableCell align="center">First</StyledTableCell>
									<StyledTableCell align="center">Last</StyledTableCell>
									<StyledTableCell align="center">Email</StyledTableCell>
									<StyledTableCell align="center">Phone</StyledTableCell>
									<StyledTableCell align="center">Company</StyledTableCell>
									<StyledTableCell align="center">Title</StyledTableCell>
								</TableRow>
							</StyledTableHead>

							{rows?.map((d) => {
								const match = clientListValue?.find((piv) => piv.id === d.id);

								return (
									<TableBody key={d.id}>
										<TableRow>
											<StyledTableCellBody align="center">
												{(d.first && d.last && d.email === "N/A") ||
												undefined ||
												"" ? (
													<StyledTooltip
														title={
															"Please add the missing information in order to select this recipient"
														}
													>
														<img
															alt="info icon"
															src={icon}
															className={styles.icon}
														/>
													</StyledTooltip>
												) : (
													<input
														onChange={(e) => toggle(e, d.id)}
														checked={!!match?.select || ""}
														type="checkbox"
													/>
												)}
											</StyledTableCellBody>
											<StyledTableCellBody align="center">
												{d.first === "N/A" ||
												d.first === undefined ||
												d.first === "" ? (
													<span className={styles.missing}>missing</span>
												) : (
													d.first
												)}
											</StyledTableCellBody>
											<StyledTableCellBody align="center">
												{d.last === "N/A" ||
												d.last === undefined ||
												d.last === "" ? (
													<span className={styles.missing}>missing</span>
												) : (
													d.last
												)}
											</StyledTableCellBody>
											<StyledTableCellBody align="center">
												{d.email === "N/A" ||
												d.email === undefined ||
												d.email === "" ? (
													<span className={styles.missing}>missing</span>
												) : (
													d.email
												)}
											</StyledTableCellBody>
											<StyledTableCellBody align="center">
												{d.phone ? d.phone : "N/A"}
											</StyledTableCellBody>
											<StyledTableCellBody align="center">
												{d.company ? d.company : "N/A"}
											</StyledTableCellBody>
											<StyledTableCellBody align="center">
												{d.title ? d.title : "N/A"}
											</StyledTableCellBody>
										</TableRow>
									</TableBody>
								);
							})}
						</Table>
					</TableContainer>
				</div>
			)}
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					position: "absolute",
					bottom: "0",
					minHeight: "50px",
					backgroundColor: "white",
					zIndex: "100",
					width: "100%",
					paddingRight: "2rem",
				}}
			>
				<button
					id="responsive-dialog-title"
					className={sharedStyles.primaryButton}
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default withStyles(styles)(ClientList);
