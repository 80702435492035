import React, { useState } from "react";
import {
	Box,
	CardHeader,
	TextField,
	InputLabel,
	Grid,
} from "@material-ui/core";

import FormEditButtons from "./FormEditButtons";

import sharedStyles from "../../Components/SharedStyles.module.css"

import { forgotPassword } from "../../APIManager";

import { toast, ToastContainer } from "react-toastify";

import { BtnLoader } from "../../Loader";

export function ContactInfoCard(props) {
	const [editFormToggle, toggleForm] = useState(false);
	const {
		updatedUserProfile,
		handleChange,
		discardChanges,
		saveChanges,
	} = props;

	// to display spinner
	const [loading, setLoading] = useState(false);

	const { first, last, email, phoneNumber } = updatedUserProfile;

	const handleUpdatePassword = () => {
		setLoading(true);
		forgotPassword({ email }).then((res) => {
			if (res.code === "NO_USER") {
				setTimeout(() => {
					toast.error(res.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

					setLoading(false);
				}, 500);
			} else {
				setTimeout(() => {
					toast.info(
						"Your request to change the password has been received.	Please check your email for a link to reset your password",
						{
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						}
					);

					setLoading(false);
				}, 500);
			}
		});
	};

	const UpdatePasswordBtn = () =>
		loading ? (
			<button disabled={true} className={sharedStyles.updatePasswordButton}>
				<BtnLoader loading={loading} />
				Processing...{" "}
			</button>
		) : (
			<button
				disabled={loading}
				type="submit"
				className={sharedStyles.updatePasswordButton}
				onClick={handleUpdatePassword}
			>
				Change Password
			</button>
		);

	return (
		<div className="card">
			<Box mt={-1} ml={-1} mb={-3}>
				<CardHeader
					titleTypographyProps={{ variant: "h5" }}
					title="Contact Info"
					bgcolor="text.disabled"
					action={
						<FormEditButtons
							toggleForm={toggleForm}
							editFormToggle={editFormToggle}
							discardChanges={discardChanges}
							saveChanges={saveChanges}
						/>
					}
				/>
			</Box>
			<Box m={5} mt={4} p={0} ml={12} mb={0}>
				<Grid
					container
					xs={12}
					direction="column"
					spacing={0}
					alignItems="left"
				>
					<ToastContainer />
					{editFormToggle ? (
						<>
							<InputLabel id="company-fname-label">First Name</InputLabel>
							<TextField
								labelid="company-fname-label"
								className="textField"
								variant="outlined"
								name="first"
								value={first}
								onChange={(e) => handleChange(e)}
								style={{ marginBottom: "1%", marginTop: "1%" }}
							/>
							<InputLabel id="company-lname-label">Last Name</InputLabel>
							<TextField
								labelid="company-lname-label"
								className="textField"
								variant="outlined"
								name="last"
								value={last}
								onChange={(e) => handleChange(e)}
								style={{ marginBottom: "1%", marginTop: "1%" }}
							/>
							<InputLabel id="company-email-label">Email</InputLabel>
							<TextField
								labelid="company-email-label"
								className="textField"
								variant="outlined"
								name="email"
								value={email}
								onChange={(e) => handleChange(e)}
								style={{ marginBottom: "1%", marginTop: "1%" }}
								InputProps={{
									readOnly: true,
								}}
								helperText="Please contact support to change email"
							/>
							<InputLabel id="company-phone-label">Phone Number</InputLabel>
							<TextField
								labelid="company-phone-label"
								className="textField"
								type="number"
								variant="outlined"
								name="phoneNumber"
								value={phoneNumber}
								onChange={(e) => handleChange(e)}
								pattern="^-?[0-9]\d*\.?\d*$"
								style={{ marginBottom: "1%", marginTop: "1%" }}
								inputProps={{ minLength: 10, maxLength: 17 }}
							/>
						</>
					) : (
						<>
							<TextField
								className="textField"
								variant="standard"
								label="First Name"
								value={first}
								InputProps={{
									readOnly: true,
								}}
								style={{ marginBottom: "5%", marginTop: "1%" }}
							/>
							<TextField
								className="textField"
								variant="standard"
								label="Last Name"
								value={last}
								InputProps={{
									readOnly: true,
								}}
								style={{ marginBottom: "5%" }}
							/>
							<TextField
								className="textField"
								variant="standard"
								label="Email"
								value={email}
								InputProps={{
									readOnly: true,
									endAdornment: <UpdatePasswordBtn />,
								}}
								style={{ marginBottom: "5%" }}
							/>
							<TextField
								className="textField"
								variant="standard"
								label="Phone Number"
								value={phoneNumber}
								InputProps={{
									readOnly: true,
								}}
								style={{ marginBottom: "5%" }}
							/>
						</>
					)}
				</Grid>
			</Box>
		</div>
	);
}
