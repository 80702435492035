import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import avatar from "../../Pictures/g4b-profile.png";
import { withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "../../Styles/SidebarStyles/ProfileBoxStyles";
import {
  handleGETprofileBox,
  getPlanData,
  handleChangePlan,
  checkForToken,
} from "../../../../APIManager";
import { PageLoader } from "../../../../Loader";
import { ToastContainer, toast } from "react-toastify";

class ProfileBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // first: "",
      // last: "",
      // credit: 0,
      // planName: "Trial",
      // planId: "",
      // picture: "",
      profileData: {},
      loader: false,
    };
    // this.handleGET = this.handleGET.bind(this);
    this.refreshGET = this.refreshGET.bind(this);
    this.profileSetState = this.profileSetState.bind(this);
  }

  async componentDidMount() {
    try {
      if (!checkForToken()) {
        this.props.history.push("/login");
        return 0;
      } else {
        var data = await this.props.importGetProfileInfo();
        if (data === 0) {
          this.props.history.push("/login");
          return 0;
        } else {
          if (this.state.profileData) {
            this.profileSetState();
          }
        }
      }
    } catch (error) {
      console.log("caught error: ", error);
    }
  }

  componentWillReceiveProps(props) {
    if (this.state.profileData !== props) {
      // this.setState({ profileData: person });

      if (localStorage.getItem("Refresh") === "true") {
        this.setState({ loader: true });
        setTimeout(
          function () {
            localStorage.setItem("Refresh", "false");
            this.setState({
              profileData: this.props.profileData,
              loader: false,
            });
          }.bind(this),
          1000
        );
      }
    }
  }

  profileSetState() {
    this.setState({
      profileData: this.props.profileData,
    });
  }

  async refreshGET() {
    await this.props.importGetProfileInfo();
    if (localStorage.getItem("Refresh") === "true") {
      this.setState({ loader: true });
      setTimeout(
        function () {
          localStorage.setItem("Refresh", "false");
          this.setState({
            profileData: this.props.profileData,
            loader: false,
          });
        }.bind(this),
        5000
      );
    }
  }

  // async handleGET() {
  //   var tempdata = await handleGETprofileBox();
  //   if (tempdata === undefined) {
  //     return 0;
  //   }

  //   this.setState({
  //     planId: tempdata?.planId,
  //     first: tempdata?.first,
  //     last: tempdata?.last,
  //     credit: tempdata?.credit,
  //     planName: tempdata?.planName,
  //     picture: tempdata?.company_logo,
  //   });
  // }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {!this.state.loader ? (
          <div className={classes.mainContainer}>
            <div className={classes.refreshFlex}>
              <FontAwesomeIcon
                icon={faSyncAlt}
                className={classes.refreshButton}
                onClick={this.refreshGET}
              />
            </div>

            <div className={classes.nameContainer}>
              <div className={classes.avatarContainer}>
                <img
                  src={
                    this.state.profileData?.company_logo === undefined
                      ? avatar
                      : this.state.profileData.company_logo
                  }
                  className={classes.image}
                />
              </div>

              <div>
                <Link to={"/profile"} style={{ textDecoration: "none" }}>
                  <h1 className={classes.fullName}>
                    {this.state.profileData?.first === undefined
                      ? ""
                      : this.state.profileData.first}{" "}
                    {this.state.profileData?.last === undefined
                      ? ""
                      : this.state.profileData.last.charAt(0)}
                    .
                  </h1>
                </Link>
              </div>

              <div className={classes.plan}>
                {this.state?.profileData?.planId !="plan-0005" && this.state?.profileData?.planId !="plan-trial"
                  ? ""
                  : this.state.profileData.planName}{" "}

                  {this.state?.profileData?.planId === "plan-0005" ? "Plan" : null}
                {/* Plan */}
              </div>
              {/* <Link to={"/plans"} style={{ textDecoration: "none" }}>
                <button className={classes.upgradeButton}>Upgrade</button>
              </Link> */}
            </div>

            <div className={classes.balance}>
              <div className={classes.balanceRemaining}>Balance</div>
              <div className={classes.credit}>
                
                {this.state.profileData?.credit === undefined
                  ? ""
                  : "$" + this.state.profileData?.credit?.toLocaleString()}
              </div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className={classes.mainContainer}>
            <div className={classes.loaderContainer}>
              <div className={classes.loader}>
                <PageLoader />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ProfileBox));
