import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

 export function PageLoader(props) {
	return (
		
			<ClipLoader
				size={100}
				color={"#2B08A0"}
				loading={props}
				speedMultiplier={1}
			/>
	
	);
}

export function BtnLoader(props) {
	return (
		<ClipLoader
			size={20}
			color={props}
			loading={props}
			speedMultiplier={1}
            css={props}
		/>
	);
}
