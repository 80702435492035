import React from "react";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from "@material-ui/core";

export default function CompanyInfoFormFields(props) {
  const { user, handleChange, editFormToggle } = props;
  const { company, revenue, size_of_company } = user;

  return editFormToggle ? (
    <>
      <Grid item>
        <InputLabel id="company-name-label">Company Name</InputLabel>
        <TextField
          labelid="company-name-label"
          className="textFieldLong"
          variant="outlined"
          name="company"
          value={company}
          onChange={(e) => handleChange(e)}
          style={{ marginBottom: "1%", marginTop: "1%" }}
        />
      </Grid>
      <Grid item>
        <InputLabel id="company-size-label">Company Size</InputLabel>
        <Select
          labelid="company-size-label"
          className="textFieldLong"
          variant="outlined"
          name="size_of_company"
          value={size_of_company}
          onChange={(e) => handleChange(e)}
          style={{ marginBottom: "1%" }}
          inputProps={{ "aria-label": "Company Size" }}
        >
          <MenuItem value="0-10">0-10</MenuItem>
          <MenuItem value="10-100">10-100</MenuItem>
          <MenuItem value="100-500">100-500</MenuItem>
          <MenuItem value="500+">500+</MenuItem>
        </Select>
      </Grid>
      {/* <Grid item>
        <InputLabel id="company-revenue-label">Company Revenue</InputLabel>

        <Select
          className="textField"
          labelid="company-revenue-label"
          variant="outlined"
          value={revenue}
          name="revenue"
          onChange={(e) => handleChange(e)}
          style={{ marginBottom: "5%" }}
          inputProps={{ "aria-label": "Company Revenue" }}
        >
          <MenuItem value="0 - $250000">0 - $250000</MenuItem>
          <MenuItem value="$250000-$500000">$250000-$500000</MenuItem>
          <MenuItem value="$500-$1m">$500-$1m</MenuItem>
          <MenuItem value="$1m+">$1m+</MenuItem>
        </Select>
      </Grid> */}
    </>
  ) : (
    <Grid item>
      <TextField
        className="textField"
        variant="standard"
        label="Company Name"
        value={company}
        InputProps={{
          readOnly: true,
        }}
        style={{ marginBottom: "5%" }}
      />
      <TextField
        className="textField"
        variant="standard"
        label="Company Size"
        value={size_of_company}
        InputProps={{
          readOnly: true,
        }}
        style={{ marginBottom: "5%" }}
      />
      {/* <TextField
        className="textField"
        variant="standard"
        label="Company Revenue"
        value={revenue}
        InputProps={{
          readOnly: true,
        }}
        style={{ marginBottom: "5%" }}
      /> */}
    </Grid>
  );
}
