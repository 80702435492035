import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import sharedStyles from "../SharedStyles.module.css";

import { BtnLoader } from "../../Loader";
import { TextField } from "@material-ui/core";
import validator from "validator";

import logo from "../../Assets/logo.png";
import { makeStyles } from "@material-ui/styles";

import { css } from "@emotion/core";
import { forgotPassword, checkForToken } from "../../APIManager";

import { toast, ToastContainer } from "react-toastify";

import styles from "./Login.module.css";

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			backgroundColor: "rgba(224, 224, 224, 0.3)",
		},

		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},

		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
		"& .MuiOutlinedInput-input": {
			color: "white",
		},

		"&:hover .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiInputLabel-outlined": {
			color: "white",
		},
		"&:hover .MuiInputLabel-outlined": {
			color: "white",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "white",
		},
	},
});

const override = css`
	display: block;
	margin: 0 auto;
	border-color: #fff;
`;

function ForgotPassword({ setDisplay }) {
	const [email, setEmail] = useState("");

	// to display spinner
	const [loading, setLoading] = useState(false);

	// to display message if email submit was successful
	const [displayMessage, setDisplayMessage] = useState(false);

	// to track window width change
	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
	});

	function debounce(fn, ms) {
		let timer;
		return (_) => {
			clearTimeout(timer);
			timer = setTimeout((_) => {
				timer = null;
				fn.apply(this, arguments);
			}, ms);
		};
	}
	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setDimensions({
				width: window.innerWidth,
			});
		}, 400);

		window.addEventListener("resize", debouncedHandleResize);

		return (_) => {
			window.removeEventListener("resize", debouncedHandleResize);
		};
	});

	const history = useHistory();

	// if token redirect to Order's Page

	useEffect(() => {
		let unmounted = false;

		if (!unmounted && checkForToken()) {
			history.push("/");
			setDisplay(false);
		}

		return () => {
			unmounted = true;
		};
	}, [checkForToken]);
	const classes = useStyles();

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!validator.isEmail(email)) {
			toast.error("Please enter a valid email", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return 0;
		}

		setLoading(true);
		forgotPassword({ email })
		.then((res) => {
			if (res?.code === "SUCCESS") {
				setDisplayMessage(true);
			} else if (res?.code === "FAILURE") {
				setTimeout(() => {
					toast.error(res?.message, {
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					setLoading(false);

				}, 5000);

			}
		});
	};

	return (
		<div className={styles.loginPage}>
			<img
				src={logo}
				alt=" Gesture Business logo"
				className={styles.logo}
			></img>
			<div className={styles.container}>
				<ToastContainer />

				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path
						fill="#f3f4f5"
						fillOpacity="1"
						d="M0,32L48,32C96,32,192,32,288,74.7C384,117,480,203,576,218.7C672,235,768,181,864,144C960,107,1056,85,1152,106.7C1248,128,1344,192,1392,224L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
					></path>
				</svg>
				<div className={styles.form}>
					{!displayMessage ? (
						<div className={styles.headerContainer}>
							<h3 className={styles.headerTitle}>Reset Password</h3>
						</div>
					) : null}

					<div className={styles.formCenter}>
						{!displayMessage ? (
							<form onSubmit={handleSubmit} className={styles.formContainer}>
								<div className={styles.formFields}>
									{" "}
									{dimensions.width < 380 ? (
										<TextField
											type="email"
											className={classes.root}
											label="Email"
											variant="outlined"
											id="custom-css-outlined-input"
											style={{ width: "100%" }}
											onChange={(e) => setEmail(e.target.value.toLowerCase())}
											required
										/>
									) : (
										<TextField
											type="email"
											className={classes.root}
											label="Email"
											variant="outlined"
											id="custom-css-outlined-input"
											style={{ width: 360 }}
											onChange={(e) => setEmail(e.target.value.toLowerCase())}
											required
										/>
									)}
								</div>

								<div
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
										padding: "1rem 0",
									}}
								>
									{loading ? (
										<button
											disabled={true}
											// type="submit"
											className={sharedStyles.secondaryButton}
										>
											<BtnLoader loading={loading} css={override} />
										</button>
									) : (
										<button
											disabled={!email && loading}
											type="submit"
											className={sharedStyles.secondaryButton}
										>
											Reset
										</button>
									)}
								</div>
								<div className={styles.linkContainer}>
									{loading ? (
										<Link to="/login" className={styles.disabledLink}>
											Back to Login{" "}
										</Link>
									) : (
										<Link to="/login" className={styles.link}>
											Back to Login{" "}
										</Link>
									)}
								</div>
							</form>
						) : (
							<div className={styles.messageContainer}>
								<h4 className={styles.message}>
									Please check your email for a link to reset your password
								</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;
