import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProfileBox from "../ContactsPage/Components/Sidebar/ProfileBox"
// to import dashboard routes
import SidebarLinks from "./SidebarLinks";
// import logo from "../DesignAssets/Gesture_Business_Logo.jpg";

function Sidebar(props, { defaultActive }) {
  const location = props.history.location;
  const prevActiveIndexString = localStorage.getItem("prevActiveIndex");

  const prevActiveIndex = Number(prevActiveIndexString);

  const [activeIndex, setActiveIndex] = useState(
    prevActiveIndex || Number(defaultActive)
  );

  function changeActiveIndex(newIndex) {
    localStorage.setItem("prevActiveIndex", newIndex);
    setActiveIndex(newIndex);
  }

  function getPath(path) {
    if (path.charAt(0) !== "/") {
      return "/" + path;
    }
    return path;
  }
  useEffect(async () => {
    const activeItem = SidebarLinks.findIndex(
      (item) => getPath(item.route) === getPath(location.pathname)
    );
    changeActiveIndex(activeItem);
  }, [location]);

  return (
    <div className={styles.mainContainer}>
      <div
        className="sidebar"

        // className={styles.mainLogoContainer}
      >
        <ProfileBox
          profileData={props.profileData}
          importGetProfileInfo={props.importGetProfileInfo}
        />
      </div>

      {SidebarLinks.map(({ name, route, img, alt }, index) => {
        return (
          //redirect to a new route
          <div className={name} style={{ width: "100%" }} key={name}>
            <Link
              to={route}
              className={`${
                index === activeIndex
                  ? `${styles.sidebarLinkisActive}`
                  : `${styles.sidebarLink}`
              }`}
            >
              <div className={styles.dashboardContent}>
                <img src={img} alt={alt} className={styles.logo} />

                <p className={styles.linkTitle}>{name}</p>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default Sidebar;
