import React from "react";
import { withStyles } from "@material-ui/styles";
import { Box, Grid, Modal, Typography, Fade } from "@material-ui/core";
import sharedStyles from "../../Components/SharedStyles.module.css";

function DeleteConfirmation(props) {
	const { open, setDeleteModal, handleDelete, saveChanges } = props;

	return (
		<>
			<Modal open={open}>
				<Fade in={open}>
					<div className="modal-back">
						<div className="modal-body">
							<Box display="flex" alignItems="center" p={2} m={3}>
								<Grid container justify="center" spacing={3}>
									<Grid item>
										<Typography variant="h6" color="textPrimary">
											Would you like to delete the sender names?
										</Typography>
									</Grid>
									<Grid item>
										<button
											className={sharedStyles.primaryButton}
											onClick={() => {
												setDeleteModal(!open);
											}}
											style={{
												backgroundColor: "transparent",
												color: "#2B08A0",
											}}
										>
											Cancel{" "}
										</button>
									</Grid>
									<Grid item>
										<button
											className={sharedStyles.primaryButton}
											onClick={() => {
												handleDelete();
												saveChanges();
												setDeleteModal(!open);
											}}
										>
											Confirm
										</button>
									</Grid>
								</Grid>
							</Box>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
}

export default DeleteConfirmation;
