import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig =
  process.env.NODE_ENV === "development"
    ? {
        apiKey: "AIzaSyBtSK8bShTP8DH63ViI6zgkKnKoxD-vCSE",
        authDomain: "gesture-dev.firebaseapp.com",
        databaseURL: "https://gesture-dev.firebaseio.com",
        projectId: "gesture-dev",
        storageBucket: "gesture-dev.appspot.com",
        messagingSenderId: "4334941903",
        appId: "1:4334941903:web:c70206f7c8eeaa4e5d69ba",
      }
    : {
        apiKey: "AIzaSyDDERpjK2_6u55uYlH3-tC_zozWN5uEbUs",
        authDomain: "yourgestureapp.firebaseapp.com",
        databaseURL: "https://yourgestureapp.firebaseio.com",
        projectId: "yourgestureapp",
        storageBucket: "yourgestureapp.appspot.com",
        messagingSenderId: "768896016484",
        appId: "1:768896016484:web:9a8a22156570818c19ff71",
      };

//Dev config obj
// const firebaseConfig = {
//   apiKey: "AIzaSyBtSK8bShTP8DH63ViI6zgkKnKoxD-vCSE",
//   authDomain: "gesture-dev.firebaseapp.com",
//   databaseURL: "https://gesture-dev.firebaseio.com",
//   projectId: "gesture-dev",
//   storageBucket: "gesture-dev.appspot.com",
//   messagingSenderId: "4334941903",
//   appId: "1:4334941903:web:c70206f7c8eeaa4e5d69ba",
// };

//Live config obj
// const firebaseConfig = {
//   apiKey: "AIzaSyDDERpjK2_6u55uYlH3-tC_zozWN5uEbUs",
//   authDomain: "yourgestureapp.firebaseapp.com",
//   databaseURL: "https://yourgestureapp.firebaseio.com",
//   projectId: "yourgestureapp",
//   storageBucket: "yourgestureapp.appspot.com",
//   messagingSenderId: "768896016484",
//   appId: "1:768896016484:web:9a8a22156570818c19ff71",
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//analytics
// firebase.analytics();

const storage = firebase.storage();

export { storage, firebase as default };
