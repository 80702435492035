export default {
  // box: {
  //   backgroundColor: "rgba(225,225,225,0.2)",
  //   width: "700px",
  //   margin: "5px 5px 12.5px 5px",
  //   padding: "7.5px",
  //   borderRadius: "7px",
  //   boxShadow: "0 0 1px 1px rgba(15,10,20,.2)",
  // },

  singleContact: {
    textDecoration: "none",
    color: "#1F1E1E",
    fontWeight: "550",
    fontSize: "16.5px",
    display: "flex",
  },

  name: {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "flex-end",
    // marginLeft: "10px",
  },

  // nameFlexbox: {
  //   flex: "0 1 auto",
  //   position: "relative",
  //   left: "50%",
  //   transform: "translateX(-50%)",
  // },
};
