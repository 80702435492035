export const load = (stateObject) => {
    return{
        type: 'LOAD',
        payload: stateObject,
    }
}

export const read = () => {
    return{
        type: 'LOAD',
    }
}