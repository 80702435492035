import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import sharedStyles from "../SharedStyles.module.css";

const CssTextField = withStyles({
	root: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#fff",
				boxShadow: "0 0 9px 0 rgb(0 0 0 / 15%)",
			},
		},
	},
})(TextField);

const useStyles = makeStyles({
	root: {
		marginTop: 16,
		marginBottom: 16,
		padding: 16,
	},

	input: {
		margin: 10,
		height: 40,
	},
});

function PersonalNotes({ setOpenNote, data, getData, id }) {
	// to get input value
	const [notesInput, setNotesInput] = useState("");

	// to close dialog popup for personal note
	const handleCloseNote = () => {
		setOpenNote(false);
	};

	//  to display previously typed note on dialog popup reopen
	let defValue = data
		?.filter((item) => {
			return item.id === id;
		})
		?.map((d) => {
			return d.personalNote;
		})
		?.toString();

	const saveNotes = () => {
		const updateNotes = () => {
			return data?.map((item) => {
				var temp = Object.assign({}, item);
				if (temp.id === id) {
					temp.personalNote = notesInput;
				}
				return temp;
			});
		};

		localStorage.setItem("selectedClientList", JSON.stringify(updateNotes()));

		getData(updateNotes);
	};

	// to save input into state & close dialog popup for personal note
	const handleSaveNote = () => {
		setOpenNote(false);

		saveNotes();
	};

	const classes = useStyles();

	return (
		<div>
			<CssTextField
				variant="outlined"
				multiline={true}
				fullWidth={true}
				inputProps={{
					maxLength: 250,
					height: 150,

					classes: {
						notchedOutline: classes.notchedOutline,
					},
				}}
				label="Personal Note"
				rows={3}
				defaultValue={defValue}
				onChange={(e) => {
					e.preventDefault();

					setNotesInput(e.target.value);
				}}
			/>

			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
					marginTop: "3rem",
				}}
			>
				<button
					autoFocus
					onClick={handleCloseNote}
					className={sharedStyles.primaryButton}
				>
					Cancel
				</button>
				<button onClick={handleSaveNote} className={sharedStyles.primaryButton}>
					Save
				</button>
			</div>
		</div>
	);
}

export default PersonalNotes;
