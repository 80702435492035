import React, { useState } from "react";
import {
	Box,
	CardHeader,
	TextField,
	InputLabel,
	Grid,
} from "@material-ui/core";
import FormEditButtons from "./FormEditButtons";
import FormButtons from "./FormButtons";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/styles";

import styles from "./Senders.module.css";

import icon from "../../Assets/icons/info-icon.png";

const StyledTooltip = withStyles({
	tooltip: {
		color: "#1F1E1E",
		backgroundColor: "white",
		boxShadow: "0 2px 10px 0 rgb(0 0 0 / 20%)",
		height: "fit-content",
		fontSize: 16,
		display: "flex",
		alignItems: "center",
		padding: "15px",
	},
})(Tooltip);

function Senders(props) {
	const [editFormToggle, toggleForm] = useState(false);
	const {
		updatedUserProfile,
		handleChange,
		discardChanges,
		saveChanges,
		handleDelete,
	} = props;

	const { sendersForNotification, first, last } = updatedUserProfile;

	function Title() {
		return (
			<div className={styles.headerContainer}>
				Senders
				{/* <Tooltip title={<h3>.....</h3>}>
					<IconButton aria-label="help" color="primary">
						<HelpIcon />
					</IconButton>
				</Tooltip> */}
				<StyledTooltip
					title={
						"This information will be displayed to the notification that we send to your recipient via our SmartSend technology. If you omit this information, we will simply use your full name."
					}
				>
					<img alt="info icon" src={icon} className={styles.icon} />
				</StyledTooltip>
			</div>
		);
	}

	return (
		<div className="card">
			<Box mt={-1} ml={-1} mb={-3}>
				<CardHeader
					titleTypographyProps={{ variant: "h5" }}
					title={<Title />}
					bgcolor="text.disabled"
					action={
						<FormButtons
							toggleForm={toggleForm}
							editFormToggle={editFormToggle}
							discardChanges={discardChanges}
							saveChanges={saveChanges}
							sendersForNotification={sendersForNotification}
							handleDelete={handleDelete}
						/>
					}
				/>
			</Box>
			<Box m={5} mt={4} p={0} ml={12} mb={0}>
				<Grid
					container
					xs={12}
					direction="column"
					spacing={0}
					alignItems="left"
				>
					{editFormToggle ? (
						<>
							<TextField
								type="text"
								labelid="sendersForNotification"
								className="textField"
								variant="outlined"
								name="sendersForNotification"
								value={sendersForNotification}
								onChange={(e) => handleChange(e)}
								style={{ marginBottom: "1%", marginTop: "1%" }}
								placeholder="Add a sender name (John S, Jane D, David M.)"
							/>
						</>
					) : (
						<>
							<TextField
								className="textField"
								variant="standard"
								value={sendersForNotification || first + " " + last}
								InputProps={{
									readOnly: true,
								}}
								style={{ marginBottom: "5%", marginTop: "1%" }}
							/>
						</>
					)}
				</Grid>
			</Box>
		</div>
	);
}

export default withStyles(styles)(Senders);
