import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import validator from "validator";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import stringify from "json-stable-stringify";
import libphonenumber from "google-libphonenumber";
import { parsePhoneNumber, isPossiblePhoneNumber } from "libphonenumber-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import styles from "../Styles/ImportSingleStyles";
import sharedStyles from "../../SharedStyles.module.css";
import { handlePOST } from "../../../APIManager";
import { ToastContainer, toast } from "react-toastify";

class ImportSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first: "",
      last: "",
      phone: "",
      email: "",
      company: "",
      title: "",
      error: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeFirst = this.handleChangeFirst.bind(this);
    this.handleChangeLast = this.handleChangeLast.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
  }

  handleChangeCompany(value) {
    this.setState({
      company: value.target.value,
    });
  }

  handleChangeTitle(value) {
    this.setState({
      title: value.target.value,
    });
  }

  handleChangeLast(value) {
    this.setState({
      last: value.target.value,
    });
  }

  handleChangeFirst(value) {
    this.setState({
      first: value.target.value,
    });
  }

  handleChangeEmail(value) {
    this.setState({
      email: value.target.value,
    });
  }
  handleChangePhone(value) {
    this.setState({
      phone: value.target.value,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    var tracker;
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
    var contactsData = this.props.location.state.data;
    let tempcontactsarray = [];
    contactsData?.forEach((contactsDataItem) => {
      let tempdata = {};
      for (const property in contactsDataItem) {
        if (
          property === "first" ||
          property === "last" ||
          property === "email" ||
          property === "phone" ||
          property === "company" ||
          property === "title"
        ) {
          tempdata[property] = removeExtraSpace(contactsDataItem[property]);
        }
      }
      tempcontactsarray.push(tempdata);
    });
    var email = this.state.email;
    var first = this.state.first;
    var last = this.state.last;
    if (!first) {
      toast.error("Please enter a valid first name!", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return 0;
    }
    if (!last) {
      toast.error("Please enter a valid last name!", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return 0;
    }
    if (!validator.isEmail(email)) {
      toast.error("Please enter a valid email!", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return 0;
    }

    var tempdata = [
      {
        first: this.state.first,
        last: this.state.last,
        email: this.state.email,
        phone: this.state.phone,
        company: this.state.company,
        title: this.state.title,
      },
    ];

    tempdata?.forEach((arrayItem) => {
      if (removeExtraSpace(arrayItem["phone"]) === "") {
        tracker = true;
        arrayItem["phone"] = "N/A";
      } else if (removeExtraSpace(arrayItem["phone"]).length >= 17) {
        tracker = false;
        toast.error("Please enter a valid phone number", {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        arrayItem["phone"].match(/^(?=.*[0-9])[- +()0-9]+$/) === null
      ) {
        tracker = false;
        toast.error("Please enter a valid phone number", {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return 0;
      } else if (
        arrayItem["phone"].match(/^(?=.*[0-9])[- +()0-9]+$/) !== null
      ) {
        tracker = true;
        let tempnumber = (arrayItem["phone"] = parsePhoneNumber(
          arrayItem["phone"],
          "US"
        ));
        arrayItem["phone"] = removeExtraSpace(tempnumber.number);
      }
      for (const property in arrayItem) {
        if (
          arrayItem[property] === "" ||
          arrayItem[property] === null ||
          arrayItem[property] === undefined
        ) {
          arrayItem[property] = "N/A";
        }
        arrayItem[property] = removeExtraSpace(arrayItem[property]);
      }
    });

    for (let i = 0; i < tempcontactsarray.length; i++) {
      if (stringify(tempdata[0]) === stringify(tempcontactsarray[i])) {
        toast.error("This contact already exists!", {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return 0;
      }
    }

    tempdata?.forEach((arrayItem) => {
      arrayItem["fullname"] = `${arrayItem["first"]} ${arrayItem["last"]}`;
    });

    if (tracker) {
      await handlePOST(tempdata);
      this.props.history.push("/contacts");
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={sharedStyles.mainContainer}>
        <ToastContainer />
        <div className={classes.wrapper}>
          <form onSubmit={this.handleSubmit}>
            <div className={classes.title}>
              <div className={classes.titlename}>
                <Link to={"/contacts"}>
                  <ArrowBackIcon className={classes.arrowBack} />
                </Link>

                <FontAwesomeIcon icon={faUser} alt="people" />
                <h2 className={sharedStyles.mainTitle}>Add Contact</h2>
              </div>
              <div className={classes.buttonGroup}>
                <button
                  variant="contained"
                  type="submit"
                  className={sharedStyles.primaryButton}
                  // style={{ backgroundColor: "#2B08A0", color: "white" }}
                >
                  Save
                </button>

                <Link to={"/contacts"} style={{ textDecoration: "none" }}>
                  <button
                    variant="contained"
                    className={sharedStyles.primaryButton}
                    style={{ marginLeft: "25px" }}
                  >
                    Cancel
                  </button>
                </Link>
              </div>
            </div>

            <b className={classes.bold}>First Name*</b>
            <div className={classes.nameText}>
              <TextField
                className={classes.textField}
                variant="outlined"
                onChange={this.handleChangeFirst}
              />
            </div>

            <b className={classes.bold}>Last Name*</b>
            <div className={classes.nameText}>
              <TextField
                className={classes.textField}
                variant="outlined"
                onChange={this.handleChangeLast}
              />
            </div>

            <b className={classes.bold}>Phone</b>
            <div className={classes.phoneText}>
              <TextField
                className={classes.textField}
                variant="outlined"
                onChange={this.handleChangePhone}
                inputProps={{
                  maxLength: 17,
                  minLength:10,
                }}
              />
            </div>

            <b className={classes.bold}>Email*</b>
            <div className={classes.emailText}>
              <TextField
                className={classes.textField}
                variant="outlined"
                onChange={this.handleChangeEmail}
              />
            </div>

            <b className={classes.bold}>Company</b>
            <div className={classes.companyText}>
              <TextField
                className={classes.textField}
                variant="outlined"
                onChange={this.handleChangeCompany}
              />
            </div>

            <b className={classes.bold}>Title</b>
            <div className={classes.delivery_titleText}>
              <TextField
                className={classes.textField}
                variant="outlined"
                onChange={this.handleChangeTitle}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(ImportSingle));
