import React from "react";
import styles from "./SelectSenders.module.css";

import { TextField } from "@material-ui/core";

import { useHistory, Link } from "react-router-dom";

import sharedStyles from "../../Components/SharedStyles.module.css";

function SelectSenders({ userInfo }) {
	const history = useHistory();
	const handleRedirect = () => {
		history.push("/profile");
	};

	console.log(userInfo);
	return (
		<div className={styles.mainContainer}>
			<div className={styles.header}>
				<h3 className={styles.mainTitle}>Who is sending this Gift? </h3>
				<div className={styles.underTitle}>
					The following senders will be shown in the email to your recipient
				</div>
			</div>
			<div className={styles.inputContainer}>
				<button onClick={handleRedirect} className={sharedStyles.primaryButton}>
					{/* {userInfo?.sendersForNotification?.trim() ? "Edit" : "Add"}{" "} */}
					Edit
				</button>{" "}
			</div>
			<TextField
				value={userInfo?.sendersForNotification || userInfo?.first + " " + userInfo?.last}
				InputProps={{
					readOnly: true,
					style: { fontSize: 16, fontWeight: 700 },
				}}
				placeholder="Add a sender name (John S, Jane D, David M.)"
				variant="standard"
			/>
			{userInfo?.first?.trim() && !userInfo?.sendersForNotification?.trim() ? (
				<div className={styles.underNote}>
					Note: the default sender name that will be displayed in the email is{" "}
					{userInfo?.first + " " + userInfo?.last}
				</div>
			) : null}
		</div>
	);
}

export default SelectSenders;
