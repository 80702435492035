import React, { useState, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { BtnLoader } from "../../Loader";
import { TextField } from "@material-ui/core";

import logo from "../../Assets/logo.png";
import { makeStyles } from "@material-ui/styles";
import { toast, ToastContainer } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import { css } from "@emotion/core";

import { resetPassword } from "../../APIManager";

import sharedStyles from "../SharedStyles.module.css";
import styles from "./Login.module.css";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const override = css`
	display: block;
	margin: 0 auto;
	border-color: #fff;
`;

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			backgroundColor: "rgba(224, 224, 224, 0.3)",
		},

		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},

		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
		"& .MuiOutlinedInput-input": {
			color: "white",
		},

		"&:hover .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiInputLabel-outlined": {
			color: "white",
		},
		"&:hover .MuiInputLabel-outlined": {
			color: "white",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "white",
		},
		"& .MuiIconButton-root": {
			color: "white",
		},
		"& .MuiSvgIcon-root": {
			width: "16px",
		},
	},
});

function ResetPassword() {
	//  To get password value
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	// to display spinner
	const [loading, setLoading] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const classes = useStyles();

	// to display message if email submit was successful
	const [displayMessage, setDisplayMessage] = useState(false);

	// to hide/display password

	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleClickShowConfirmPassword = () =>
		setShowConfirmPassword(!showConfirmPassword);

	// to track window width change
	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
	});

	let location = useLocation();
	const query = new URLSearchParams(location.search);

	const token = query.get("token");

	function debounce(fn, ms) {
		let timer;
		return (_) => {
			clearTimeout(timer);
			timer = setTimeout((_) => {
				timer = null;
				fn.apply(this, arguments);
			}, ms);
		};
	}
	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setDimensions({
				width: window.innerWidth,
			});
		}, 400);

		window.addEventListener("resize", debouncedHandleResize);

		return (_) => {
			window.removeEventListener("resize", debouncedHandleResize);
		};
	});

	const history = useHistory();
	const handleUpdate = (e) => {
		e.preventDefault();
		setLoading(true);

		if (password !== confirmPassword) {
			toast.error("Passwords don't match", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			setLoading(false);
		} else {
			resetPassword({ password, token }).then((res) => {
				if (res?.code === "SUCCESS") {
					localStorage.removeItem("token");
					history.push("/login");

					setTimeout(() => {
						toast.info(
							"You successfully changed your password, we have logged you out of all other sessions that were active",
							{
								position: "top-center",
								autoClose: 5000,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							}
						);

						setLoading(false);
					}, 500);
				} else if (res?.code === "FAILURE") {
					setTimeout(() => {
						toast.error(res?.message, {
							position: "top-center",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});

						setLoading(false);
					}, 5000);
				}
			});

			// .then((res) => {
			// 	setLoading(false);

			// 	if (res.code === "INVALID_TOKEN") {
			// 		setTimeout(() => {
			// 			toast.error(res.message, {
			// 				position: "top-center",
			// 				autoClose: 5000,
			// 				hideProgressBar: true,
			// 				closeOnClick: true,
			// 				pauseOnHover: true,
			// 				draggable: true,
			// 				progress: undefined,
			// 			});

			// 			setLoading(false);
			// 		}, 500);
			// 	} else if (res.code === "SUCCESS") {
			// 		localStorage.removeItem("token");
			// 		history.push("/login");

			// 		setTimeout(() => {
			// 			toast.info(
			// 				"You successfully changed your password, we have logged you out of all other sessions that were active",
			// 				{
			// 					position: "top-center",
			// 					autoClose: 5000,
			// 					hideProgressBar: true,
			// 					closeOnClick: true,
			// 					pauseOnHover: true,
			// 					draggable: true,
			// 					progress: undefined,
			// 				}
			// 			);

			// 			setLoading(false);
			// 		}, 500);
			// 	}
			// });
		}
	};

	return (
		<div className={styles.loginPage}>
			<img
				src={logo}
				alt=" Gesture Business logo"
				className={styles.logo}
			></img>
			<div className={styles.container}>
				<ToastContainer />

				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path
						fill="#f3f4f5"
						fillOpacity="1"
						d="M0,32L48,32C96,32,192,32,288,74.7C384,117,480,203,576,218.7C672,235,768,181,864,144C960,107,1056,85,1152,106.7C1248,128,1344,192,1392,224L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
					></path>
				</svg>
				<div className={styles.form}>
					<div className={styles.headerContainer}>
						<h3 className={styles.headerTitle}>Update Password</h3>
					</div>

					<div className={styles.formCenter}>
						<form onSubmit={handleUpdate} className={styles.formContainer}>
							<div className={styles.formFields}>
								{" "}
								{dimensions.width < 380 ? (
									<TextField
										// type="password"
										type={showPassword ? "text" : "password"}
										className={classes.root}
										label="Password"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: "100%" }}
										onChange={(e) => setPassword(e.target.value)}
										required
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
													>
														{showPassword ? (
															<VisibilityIcon />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								) : (
									<TextField
										// type="password"
										type={showPassword ? "text" : "password"}
										className={classes.root}
										label="Password"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: 360 }}
										onChange={(e) => setPassword(e.target.value)}
										required
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
													>
														{showPassword ? (
															<VisibilityIcon />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								)}
							</div>

							<div className={styles.formFields}>
								{dimensions.width < 380 ? (
									<TextField
										// type="password"
										type={showConfirmPassword ? "text" : "password"}
										className={classes.root}
										label="Confirm Password"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: "100%" }}
										onChange={(e) => setConfirmPassword(e.target.value)}
										required
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowConfirmPassword}
													>
														{showConfirmPassword ? (
															<VisibilityIcon />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								) : (
									<TextField
										// type="password"
										type={showConfirmPassword ? "text" : "password"}
										className={classes.root}
										label="Confirm Password"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: 360 }}
										onChange={(e) => setConfirmPassword(e.target.value)}
										required
										required
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowConfirmPassword}
													>
														{showConfirmPassword ? (
															<VisibilityIcon />
														) : (
															<VisibilityOffIcon />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								)}
							</div>

							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									padding: "1rem 0",
								}}
							>
								{loading ? (
									<button
										disabled={true}
										// type="submit"
										className={sharedStyles.secondaryButton}
									>
										<BtnLoader loading={loading} css={override} />
									</button>
								) : (
									<button
										disabled={loading}
										type="submit"
										className={sharedStyles.secondaryButton}
									>
										Update
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
