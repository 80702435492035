import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Header from "./Header";
import logo from "../../Assets/logo.png";
import Select, { components } from "react-select";
import myStyles from "./SignUp.module.css";
import sharedStyles from "../SharedStyles.module.css";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/core";
import validator from "validator";

import { ToastContainer, toast } from "react-toastify";

import { BtnLoader } from "../../Loader";

import { TextField } from "@material-ui/core";

import { signUpUser, checkForToken } from "../../APIManager";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	root: {
		"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			backgroundColor: "rgba(224, 224, 224, 0.3)",
		},

		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},

		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "white",
		},
		"& .MuiOutlinedInput-input": {
			color: "white",
		},

		"&:hover .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
			color: "white",
		},
		"& .MuiInputLabel-outlined": {
			color: "white",
		},
		"&:hover .MuiInputLabel-outlined": {
			color: "white",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "white",
		},
	},
});

// to add floating label to Select

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
	return (
		<ValueContainer {...props}>
			<Placeholder {...props} isFocused={props.isFocused}>
				{props.selectProps.placeholder}
			</Placeholder>
			{React.Children.map(children, (child) =>
				child && child.type !== Placeholder ? child : null
			)}
		</ValueContainer>
	);
};

const override = css`
	display: block;
	margin: 0 auto;
	border-color: #fff;
`;

function SignUp({ setDisplay }) {
	// to get first name
	const [firstName, setFirstName] = useState("");

	// to get last name
	const [lastName, setLastName] = useState("");

	//  To get email value
	const [email, setEmail] = useState("");

	//  To get password value
	const [password, setPassword] = useState("");

	// to get company name
	const [companyName, setCompanyName] = useState("");

	// to get phone number
	const [phoneNumber, setPhoneNumber] = useState("");

	// to get size of the company
	const [companySize, setCompanySize] = useState("");

	// to display Laader

	const [loading, setLoading] = useState(false);

	// to disable btn until success/error message received from server

	const [processing, setProcessing] = useState(false);

	const history = useHistory();

	let token = checkForToken();

	// to track window width change
	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
	});

	// window["hideChat"]();


	function debounce(fn, ms) {
		let timer;
		return (_) => {
			clearTimeout(timer);
			timer = setTimeout((_) => {
				timer = null;
				fn.apply(this, arguments);
			}, ms);
		};
	}
	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setDimensions({
				width: window.innerWidth,
			});
		}, 400);

		window.addEventListener("resize", debouncedHandleResize);

		return (_) => {
			window.removeEventListener("resize", debouncedHandleResize);
		};
	});
	// if token redirect to Plans Page

	useEffect(() => {
		let isSubscribed = true;

		if (isSubscribed && token) {
			history.push("/");
			setDisplay(true);
		}
		return () => (isSubscribed = false);
	}, [token]);

	let tokenAuth =
		"183e08f5-a234-4941-998a-be2367a7a33f62e2691d-7ccf-4850-b07d-56dc1f121394";
	let location = useLocation();
	const query = new URLSearchParams(location.search);

	const tokenQuery = query.get("token");

	useEffect(() => {
		if (tokenQuery != tokenAuth) {
			history.push("/login");
			setDisplay(false);
		}
	}, []);

	// to disable btn until company size selected
	useEffect(() => {
		setProcessing(companySize ? true : false);
	}, [companySize]);

	// to sign up
	const handleSubmit = (e) => {
		e.preventDefault();

		if (!validator.isEmail(email)) {
			toast.error("Please enter a valid email", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return 0;
		}

		setLoading(true);
		signUpUser({
			firstName,
			lastName,
			companyName,
			email,
			phoneNumber,
			password,
			companySize,
		});

		if (!token) {
			setTimeout(
				() => {
					setLoading(false);
					setFirstName("");
					setLastName("");
					setCompanyName("");
					setPhoneNumber("");
					setCompanySize("");
				},

				5000
			);
		}
	};

	const options = [
		{ value: "0-10", label: "0-10" },
		{ value: "10-100", label: "10-100" },
		{ value: "100-500", label: "100-500" },
		{ value: "500+", label: "500+" },
	];

	const customStyles = {
		control: (base) => ({
			...base,
			height: 35,
			minHeight: 35,
		}),
	};

	const classes = useStyles();

	return (
		<div className={myStyles.signUpPage}>
			<img
				src={logo}
				alt=" Gesture Business logo"
				className={myStyles.logo}
			></img>
			<div className={myStyles.container}>
				<ToastContainer />

				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
					<path
						fill="#f3f4f5"
						fillOpacity="1"
						d="M0,32L48,32C96,32,192,32,288,74.7C384,117,480,203,576,218.7C672,235,768,181,864,144C960,107,1056,85,1152,106.7C1248,128,1344,192,1392,224L1440,256L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
					></path>
				</svg>

				<div className={myStyles.form}>
					<Header />

					<div className={myStyles.formCenter}>
						<form onSubmit={handleSubmit} className={myStyles.formContainer}>
							<div className={myStyles.flex}>
								<div className={myStyles.formFields}>
									{dimensions.width < 380 ? (
										<TextField
											type="text"
											className={classes.root}
											label="First Name"
											variant="outlined"
											id="custom-css-outlined-input"
											style={{ width: "100%" }}
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
											required
										/>
									) : (
										<TextField
											type="text"
											className={classes.root}
											label="First Name"
											variant="outlined"
											id="custom-css-outlined-input"
											style={{ width: 360 }}
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
											required
										/>
									)}
								</div>
								<div className={myStyles.formFields}>
									{dimensions.width < 380 ? (
										<TextField
											type="text"
											className={classes.root}
											label="Last Name"
											variant="outlined"
											id="custom-css-outlined-input"
											style={{ width: "100%" }}
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
											required
										/>
									) : (
										<TextField
											type="text"
											className={classes.root}
											label="Last Name"
											variant="outlined"
											id="custom-css-outlined-input"
											style={{ width: 360 }}
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
											required
										/>
									)}
								</div>
							</div>
							<div className={myStyles.formFields}>
								{" "}
								{dimensions.width < 380 ? (
									<TextField
										type="email"
										className={classes.root}
										label="Email"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: "100%" }}
										onChange={(e) => setEmail(e.target.value.toLowerCase())}
										required
										name="email"
										value={email}
									/>
								) : (
									<TextField
										type="email"
										className={classes.root}
										label="Email"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: 360 }}
										onChange={(e) => setEmail(e.target.value.toLowerCase())}
										required
										value={email}
										name="email"
									/>
								)}
							</div>
							<div className={myStyles.formFields}>
								{" "}
								{dimensions.width < 380 ? (
									<TextField
										type="password"
										className={classes.root}
										label="Password"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: "100%" }}
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								) : (
									<TextField
										type="password"
										className={classes.root}
										label="Password"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{
											width: 360,
										}}
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								)}
							</div>
							<div className={myStyles.formFields}>
								{dimensions.width < 380 ? (
									<TextField
										type="text"
										className={classes.root}
										label="Company Name"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: "100%" }}
										value={companyName}
										onChange={(e) => setCompanyName(e.target.value)}
										required
									/>
								) : (
									<TextField
										type="text"
										className={classes.root}
										label="Company Name"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: 360 }}
										value={companyName}
										onChange={(e) => setCompanyName(e.target.value)}
										required
									/>
								)}
							</div>
							<div className={myStyles.formFields}>
								{dimensions.width < 380 ? (
									<TextField
										type="tel"
										className={classes.root}
										label="Phone Number"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: "100%" }}
										value={phoneNumber}
										inputProps={{ minLength: 10,maxLength:17 }}
										pattern="^-?[0-9]\d*\.?\d*$"
										onChange={(e) => {
											const re = /^[0-9\b]+$/;
											if (e.target.value === "" || re.test(e.target.value)) {
												setPhoneNumber(e.target.value);
											}
										}}
										required
									/>
								) : (
									<TextField
										type="tel"
										className={classes.root}
										label="Phone Number"
										variant="outlined"
										id="custom-css-outlined-input"
										style={{ width: 360 }}
										value={phoneNumber}
										inputProps={{ minLength: 10,maxLength:17 }}
										pattern="^-?[0-9]\d*\.?\d*$"
										onChange={(e) => {
											const re = /^[0-9\b]+$/;
											if (e.target.value === "" || re.test(e.target.value)) {
												setPhoneNumber(e.target.value);
											}
										}}
										required
									/>
								)}
							</div>
							<div className={myStyles.formFields}>
								{" "}
								<Select
									options={options}
									onChange={(e) => setCompanySize(e.value)}
									value={options.filter(function(option) {
										return option.value === companySize;
									})}
									label="Single select"
									myStyles={customStyles}
									menuPlacement="top"
									components={{
										ValueContainer: CustomValueContainer,
									}}
									placeholder="Select Company Size"
									styles={{
										container: (provided) => ({
											...provided,
											marginTop: "12px",
										}),
										valueContainer: (provided) => ({
											...provided,
											overflow: "visible",
											height: "35px",
										}),
										placeholder: (provided, state) => ({
											...provided,
											position: "absolute",

											top:
												state.hasValue || state.selectProps.inputValue
													? -15
													: "50%",
											transition: "top 0.1s, font-size 0.1s",
											fontSize:
												(state.hasValue || state.selectProps.inputValue) && 15,
											color:
												(state.hasValue || state.selectProps.inputValue) &&
												"white",
										}),
									}}
								/>
							</div>

							<div
								style={{
									width: "100%",
									display: "flex",
									justifyContent: "center",
									padding: "1rem 0",
								}}
							>
								{loading ? (
									<button
										disabled={true}
										className={sharedStyles.secondaryButton}
									>
										<BtnLoader loading={loading} css={override} />
									</button>
								) : (
									<button
										disabled={!processing}
										type="submit"
										className={sharedStyles.secondaryButton}
									>
										Sign Up{" "}
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SignUp;
