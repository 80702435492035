import React from "react";
import styles from "./Header.module.css";
import { NavLink } from "react-router-dom";

function Header() {
	return (
		<div className={styles.headerTitles}>
			<NavLink
				to="/signup"
				activeClassName={styles.activeClass}
				className={styles.link}
			>
				<h3 className={styles.headerTitle}>Sign Up</h3>
			</NavLink>
			<span style={{ marginRight: "21px" }}></span>
			<NavLink
				activeClassName={styles.activeClass}
				className={styles.link}
				to="/login"
			>
				<h3 className={styles.headerTitle}>Login</h3>
			</NavLink>
		</div>
	);
}

export default Header;
