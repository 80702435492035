import React, { useState } from "react";
import { withStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import sharedStyles from "../../Components/SharedStyles.module.css";
import ModalConfirmation from "./ModalConfirmation";
import DeleteConfirmation from "./DeleteConfirmation";

function FormButtons(props) {
	const {
		editFormToggle,
		toggleForm,
		discardChanges,
		saveChanges,
		sendersForNotification,
		handleDelete,
	} = props;
	const [modalToggle, toggleModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	return (
		<>
			<ModalConfirmation
				open={modalToggle}
				toggleModal={toggleModal}
				toggleForm={toggleForm}
				saveChanges={saveChanges}
			/>
			<DeleteConfirmation
				open={deleteModal}
				setDeleteModal={setDeleteModal}
				toggleForm={toggleForm}
				saveChanges={saveChanges}
				handleDelete={handleDelete}
			/>
			<Box mt={1.5}>
				{editFormToggle ? (
					<div className="card-edit">
						<button
							onClick={() => {
								discardChanges();
								toggleForm(false);
							}}
							className={sharedStyles.primaryButton}
							style={{
								marginRight: "5px",
								backgroundColor: "transparent",
								color: "#2B08A0",
							}}
						>
							Cancel
						</button>

						<button
							onClick={() => {
								toggleModal(!modalToggle);
							}}
							className={sharedStyles.primaryButton}
							disabled={!sendersForNotification?.trim()}
						>
							Save
						</button>
					</div>
				) : (
					<div className="card-edit">
						{sendersForNotification?.trim() ? (
							<button
								className={sharedStyles.primaryButton}
								onClick={() => {
									setDeleteModal(!deleteModal);
								}}
								style={{
									backgroundColor: "transparent",
									marginRight: "5px",
									color: "#2B08A0",
								}}
							>
								Delete
							</button>
						) : null}
						<button
							className={sharedStyles.primaryButton}
							style={{ marginRight: "5px" }}
							onClick={() => toggleForm(!editFormToggle)}
						>
							{/* {sendersForNotification?.trim() ? "Edit" : "Add"} */}
							Edit
						</button>
					</div>
				)}
			</Box>
		</>
	);
}

export default withStyles(sharedStyles)(FormButtons);
