import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./CustomSku.module.css";
import sharedStyles from "../SharedStyles.module.css";
import { withStyles } from "@material-ui/styles";

import Dialog from "@material-ui/core/Dialog";
import { DialogTitle } from "@material-ui/core";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { DialogActions } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import { faMinusCircle, faGifts } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import deleteIcon from "../../Assets/icons/x.png";

const useStyles = makeStyles({
	root: {
		marginTop: 16,
		marginBottom: 16,
		padding: 16,
	},

	input: {
		margin: 10,
		height: 40,
		width: 300,
	},
});

const CssTextField = withStyles({
	root: {
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "#fff",
				boxShadow: "0 0 9px 0 rgb(0 0 0 / 15%)",
			},
		},
	},
})(TextField);

function CustomSku({
	customSkus,
	setCustomSkus,
	notesValue,
	setNotesValue,
	setOpen,
	skuSelected,
}) {
	// to get value from input

	const [input, setInput] = useState([]);

	// to get value from  notes input

	const [notesInput, setNotesInput] = useState(notesValue);

	const [update, setUpdate] = useState(customSkus);

	const [edit, setEdit] = useState(false);

	//  to open/close confirmation dialog informing user that if Custom Gesture is selected the other Gestures will be removed

	const [openConfirm, setOpenConfirm] = useState(false);

	const handleClose = () => {
		setOpenConfirm(false);
	};

	useEffect(() => {
		localStorage.setItem("customSkus", JSON.stringify(customSkus));
	}, [customSkus?.length]);

	const classes = useStyles();

	function handleRemove(inputId) {
		const values = [...input];

		let newArray = values?.filter((item) => item.id !== inputId);

		setInput(newArray);
	}

	function handleRemoveUpdate(updateId) {
		const arr = [...update];

		let newArray = arr?.filter((item) => item.id !== updateId);

		setUpdate(newArray);

		setEdit(true);
	}

	function handleChange(id, e) {
		e.preventDefault();

		if (/^\s/.test(e.target.value)) {
			e.target.value = "";
		}

		setInput((values) =>
			values.map((el) =>
				el.id === id
					? {
							...el,
							value: e.target.value,
					  }
					: el
			)
		);
	}

	function handleAddInput() {
		setInput((input) =>
			input.concat({
				id: uuidv4(),
				value: "",
			})
		);
	}

	const handleSave = (e) => {
		e.preventDefault();

		if (skuSelected?.length > 0) {
			setOpenConfirm(true);
			return;
		}

		if (edit) {
			setCustomSkus(update);
		}

		setCustomSkus((customSkus) =>
			customSkus.map((el) => ({
				...el,
				value: e.target[el.id]?.value,
			}))
		);

		setCustomSkus((customSkus) => customSkus.concat(input));

		setNotesValue(notesInput);

		setOpen(false);
		localStorage.setItem("dialogOpen", false);
	};

	const handleSaveN = (e) => {
		e.preventDefault();

		if (edit) {
			setCustomSkus(update);
		}

		setCustomSkus((customSkus) =>
			customSkus.map((el) => ({
				...el,
				value: e.target[el.id]?.value,
			}))
		);

		setCustomSkus((customSkus) => customSkus.concat(input));

		setNotesValue(notesInput);

		setOpen(false);
		localStorage.setItem("dialogOpen", false);
	};

	return (
		<>
			<form className={styles.mainContainer} onSubmit={handleSave}>
				<h4 className={styles.title}>Notes</h4>
				<CssTextField
					variant="outlined"
					multiline={true}
					inputProps={{
						maxLength: 250,
						height: 150,

						classes: {
							notchedOutline: classes.notchedOutline,
						},
					}}
					label="Description"
					placeholder="Please add detailed description..."
					rows={3}
					defaultValue={notesValue}
					onChange={(e) => {
						e.preventDefault();

						if (/^\s/.test(e.target.value)) {
							e.target.value = "";
						}

						setNotesInput(e.target.value?.trim());
					}}
				/>
				<div className={styles.productsMainContainer}>
					<div className={styles.productsContainerHeader}>
						<h4 className={styles.title}>Products</h4>

						<button
							type="button"
							className={sharedStyles.primaryButton}
							onClick={handleAddInput}
						>
							Add Product
						</button>
					</div>
					<div className={styles.mainAddSkusContainer}>
						<div>
							{update?.map((d, i) => {
								const updateId = d.id;
								return (
									<div key={updateId} className={styles.inputContainer}>
										<TextField
											InputProps={{
												className: classes.input,
											}}
											inputProps={{
												maxLength: 36,
											}}
											id="outlined-basic"
											id={updateId}
											defaultValue={d.value}
											variant="outlined"
											label="Product"
											required
										/>
										<button
											type="button"
											className={styles.button}
											onClick={(e) => handleRemoveUpdate(updateId, e)}
										>
											<img
												src={deleteIcon}
												className={styles.icon}
												alt="delete"
											/>
										</button>
									</div>
								);
							})}
						</div>
						{input?.map((input, idx) => {
							let inputId = input.id;
							return (
								<div key={idx} className={styles.inputContainer}>
									<TextField
										InputProps={{
											className: classes.input,
										}}
										inputProps={{
											maxLength: 36,
										}}
										id="outlined-basic"
										label="Product"
										variant="outlined"
										placeholder="Add product name"
										onChange={(e) => handleChange(input.id, e)}
										value={input.value}
										autoFocus
										required
									/>

									<button
										type="button"
										className={styles.button}
										onClick={(e) => handleRemove(inputId, e)}
									>
										<img
											src={deleteIcon}
											className={styles.icon}
											alt="delete"
										/>
									</button>
								</div>
							);
						})}
					</div>
				</div>{" "}
				<div className={styles.saveBtnContainer}>
					<button
						type="submit"
						id="responsive-dialog-title"
						className={styles.saveBtn}
					>
						{" "}
						Save
					</button>
				</div>
				{/* dialog to inform user that Custom Gestures cann't be combined with other GEstures and by saving Custom Gesture user agrees that all selected previoulsy other GEstures will be deleted */}
				<Dialog
					open={openConfirm}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title"
					maxWidth="sm"
					fullWidth={true}
				>
					<DialogTitle id="alert-dialog-title">
						Custom Gestures can't be combined with Regular Gestures.{" "}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							By submitting the Custom Gesture, this will remove all your
							current Gesture Selections
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-evenly",
								paddingBottom: "20px",
							}}
						>
							<button
								className={sharedStyles.primaryButton}
								onClick={handleClose}
							>
								Cancel
							</button>
							<button
								className={sharedStyles.primaryButton}
								color="primary"
								autoFocus
								onClick={(e) => handleSaveN(e)}
							>
								Okay
							</button>
						</div>
					</DialogActions>
				</Dialog>
			</form>
		</>
	);
}

export default CustomSku;
