import React, { useState, useRef } from "react";
import { Box, CardHeader } from "@material-ui/core";
import { usePromiseTracker } from "react-promise-tracker";
import Payment from "../Checkout/Payment";

export function PaymentInfoCard(props) {
  const [editFormToggle, toggleForm] = useState(true);
  const { promiseInProgress } = usePromiseTracker();

  const { updatedUserProfile } = props;

  return (
    <Box mb={3}>
      <div className="card">
        <Box mt={-1} ml={-1} mb={-3}>
          <CardHeader
            titleTypographyProps={{ variant: "h5" }}
            title="Payment Info"
            bgcolor="text.disabled"
          />
        </Box>
        <Box m={3} p={5}>
          <Payment
            userData={updatedUserProfile}
            disable={editFormToggle}
            setDisable={toggleForm}
            promiseInProgress={promiseInProgress}
          />
        </Box>
      </div>
    </Box>
  );
}
