import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { load } from "../../actions/index";

import styles from "./Receipt.module.css";

// import sharedStyles from "../../components/SharedStyles.module.css";
import sharedStyles from "../SharedStyles.module.css";

import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@material-ui/core/Box";

import { DialogActions } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";

import { DialogTitle } from "@material-ui/core";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import { withStyles } from "@material-ui/core/styles";

import CustomSku from "./CustomSku";

import { BtnLoader } from "../../Loader";

import deleteIcon from "../../Assets/icons/x.png";
import editIcon from "../../Assets/icons/edit-icon.png";
import giftIcon from "../../Assets/icons/custom-gesture-sku.png";

import {
	postOrder,
	handleGETprofileBox,
	checkForToken,
} from "../../APIManager";

import Select, { components } from "react-select";

const StyledDialogContent = withStyles({
	root: {
		padding: "0",
		"&:first-child": {
			padding: "0",
		},
	},
})(DialogContent);

const StyledDialogTitle = withStyles({
	root: {
		padding: "15px 30px",
		borderBottom: "1px solid #e3e7f5",
		backgroundColor: "#e3e7f5",
	},
})(DialogTitle);

function Receipt({
	skuSelected,
	contactsSelected,
	scheduledDateSelected,
	setScheduledDateSelected,
	customSkus,
	setCustomSkus,
	setSkuSelected,
	notesValue,
	setNotesValue,
	setContactsSelected,
	importGetProfileInfo,
	handleLogOut,
	setSmartSendScheduledDate,
	smartSendScheduledDate,
	userInfo,
	promiseInProgress,
	calculatedPrice = 0,
	checkOut = false,
	stateObject = {},
}) {
	const dispatch = useDispatch();

	const history = useHistory();

	// To open/close message if contact/sku/data is missing
	const [visible, setVisible] = useState(false);

	// to display message if delivery date is missing
	const [isDeliveryDate, setIsDeliveryDate] = useState(false);

	// to display message if smartsend date is missing
	const [isSmartSendDeliveryDate, setIsSmartSendDeliveryDate] = useState(false);

	// to display message if contact is missing

	const [isSelectedContacts, setIsSelectedContacts] = useState(false);

	// to display message if sku is missing

	const [isSelectedSkus, setIsSelectedSkus] = useState(false);

	// create cart object customGesture and store/track custom skus value

	const [customGesture, setCustomGesture] = useState();

	// to display message if customSku is missing

	const [isSelectedCustomSkus, setIsSelectedCustomSkus] = useState(false);

	// open/close edit dialog for custom gesture

	const [open, setOpen] = useState(false);

	// open/close Delete Custom Gestures in custom gesture dialog window

	const [openConfirm, setOpenConfirm] = useState(false);

	// to display error message if post fetch returns error
	const [error, setError] = useState(false);

	// to display error message from failed fetch post request
	const [errMessage, setErrMessage] = useState();

	// to display Loader while posting order
	const [processing, setProcessing] = useState(false);

	const [quantity, setQuantityInput] = useState();

	const [skuId, setSkuId] = useState();

	const [updateCount, setUpdateCount] = useState();

	// to calculate diff between user credit & Gesture total price
	const [diff, setDiff] = useState();

	// to calculate  user credit
	const [credit, setCredit] = useState();

	const [isLoaded, setIsLoaded] = useState(false);
	const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps



	let sendersForNotification = userInfo?.sendersForNotification 

	useEffect(() => {
		setIsLoaded(true);
	}, []);
	// to check if delivery day is selected

	function checkForDate() {
		if (scheduledDateSelected === null) {
			setIsDeliveryDate(false);
		} else {
			setIsDeliveryDate(true);
		}
	}

	// to check if  smartSend delivery day is selected

	function checkForSmartSendDate() {
		if (smartSendScheduledDate === null) {
			setIsSmartSendDeliveryDate(false);
		} else {
			setIsSmartSendDeliveryDate(true);
		}
	}

	// to check everytime new date is selected
	useEffect(() => {
		checkForDate();
	}, [scheduledDateSelected]);

	// to check everytime new smartSend date is selected
	useEffect(() => {
		checkForSmartSendDate();
	}, [smartSendScheduledDate]);

	// to check if contact is selected

	function checkForContacts() {
		if (!contactsSelected?.length) {
			setIsSelectedContacts(false);
		} else {
			setIsSelectedContacts(true);
		}
	}

	// to check when/if selected contact list changes
	useEffect(() => {
		checkForContacts();
	}, [contactsSelected?.length]);

	// to check if sku is selected
	async function checkForSkus() {
		if (
			!Object.keys(skusSelected)?.length &&
			!Object.keys(skusCustom)?.length
		) {
			setIsSelectedSkus(false);
		} else {
			setIsSelectedSkus(true);
		}
	}

	// to check if customSku is selected
	async function checkForCustomSkus() {
		if (!Object.keys(skusCustom)?.length) {
			setIsSelectedCustomSkus(false);
		} else {
			setIsSelectedCustomSkus(true);
		}
	}

	let skusSelected = skuSelected?.reduce((acc, { id, price, title, count }) => {
		let productName = title?.trim();

		acc[id] = { price, count, productName };
		return acc;
	}, {});

	let skusCustom = customSkus?.reduce((acc, { id, value }) => {
		acc[id] = { value };
		return acc;
	}, {});
	// to check when/if general skus value changes

	useEffect(() => {
		checkForSkus();
	}, [Object.keys(skusSelected)?.length]);

	// to check when/if custom skus value changes

	useEffect(() => {
		checkForCustomSkus();
	}, [Object.keys(skusCustom)?.length]);

	// to open dialog custom gesture
	const handleClickOpen = () => {
		setOpen(true);
		localStorage.setItem("dialogOpen", true);
	};

	// to close custom gesture dialog pop up
	const handleClose = () => {
		setOpen(false);
		localStorage.setItem("dialogOpen", false);
	};

	// to close confirm Delete All Custom Gestures dialog pop up
	const handleCloseConfirm = () => {
		setOpenConfirm(false);
	};

	// to set customGesture cartObject
	useEffect(() => {
		setCustomGesture({
			products: skusCustom,
			notes: notesValue,
		});
	}, [Object.keys(skusCustom)?.length, notesValue]);

	const handleQuantityChange = (e, id) => {
		setQuantityInput(e.value);

		setUpdateCount(Math.random());

		setSkuId(id?.id);
	};

	useEffect(() => {
		if (skuSelected?.filter((item) => item.id === skuId)) {
			const newCount = skuSelected
				?.filter((item) => item.id === skuId)
				?.map((obj) => ({
					...obj,
					count: Number(quantity),
				}));

			setSkuSelected([...skuSelected, newCount]);

			const updateCount = () => {
				return skuSelected?.map((item) => {
					var temp = Object.assign({}, item);

					if (temp.id === skuId) {
						temp.count = Number(quantity);
					}
					return temp;
				});
			};

			setSkuSelected(updateCount);
		} else {
			console.log("err");
		}
	}, [updateCount]);

	async function handleSubmit(e) {
		const emptyT = {};
		// localStorage.setItem('skuSelected', emptyT);
		// localStorage.setItem('customSkus', emptyT);
		// localStorage.setItem('notesValue', emptyT);
		e.preventDefault();

		if (
			isSelectedContacts &&
			isDeliveryDate &&
			isSmartSendDeliveryDate &&
			isSelectedSkus &&
			!isSelectedCustomSkus
		) {
			let cartObject = {
				skusSelected,
				contactsSelected,
				scheduledDateSelected,
				smartSendScheduledDate,
				sendersForNotification
				
			};

			setProcessing(true);

			postOrder({ cartObject })
				.then((res) => {
					handleLogOut(history);
					if (res?.code === "FAILURE") {
						setError(true);
						setVisible(true);
						setProcessing(false);
						setErrMessage(res?.message);
					}
					// else if (res.code === "CHARGE_ERR") {
					// 	setError(true);
					// 	setVisible(true);
					// 	setProcessing(false);
					// 	setErrMessage(res.data.message);
					// }
					else if (res?.code === "SUCCESS") {
						importGetProfileInfo();
						setTimeout(() => {
							setSkuSelected([]);
							setContactsSelected([]);
							localStorage.removeItem("selectedClientList");
							setScheduledDateSelected("");
							setSmartSendScheduledDate("");
							history.push("/history");
						}, 3000);
					}
				})
				.catch((error) => console.log(error, "error"));
		} else if (
			isSelectedContacts &&
			isSelectedCustomSkus &&
			isDeliveryDate &&
			isSmartSendDeliveryDate
		) {
			let cartObject = {
				contactsSelected,
				scheduledDateSelected,
				customGesture,
				smartSendScheduledDate,
				sendersForNotification
			};

			setProcessing(true);

			postOrder({ cartObject })
				.then((res) => {
					if (res.code === "SUCCESS") {
						setTimeout(() => {
							setCustomSkus([]);
							localStorage.removeItem("customSkus");
							localStorage.removeItem("notesValue");
							setNotesValue("");
							setContactsSelected([]);
							localStorage.removeItem("selectedClientList");
							setScheduledDateSelected("");
							setSmartSendScheduledDate("");
							history.push("/history");
						}, 3000);
					} else if (!res.ok) {
						setError(true);
						setVisible(true);
						setProcessing(false);
						setErrMessage(res.data.message);
					}
				})
				.catch((error) => console.log(error, "error"));
		} else {
			setVisible(true);
		}
	}

	// to calculate total

	var result = skuSelected?.reduce(function(tot, arr) {
		// return the sum with previous value
		return tot + arr.price * arr.count;

		// set initial value as 0
	}, 0);

	let clients = contactsSelected?.length;

	let multiply = result * clients;

	var subtotal = isNaN(multiply) ? 0 : multiply;

	const someFunc = () => {
		if (userInfo?.credit > subtotal) {
			let tot = 0;
			setDiff(tot);
			setCredit(subtotal);
		} else if (userInfo?.credit === subtotal) {
			let tot = 0;
			setDiff(tot);
			setCredit(subtotal);
		} else if (userInfo?.credit < subtotal) {
			let tot = subtotal - userInfo?.credit;

			setDiff(tot);
			setCredit(userInfo?.credit);
		}
	};

	useEffect(() => {
		if (!promiseInProgress) {
			someFunc();
		}
	}, [promiseInProgress]);
	useEffect(() => {
		if (isLoaded) {
			someFunc();
		}
	}, [isLoaded, subtotal]);

	return (
		<div className={styles.mainContainer}>
			{error && visible ? (
				<Box m={2}>
					<Collapse in={visible}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setVisible(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							{errMessage}
						</Alert>
					</Collapse>
				</Box>
			) : null}

			<h3 className={styles.mainTitle}>Your Order</h3>

			<div className={styles.detailsContainer}>
				<h4 className={styles.title}>Group Gesture </h4>

				{contactsSelected?.length > 0 && contactsSelected ? (
					<h4 className={styles.title}>
						({contactsSelected?.length}{" "}
						{contactsSelected?.length === 1 ? "Recipient" : "Recipients"})
					</h4>
				) : null}

				{!contactsSelected || contactsSelected?.length === 0 ? (
					<h4 className={styles.title}> (0 Recipients) </h4>
				) : null}
			</div>
			{skuSelected?.map((d) => {
				return (
					<div key={d.id} className={styles.skuContainer}>
						<div className={styles.skusContainer}>
							<img src={d.img} className={styles.img} />
							<div className={styles.skuInfoContainer}>
								<div className={styles.skuInfoCenterContainer}>
									<h4 className={styles.skuTitle}> Gesture- {d.title}</h4>
								</div>
								<h4 className={styles.skuTitle}> ${d.price}</h4>

								<div className={styles.quantity}>
									<label className={styles.quantityLabel}>Qty:</label>
									<Select
										options={
											d.quantity_limit
												? Array.from(
														{ length: d.quantity_limit },
														(v, k) => k + 1
												  ).map((d) => {
														return { value: d, label: d };
												  })
												: { value: 1, label: 1 }
										}
										value={d.count}
										maxMenuHeight={120}
										onChange={(e) => handleQuantityChange(e, d, d.id)}
										placeholder={d.count}
										menuPlacement="top"
										styles={{
											container: (provided) => ({
												...provided,
												width: "100px",
												minWidth: "fit-content",
											}),
											valueContainer: (provided) => ({
												...provided,
												overflow: "visible",
												height: "35px",
											}),
										}}
									/>
								</div>
							</div>
						</div>
						<button
							className={styles.button}
							onClick={(e) => {
								e.preventDefault();
								var array = [...skuSelected];

								let newArray = array?.filter((item) => item.id !== d.id);
								setSkuSelected(newArray);
							}}
						>
							<img src={deleteIcon} alt="delete" className={styles.icon} />
						</button>
					</div>
				);
			})}

			{customSkus?.length ? (
				<>
					<div className={styles.customSkusContainer}>
						<div className={styles.customGesturesContainer}>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<h4 className={styles.title}>Custom Gestures:</h4>

								<button onClick={handleClickOpen} className={styles.button}>
									<img src={editIcon} alt="edit" className={styles.icon} />
								</button>
							</div>

							{customSkus?.map((d) => {
								return (
									<div key={d.id} className={styles.customSkus}>
										<div style={{ display: "flex", alignItems: "center" }}>
											<img src={giftIcon} alt="gift" className={styles.icon} />
											<p id={d.id}>{d.value}</p>
										</div>
									</div>
								);
							})}
						</div>

						{notesValue ? (
							<>
								<h4>Notes:</h4>

								<p className={styles.notesContainer}>{notesValue}</p>
							</>
						) : null}
					</div>
				</>
			) : null}

			<Dialog
				open={openConfirm}
				onClose={handleCloseConfirm}
				aria-labelledby="responsive-dialog-title"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogTitle id="alert-dialog-title">
					Delete Custom Gestures?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Please confirm you want to delete all the custom Gestures and notes.
						<br />
						The action cannot be undone.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "space-evenly",
							paddingBottom: "20px",
						}}
					>
						<button
							className={sharedStyles.primaryButton}
							onClick={handleCloseConfirm}
						>
							Cancel
						</button>
						<button
							className={sharedStyles.primaryButton}
							// onClick={handleClose}
							color="primary"
							autoFocus
							onClick={() => {
								setCustomSkus([]);
								setNotesValue("");
								setOpen(false);
								setOpenConfirm(false);
								localStorage.removeItem("customSkus");
								localStorage.removeItem("notesValue");
								localStorage.setItem("dialogOpen", false);
								localStorage.removeItem("notesValue");
							}}
						>
							Confirm{" "}
						</button>
					</div>
				</DialogActions>
			</Dialog>

			<div className={styles.btnSelectorContainer}>
				<Dialog
					open={open}
					aria-labelledby="responsive-dialog-title"
					maxWidth="md"
					setMaxWidth="md"
					fullWidth={true}
				>
					<StyledDialogContent>
						<StyledDialogTitle>
							{" "}
							<div className={styles.headerContainer}>
								<button
									onClick={handleClose}
									className={styles.headerBtn}
									disabled={!customSkus?.length}
								>
									Cancel
								</button>
								{customSkus?.length ? (
									<button
										onClick={() => setOpenConfirm(true)}
										className={styles.headerBtn}
										disabled={!customSkus?.length}
									>
										Delete Custom Gestures
									</button>
								) : null}
							</div>
						</StyledDialogTitle>
						<DialogContentText>
							<CustomSku
								customSkus={customSkus}
								setCustomSkus={setCustomSkus}
								notesValue={notesValue}
								setNotesValue={setNotesValue}
								setOpen={setOpen}
							/>
						</DialogContentText>
					</StyledDialogContent>
				</Dialog>
			</div>

			{promiseInProgress ? (
				<div
					style={{
						height: "10vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "relative",
					}}
				>
					<BtnLoader loading={promiseInProgress} />
				</div>
			) : (
				<div className={styles.subtotal}>
					{isSelectedCustomSkus ? (
						<h4 className={styles.customMsg}>
							Concierge Team Will Call You For Pricing
						</h4>
					) : (
						<>
							{isSelectedContacts && isSelectedSkus && !isSelectedCustomSkus ? (
								<>
									<div style={{ display: "flex", flexDirection: "column" }}>
										{userInfo?.credit > 0 && subtotal > 0 ? (
											<h4 className={styles.total}>Subtotal</h4>
										) : null}
										{userInfo?.credit > 0 && subtotal > 0 ? (
											<h4 className={styles.total}>Credit</h4>
										) : null}

										<h4 className={styles.total}>Total</h4>
									</div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<strong className={styles.total}>&nbsp; ${subtotal}</strong>

										{userInfo?.credit > 0 && subtotal > 0 ? (
											<strong className={styles.total}>-${credit}</strong>
										) : null}

										{userInfo?.credit > 0 && subtotal > 0 ? (
											<strong className={styles.total}> &nbsp; ${diff}</strong>
										) : null}
									</div>
								</>
							) : (
								<>
									<h4 className={styles.total}>Total</h4>
									<strong className={styles.total}>
										$
										{!isSelectedContacts ||
										(!isSelectedSkus && !isSelectedCustomSkus)
											? calculatedPrice
											: subtotal}
									</strong>
								</>
							)}
						</>
					)}
				</div>
			)}
			{!isDeliveryDate && visible ? (
				<Box m={2}>
					<Collapse in={visible}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setVisible(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							Please select a delivery date
						</Alert>
					</Collapse>
				</Box>
			) : null}
			{!isSmartSendDeliveryDate && visible ? (
				<Box m={2}>
					<Collapse in={visible}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setVisible(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							Please select the date when recipient should be notified
						</Alert>
					</Collapse>
				</Box>
			) : null}

			{!isSelectedContacts && visible ? (
				<Box m={2}>
					<Collapse in={visible}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									m="10"
									onClick={() => {
										setVisible(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							Please select a recipient
						</Alert>
					</Collapse>
				</Box>
			) : null}
			{!isSelectedSkus && visible && !isSelectedCustomSkus ? (
				<Box m={2}>
					<Collapse in={visible}>
						<Alert
							severity="error"
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									m="10"
									onClick={() => {
										setVisible(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
						>
							Please select a Gesture
						</Alert>
					</Collapse>
				</Box>
			) : null}

			{processing ? (
				<div className={styles.btnContainer}>
					<button className={sharedStyles.primaryButton}>
						<BtnLoader loading={processing} />
						Processing...{" "}
					</button>
				</div>
			) : (
				<div className={styles.btnContainer}>
					{!checkOut ? (
						<Link className={styles.linkBtn} to="/Gift-Checkout">
							<button
								onClick={() => dispatch(load(stateObject))}
								className={sharedStyles.primaryButton}
							>
								Next
							</button>
						</Link>
					) : (
						<button
							onClick={handleSubmit}
							className={sharedStyles.primaryButton}
						>
							Send Gesture
						</button>
					)}
				</div>
			)}
		</div>
	);
}

export default Receipt;
