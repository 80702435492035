import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import HistoryBody from '../History/HistoryBody';
import { getHistory, checkForToken } from '../../APIManager';
import { PageLoader } from "../../Loader";
import sharedStyles from '../../Components/SharedStyles.module.css';
import './HistoryMain.css'

import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import { Box, Button, Container, Typography } from '@material-ui/core';
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import historyIcon from '../../Assets/icons/mdi_history_active.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";


const HistoryMain = () => {
    const [orders, setOrders] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    

    const getOrderHistory = () => {
        trackPromise(getHistory()
            .then((obj => {
                setOrders(obj.data)
            }))
        )
    
    }

    const history = useHistory();

    useEffect(() => {
        let unmounted = false;

        if (!unmounted && !checkForToken()) {
            history.push("/login");

            setTimeout(() => {
                toast.error(
                    "You've been logged out. This may have been caused by using more than one device or browser",
                    {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }, 1000);
            return;
        } else {
            getOrderHistory()
        }

        return () => {
            unmounted = true;
        };
    }, []);


    useEffect(() => {
		let unmounted = false;

		if (!unmounted && checkForToken() === "") {
			history.push("/login");
		}

		return () => {
			unmounted = true;
		};
	}, [checkForToken()]);
    
   

    // useEffect(() => {
    //     console.log('Hisory Page Loaded')
    //     getOrderHistory()
       
    // }, [])

    const { promiseInProgress } = usePromiseTracker();

    return (
        <Box className={sharedStyles.mainContainer} >
                {promiseInProgress ? 
                    <div
                        style={{
                            height: "40vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                        }}
                    >
                        <PageLoader  />
                    </div> : 
                    <Box >
                        <Box display='flex' flexDirection='row' alignItems='center'  >
                        <img src={historyIcon} alt='History Icon' className={sharedStyles.logo} />
                            <Box ml={0}>
                            <h2 className={sharedStyles.mainTitle} >History</h2>
                            </Box>
                        </Box>

                    <HistoryBody orders={orders} pageNumber={pageNumber} setPageNumber={setPageNumber} />
                        
                    </Box>
                }
        </Box>
    )
}

export default HistoryMain;
