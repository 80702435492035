import {
	faShoppingBag,
	faUsers,
	faUser,
	faTable,
	faHistory,
} from "@fortawesome/free-solid-svg-icons";

import giftLogo from "../../Assets/icons/gift.png";
import historyLogo from "../../Assets/icons/mdi_history_active.png";
import peopleLogo from "../../Assets/icons/mdi_account-multiple-plus-outline.png";
import profileLogo from "../../Assets/icons/mdi_account-circle-outline_active.png";

import plansLogo from "../../Assets/icons/mdi_clipboard-text-multiple-outline_active.png";

const SidebarLinks = [
	{
		name: "Gift Selection",
		route: "/",
		alt: "shopping bag",
		iconIsActive: "#fff",
		img:giftLogo,
	},
	{
		name: "History",
		route: "/history",
		alt: "icon",
		img:historyLogo,
	},
	{
		name: "People",
		route: "/contacts",
		alt: "people",
		img: peopleLogo
	},
	{
		name: "Profile",
		route: "/profile",
		alt: "user",
		iconIsActive: "#fff",
		img:profileLogo,

	},
	// {
	// 	name: "Plans",
	// 	route: "/plans",
	// 	alt: "clipboard",
	// 	img:plansLogo,
	// },

];

export default SidebarLinks;
