import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, Box, Card, CardActions, CardContent, Divider, Fade, Modal, Typography } from '@material-ui/core';
import sharedStyles from '../../Components/SharedStyles.module.css';
import styles from './HistoryBody.module.css'

import './HistoryMain.css'

import FadeIn from 'react-fade-in';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import ReactPaginate from 'react-paginate';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: '5vh',
        height: '90vh'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '',
        borderRadius: '0',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }, 
    root: {
        minWidth: 275,
        border: '',
        borderRadius: '0',
        boxShadow: theme.shadows[1],
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));

const HistoryBody = (props) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [product_description, setProduct_description] = React.useState('');
    const [first, setFirst] = React.useState('');
    const [last, setLast] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [recipientEmail, setRecipientEmail] = React.useState('');
    const [recipientPhone, setRecipientPhone] = React.useState('');
    const [orderId, setOrderId] = React.useState('');
    const [delivery_address, setDelivery_address] = React.useState('');
    const [delivery_address2, setDelivery_address2] = React.useState('');
    const [scheduledDateSelected, setScheduledDateSelected] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [skusSelected, setSkusSelected] = React.useState();
    const [customGesture, setCustomGesture] = React.useState();
    const [creditsUsed, setCreditsUsed] = React.useState();


    const handleOpen = ( order ) => {
        setOpen(true);
        setProduct_description(order.product_description);
        setFirst(order.first);
        setLast(order.last);
        setCompany(order.company);
        setTitle(order.title)
        setRecipientEmail(order.email);
        setRecipientPhone(order.phone)
        setOrderId(order.orderId);
        setDelivery_address(order.delivery_address);
        setDelivery_address2(order.delivery_address2);
        setScheduledDateSelected(getCurrentUTCDateTime(order.scheduledDateSelected));
        setStatus(order.status);
        setSkusSelected('');
        setCustomGesture('');

        const skusOrdered = [];
        if (order.skusSelected) {
            Object.keys(order.skusSelected)?.forEach(key => skusOrdered.push({
                order: key,
                details: order.skusSelected[key]
            }))
            setSkusSelected(skusOrdered)
        } else if (order.customGesture) {
            setCustomGesture(order.customGesture.products)
        }

        let sum = 0;
        // eslint-disable-next-line array-callback-return
        skusOrdered.map((order, index) => {
            sum += (order.details.price * order.details.count)
        })
        setCreditsUsed(sum)
    };

    

    const handleClose = () => {
        setOpen(false);
    };

    function getCurrentUTCDateTime(time) {
        return moment.utc(time).local().format('MMMM Do YYYY');
    }

    const ordersPerPage = 10;
    const pagesVisited = props.pageNumber * ordersPerPage
    const pageCount = Math.ceil(props.orders.length / ordersPerPage);

    const changePage = ({ selected }) => {
        props.setPageNumber(selected);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const orders = props.orders
        .slice(pagesVisited, pagesVisited + ordersPerPage)
        .map((order, index) => {

            const time = getCurrentUTCDateTime(order.created_at)
        

        return (
            <Box key={index} my={2} p={2} className={styles.left} style={{width: '70vw'}} >
                <Box display='flex' justifyContent='space-between'  >
                    <Box display='flex' flexDirection='column'  >
                        <Box display='flex'>
                           <Typography variant='h5' className='title'>
                               Gesture to {order.first} {order.last}
                            </Typography> 
                        </Box>
                        <Box mt={1} className='title'>
                            {time}
                        </Box>
                        <Box mt={1} >
                            {order.status ? order.status : <Typography style={{ color: '#a8a8a8' }} className='title'>Order has been processed</Typography>}
                        </Box>
                    </Box>

                    <Box >
                        <button className={sharedStyles.primaryButton} onClick={() => handleOpen(order)}>
                            View Order
                        </button>
                    </Box>
                </Box>
            </Box>
        )
    })

    const noOrders = () => {
        return (
           
            <Box  my={2} p={2} className={styles.left} style={{ width: '70vw' }} >
                <Box display='flex' justifyContent='space-between'  >
                    <Box mt={1}>
                        <Typography style={{ color: '#a8a8a8' }} className='title'>You have no history. If you just processed an order, refresh in a few minutes and it will appear here.</Typography>
                    </Box>
                    <Box >
                        <Link to='/' className={sharedStyles.primaryButton} style={{textDecoration: 'none'}} >
                            Place an Order
                        </Link>
                    </Box>
                </Box>
            </Box>
        )
    }


    return (
        <div>
            {props.orders.length === 0 ? noOrders() : orders} 
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{ overflow: 'auto' }}
            >
                <Fade in={open}>
                    <Box className={classes.paper} >
                        <Box display='flex'>
                            <Button style={{ marginLeft: 'auto' }} onClick={() => handleClose()}><CloseIcon/></Button>
                        </Box>
                    
                        <Card className={classes.root} style={{marginBottom: '10px', width: '50vw'}}>
                            <CardContent>
                                <Box display='flex' alignItems='center'>
                                    <Typography variant="h5" className='title' >Recipient</Typography>
                                </Box>
                                <FadeIn>
                                    <Typography className='title' style={{fontSize: '13px'}} color="textSecondary" >
                                        Name
                                    </Typography>
                                    <Typography className='title'  gutterBottom>
                                        {first ? first : 'N/A'} {last ? last : 'N/A'}
                                    </Typography>
                                    <Divider />
                                    <Typography className='title' style={{ fontSize: '13px' }} color="textSecondary" >
                                        Title
                                    </Typography>
                                    <Typography className='title'  gutterBottom>
                                        {title ? title : 'N/A'} 
                                    </Typography>
                                    <Divider />
                                    <Typography className='title' style={{ fontSize: '13px' }} color="textSecondary" >
                                        Company
                                    </Typography>
                                    <Typography className='title'  gutterBottom>
                                        {company ? company : 'N/A'} 
                                    </Typography>
                                    <Divider />
                                    <Typography className='title' style={{ fontSize: '13px' }} color="textSecondary" >
                                        Phone
                                    </Typography>
                                    <Typography className='title'  gutterBottom>
                                        {recipientPhone ? recipientPhone : 'N/A'}
                                    </Typography>
                                    <Divider />
                                    <Typography className='title' style={{ fontSize: '13px' }} color="textSecondary" >
                                        Email
                                    </Typography>
                                    <Typography className='title'  gutterBottom >
                                        {recipientEmail ? recipientEmail : 'N/A'}
                                    </Typography>
                                    <Divider />
                                    <Typography className='title' style={{ fontSize: '13px' }} color="textSecondary" >
                                        Delivery
                                    </Typography>
                                    <Typography className='title'  >
                                        {scheduledDateSelected ? scheduledDateSelected : 'N/A'}
                                    </Typography>
                                </FadeIn>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} style={{ marginBottom: '10px' }} >
                            <CardContent>
                                <Box display='flex' alignItems='center' >
                                    <Typography variant="h5" className='title' >Gestures Sent</Typography>
                                </Box>
                                <Box >
                                    <FadeIn>
                                        {skusSelected ? skusSelected.map((order, index) => (
                                                <Typography className='title' gutterBottom>
                                                {order.details.count} {order.details.productName}(s)
                                                </Typography>
                                        )) : customGesture ? customGesture.map((order, index) => (
                                            <Typography className='title' gutterBottom>
                                                {order}
                                            </Typography>
                                        )) :
                                            <Typography className='title'> N/A </Typography>}
                                    </FadeIn>
                                </Box>
                            </CardContent>
                        </Card>

                        <Card className={classes.root} style={{ marginBottom: '10px' }}>
                            <CardContent>
                                <Box display='flex' alignItems='center' >
                                    <Typography variant="h5" className='title' >Charges</Typography>
                                </Box>
                                <Box>
                                    <FadeIn>
                                        <Typography className='title'>
                                           {creditsUsed} 
                                        </Typography>

                                    </FadeIn>
                                </Box>
                            </CardContent>
                        </Card>
                        <Card className={classes.root}>
                            <CardContent>
                                <Box display='flex' alignItems='center' >
                                    <Typography variant="h5" className='title' >Status</Typography>
                                </Box>
                                <Box>
                                    <FadeIn>
                                        <Typography className='title'>
                                            {status ? status : 'Your order has been processed.'}
                                        </Typography>

                                    </FadeIn>
                                </Box>
                            </CardContent>
                        </Card>

                    </Box>
                    
                </Fade>
            </Modal>
            {props.orders.length === 0 ? 
                '' :
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                /> 
            }
            
        </div>
    )
}

export default HistoryBody;
