import React, { useState, useEffect } from "react";
import styles from "./SelectClients.module.css";
import sharedStyles from "../SharedStyles.module.css";

import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ClientList from "./ClientList";

import deleteIcon from "../../Assets/icons/x.png";
import editIcon from "../../Assets/icons/edit-icon.png";
import DialogTitle from "@material-ui/core/DialogTitle";

import addItem from "../../Assets/icons/add-item.png";

import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import smartsendImg from "../../Assets/icons/smartsend.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import {
	TableRow,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	DialogActions,
} from "@material-ui/core";
import PersonalNotes from "./PersonalNotes";

import Tooltip from "@material-ui/core/Tooltip";

const StyledTableHead = withStyles({
	root: {
		background: "white",
		height: 36,
		borderBottom: "2px solid #1F1E1E",
		fontWeight: "700",
	},
})(TableHead);

const StyledTableCell = withStyles({
	root: {
		fontWeight: "700",
		fontFamily: "Muli",
		fontSize: "16px",
		padding: "16px 16px 0 16px",
	},
})(TableCell);

const StyledTableCellBody = withStyles({
	root: {
		fontWeight: "550",
		fontFamily: "Muli",
		fontSize: "14px",
		maxWidth: "150px",
		overflowWrap: "break-word",
		whiteSpace: "break-normal",
	},
})(TableCell);

const StyledDialogContent = withStyles({
	root: {
		padding: "0",
		"&:first-child": {
			padding: "0",
		},
	},
})(DialogContent);

const useStyles = makeStyles((theme) => ({
	paper: {
		overflowY: "unset",
	},
	customizedButton: {
		position: "absolute",
		right: "-3%",
		top: "-6%",
		backgroundColor: "#2B08A0",
		color: "white",
		"&:hover": {
			background: "white",
			color: "#2B08A0",
		},
	},
}));

const StyledTooltip = withStyles({
	tooltip: {
		color: "#1F1E1E",
		backgroundColor: "white",
		boxShadow: "0 2px 10px 0 rgb(0 0 0 / 20%)",
		height: "fit-content",
		fontSize: 16,
		display: "flex",
		alignItems: "center",
		padding: "15px",
	},
})(Tooltip);
function SelectClients({ data, getData }) {
	// to open & close dialog popup to select recipients

	const [openRecipientList, setOpenRecipientList] = useState(false);

	// to display 15 contacts only at first

	const [visibleList, setVisibleList] = useState(15);

	// to open & close dialog popup for personal note

	const [openNote, setOpenNote] = useState(false);

	// to get each recipients id, pass it to PersonalNotes component  in order to have personal note for each contact
	const [contactId, setContactId] = useState();

	// to display delete notes btn only if there are notes

	const [displayDeleteBtn, setDisplayDeleteBtn] = useState(false);

	// to open dialog popup to select recipients

	const handleClickOpen = () => {
		setOpenRecipientList(true);
	};

	// to close dialog popup to select recipients

	const handleClose = () => {
		setOpenRecipientList(false);
	};

	//  to open PersonalNotes components & to display delete btn if previuosly notes were typed in
	function toggleOpen(e, id, personalNote) {
		setOpenNote(true);

		setContactId(id);

		if (personalNote === "" || !personalNote) {
			setDisplayDeleteBtn(false);
		} else {
			setDisplayDeleteBtn(true);
		}
	}

	// load 25 more  recipients on btn click
	const handleClick = () => {
		setVisibleList((prevVisibleBlogs) => prevVisibleBlogs + 25);
	};

	//  to delete notes
	const deleteNotes = () => {
		const updateNotes = () => {
			return data?.map((item) => {
				var temp = Object.assign({}, item);

				if (temp.id === contactId) {
					temp.personalNote = "";
				}
				return temp;
			});
		};

		localStorage.setItem("selectedClientList", JSON.stringify(updateNotes()));

		getData(updateNotes);
	};

	const handleDeleteNote = () => {
		deleteNotes();
		setOpenNote(false);
	};
	// to get selected recipients list from local storage ( from popup dialog)
	const newData = JSON.parse(localStorage.getItem("selectedClientList"));

	useEffect(() => {
		let isSubscribed = true;

		if (isSubscribed) {
			getData(newData);
		}

		return () => (isSubscribed = false);
	}, [newData?.length]);

	const classes = useStyles();

	//  to add/remove smartsend option for each contact
	// function toggleSmartSend(e, id) {
	// 	let checked = e.target.checked;
	// 	if (checked) {
	// 		const updateSmartSend = () => {
	// 			return data?.map((item) => {
	// 				var temp = Object.assign({}, item);
	// 				if (temp.id === id) {
	// 					temp.smartSend = true;
	// 				}
	// 				return temp;
	// 			});
	// 		};
	// 		localStorage.setItem(
	// 			"selectedClientList",
	// 			JSON.stringify(updateSmartSend())
	// 		);

	// 		getData(updateSmartSend);
	// 	} else {
	// 		// to remove from SmartSend

	// 		const updateSmartSend = () => {
	// 			return data?.map((item) => {
	// 				var temp = Object.assign({}, item);
	// 				if (temp.id === id) {
	// 					temp.smartSend = false;
	// 				}
	// 				return temp;
	// 			});
	// 		};

	// 		localStorage.setItem(
	// 			"selectedClientList",
	// 			JSON.stringify(updateSmartSend())
	// 		);

	// 		getData(updateSmartSend);
	// 	}
	// }

	return (
		<div className={styles.mainContainer}>
			<div className={styles.header}>
				<h3 className={styles.mainTitle}>Who are you sending to?</h3>
				<button
					onClick={handleClickOpen}
					className={styles.primaryButton}
				>
					Select Recipients
				</button>
			</div>
			<div className={styles.underTitle}>
				Choose from a previously saved recipient.
				<div className={styles.underNote}>
				Note: recipients must be located in the U.S.
				</div>
			</div>
			{!data?.length ? (
				<>
					<div className={styles.design}>
						<svg
							width="30"
							height="30"
							viewBox="0 0 35 35"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className={styles.svg1}
						>
							<circle cx="17.5" cy="17.5" r="17.5" fill="#E5E5E5" />
						</svg>

						<svg
							width="245"
							height="231"
							viewBox="0 0 245 231"
							fill="none"
							className={styles.svg2}
							// style={{ transform: "rotate(8.51deg)" }}
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M160.671 53.9801C180 63.8718 191.319 83.8808 200.389 102.071C209.531 120.405 221.083 142.542 212.094 157.111C203.023 171.815 173.911 161.855 158.148 170.541C143.972 178.352 145.55 205.591 127.066 203.964C108.583 202.338 99.5312 177.557 84.9563 164.1C73.9164 153.907 61.2969 146.274 52.114 134.764C40.005 119.587 19.0298 102.389 23.6812 87.2991C28.432 71.8863 56.0023 78.5171 71.4834 71.9249C83.6925 66.726 90.7543 55.6782 104.376 52.9375C122.234 49.3443 142.021 44.4365 160.671 53.9801Z"
								fill="#F3F6FF"
							/>
						</svg>

						<svg
							width="40"
							height="40"
							viewBox="0 0 40 40"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className={styles.svg3}

							// style={{ transform: "translate(-25px, 50px" }}
						>
							<circle
								cx="20"
								cy="20"
								r="17.5"
								stroke="#DDBFD6"
								strokeWidth="5"
							/>
						</svg>
					</div>

					<svg
						width="30"
						height="30"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={styles.svg4}
					>
						<circle cx="15" cy="15" r="15" fill="#DFE6FF" />
					</svg>
				</>
			) : null}

			<Dialog
				open={openRecipientList}
				aria-labelledby="responsive-dialog-title"
				maxWidth="lg"
				setMaxWidth="lg"
				fullWidth={true}
				// scroll="body"
				classes={{ paper: classes.paper }}
				onBackdropClick={handleClose}
			>
				<StyledDialogContent>
					<DialogContentText>
						<ClientList setOpenRecipientList={setOpenRecipientList} />
					</DialogContentText>
				</StyledDialogContent>
				<DialogActions>
					<IconButton
						autoFocus
						onClick={handleClose}
						color="primary"
						className={classes.customizedButton}
					>
						<CloseIcon />
					</IconButton>
				</DialogActions>
			</Dialog>
			{data?.length > 0 ? (
				<TableContainer>
					<Table aria-label="simple table">
						<StyledTableHead>
							<TableRow>
								<StyledTableCell align="center">Name</StyledTableCell>
								<StyledTableCell align="center">Email</StyledTableCell>
								<StyledTableCell align="center">Company</StyledTableCell>

								<StyledTableCell align="center">
									<StyledTooltip
										title={
											"Gesture SmartSend allows you to save time trying to get home addresses of your recipients. You may find that most of your clients are reluctant to give their home address directly to you. We act as a gifting liaison to help you save time and awkward conversation. One click takes care of scheduling, address, and delivery all through Gesture for Business SmartSend Tech."
										}
									>
										<img
											alt="smart send"
											src={smartsendImg}
											className={styles.smartsendImg}
										/>
									</StyledTooltip>
								</StyledTableCell>
								<StyledTableCell align="center">Personal Note</StyledTableCell>
								<StyledTableCell align="center"></StyledTableCell>
							</TableRow>
						</StyledTableHead>
						{/* <TableBody> */}
						{data?.slice(0, visibleList).map((d) => {
							// const matchSmartSend = data?.find((piv) => piv.id === d.id);
							return (
								<TableBody key={d.id}>
									<TableRow>
										<StyledTableCellBody align="center">
											{d?.first} {d?.last?.charAt(0)}.
										</StyledTableCellBody>

										<StyledTableCellBody align="center">
											{d.email ? d.email : "N/A"}
										</StyledTableCellBody>
										<StyledTableCellBody align="center">
											{d.company ? d.company : "N/A"}
										</StyledTableCellBody>

										<StyledTableCellBody align="center">
											{/* <input
												onChange={(e) => toggleSmartSend(e, d.id)}
												checked={matchSmartSend?.smartSend === true || ""}
												type="checkbox"
											/> */}
											{d.smartSend === true ? (
												<FontAwesomeIcon
													size={3}
													style={{ color: "#2B08A0" }}
													icon={faCheck}
													alt="check mark"
												/>
											) : null}
										</StyledTableCellBody>

										<StyledTableCellBody
											align="center"
											onClick={(e) => toggleOpen(e, d.id, d.personalNote)}
										>
											{d.personalNote ? (
												<img
													src={editIcon}
													className={styles.icon}
													alt="edit"
												/>
											) : (
												<img
													src={addItem}
													alt="add icon"
													className={styles.addIcon}
												/>
											)}
										</StyledTableCellBody>

										<StyledTableCellBody align="center" className={styles.row}>
											{" "}
											<button
												className={styles.button}
												onClick={() => {
													var array = [...data];

													let newArray = array?.filter(
														(item) => item.id !== d.id
													);
													getData(newArray);

													localStorage.setItem(
														"selectedClientList",
														JSON.stringify(newArray)
													);
												}}
											>
												<img
													src={deleteIcon}
													alt="delete"
													className={styles.addIcon}
												/>
											</button>
										</StyledTableCellBody>
									</TableRow>
								</TableBody>
							);
						})}
						{/* </TableBody> */}
					</Table>

					<Dialog
						open={openNote}
						// PaperComponent={PaperComponent}
						aria-labelledby="draggable-dialog-title"
						fullWidth={true}
						maxWidth={"xs"}
					>
						<DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
							<div
								style={{
									display: "flex",
									width: "100%",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								Personal Note{" "}
								{displayDeleteBtn ? (
									<button
										className={sharedStyles.primaryButton}
										onClick={handleDeleteNote}
									>
										Delete Note
									</button>
								) : null}
							</div>
						</DialogTitle>

						<DialogContent>
							<DialogContentText>
								<PersonalNotes
									setOpenNote={setOpenNote}
									data={data}
									getData={getData}
									id={contactId}
								/>
							</DialogContentText>
						</DialogContent>
					</Dialog>

					{visibleList < data?.length ? (
						<div className={styles.btnContainer}>
							<button
								className={sharedStyles.primaryButton}
								onClick={handleClick}
							>
								Load More
							</button>
						</div>
					) : null}
				</TableContainer>
			) : null}
		</div>
	);
}

export default SelectClients;
