export default {
  mainContainer: {
    maxWidth: "160px",
    fontFamily: "Muli",
    minHeight: "300px",
  },

  fullName: {
    width: "150px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontStyle: "normal",

    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    color: "##1F1E1E0",
    "&:hover": {
      color: "rgba(48, 32, 174, 0.5)",
    },
    marginBottom: "7.5px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },

  balanceRemaining: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#2D199D",
    marginBottom: "2.5px",
    paddingTop: "10px",
  },

  credit: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "##1F1E1E0",
    marginBottom: "8px",
    wordBreak: "break-all",
    whiteSpace: "normal",
  },

  nameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  refreshFlex: {
    width: "175px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "5px",
  },

  refreshButton: {
    fontSize: "17px",
    "&:hover": {
      color: "rgba(48, 32, 174, 0.5)",
    },
    cursor: "pointer",
    paddingLeft: "3px",
  },

  profile: {
    display: "flex",
    flexDirection: "row",
  },

  balance: {
    display: "flex",
    flexDirection: "column",
    margin: "7.5px",
    marginBottom: "22.5px",
    justifyContent: "center",
    alignItems: "center",
  },

  avatarContainer: {
    width: "100%",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  image: {
    maxWidth: "100px",
    maxHeight: "100px",
    flexShrink: "0",
  },

  plan: {
    fontSize: "14px",
    color: "#7E7E7E",
    lineHeight: "14px",
    marginBottom: "15px",
  },

  middleFlex: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5px",
  },

  upgradeButton: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "100px",
    background: "#E3E7F5",
    color: "#2B08A0",
    border: "1px solid #E3E7F5",
    outline: "none",
    justifyContent: "center",
    fontSize: "12px",
    padding: "3px 40px 3px 40px",
    marginTop: "3px",
    "&:hover": {
      color: "rgba(48, 32, 174, 0.5)",
    },
    fontSize: "15px",
    lineHeight: "20px",
    marginBottom: "14px",
  },

  loaderContainer: {
    minHeight: "300px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};
