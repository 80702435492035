import React, { useState, useEffect, Suspense } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory,
  Redirect 
} from "react-router-dom";

import { checkForToken, handleGETprofileBox } from "./APIManager";
import { PageLoader } from "./Loader";

import Plan from "./Components/Plans_Page/UpdatedPlans";
import Checkout from "./Components/Checkout/Checkout";
import Layout from "./Layout";
import SignUp from "./Components/Auth/SignUp";
import Login from "./Components/Auth/Login";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword";
import Contact from "./Components/ContactsPage/Components/Contact";
import ContactFullPage from "./Components/ContactsPage/Components/ContactFullPage";
import ImportSingle from "./Components/ContactsPage/Components/ImportSingle";

import GiftCheckOut from "./Components/Order/GiftCheckOut";

import Profile from "./Components/Profile/ProfileMain";
import HistoryMain from "./Components/History/HistoryMain";

import ReactJoyride from "react-joyride";
// import { LaptopWindows } from "@material-ui/icons";
const Order = React.lazy(() => import("./Components/Order/Order"));

function Routes() {
	const [profileData, setProfileData] = useState([]);
	const [display, setDisplay] = useState(false);
	const [joyride, setJoyride] = useState({
		run: false,
		steps: [
			{
				title: "Welcome to Gesture!",
				target: "body",
				placement: "center",
				content: (
					<div>
						<h4>Click Next to start the guided website tour.</h4>
					</div>
				),
				disableBeacon: true,
			},
			{
				title: "This is where you can check your balance",
				target: ".sidebar",
			},
			{
				title: "This is where you can select Gestures",
				target: ".Order",
			},
			{
				title: "This is where you can add contacts",
				target: ".People",
			},
			// {
			//   title: "This is where you can select a plan",
			//   target: ".Plans",
			// },
		],
	});

	const importGetProfileInfo = async () => {
		localStorage.setItem("Refresh", "true");
		const data = await handleGETprofileBox();
		if (data === undefined) {
			return 0;
		} else {
			setProfileData(data);
			return data;
		}
	};

	const handleLogOut = async (history) => {
		const data = await checkForToken();
		if (data === "" || !data || data === undefined || data === null) {
			history.push("/");
		}

		return data;
	};

	useEffect(() => {
		if (checkForToken()) {
			importGetProfileInfo();
		}
	}, 500);

	useEffect(() => {
		setTimeout(() => {
			if (!display) {
				setJoyride({ ...joyride, run: false });
			} else {
				setJoyride({ ...joyride, run: true });
			}
		}, 50);
	}, [display]);

	return (
		<div>
			<Suspense fallback={<PageLoader />}>
				<Router>
					<Route
						render={(props) => (
							<Switch>
								<Route exact path="/login">
									<Login setDisplay={setDisplay} display={display} />
								</Route>

								<Route exact path="/signup">
									<SignUp setDisplay={setDisplay} />
								</Route>

								<Route exact path="/forgot-password">
									<ForgotPassword setDisplay={setDisplay} />
								</Route>
								<Route exact path="/reset-password">
									<ResetPassword setDisplay={setDisplay} />
								</Route>
								{/* to display dashboard */}
								<Layout
									{...props}
									profileData={profileData}
									importGetProfileInfo={importGetProfileInfo}
								>
									<Route exact path="/">
										<Order
											importGetProfileInfo={importGetProfileInfo}
											handleLogOut={handleLogOut}
										/>
									</Route>

									<Route exact path="/Gift-Checkout">
										<GiftCheckOut
											importGetProfileInfo={importGetProfileInfo}
											handleLogOut={handleLogOut}
										/>
									</Route>

									<ReactJoyride
										steps={joyride.steps}
										run={joyride.run}
										continuous
										showProgress
										showSkipButton
										styles={{
											options: {
												// modal arrow and background color
												arrowColor: "#eee",
												backgroundColor: "#eee",

												// page overlay color
												//    overlayColor: "rgba(79, 26, 0, 0.4)",
												//button color
												primaryColor: "#2B08A0",
												//text color
												textColor: "#1F1E1E",

												//width of modal
												width: 500,
												//zindex of modal
												zIndex: 1000,
												height: "70px",
											},
											tooltipTitle: {
												fontSize: "16",
												color: "#1F1E1E",
												fontFamily: "Muli",
												WebkitFontSmoothing: "antialiased",
												MozOsxFontSmoothing: "antialiased",
											},
											tooltipContent: {
												fontSize: 14,
												color: "#1F1E1E",
												fontFamily: "Muli",
												WebkitFontSmoothing: "antialiased",
												MozOsxFontSmoothing: "antialiased",
											},
											buttonSkip: {
												fontSize: 14,
												color: "#1F1E1E",
												fontFamily: "Muli",
												fontWeight: "600",
												WebkitFontSmoothing: "antialiased",
												MozOsxFontSmoothing: "antialiased",
											},

											buttonBack: {
												fontSize: 14,
												fontFamily: "Muli",
												fontWeight: "600",
												WebkitFontSmoothing: "antialiased",
												MozOsxFontSmoothing: "antialiased",
											},

											buttonNext: {
												fontSize: 14,
												width: "25%",
												height: "auto",
												color: "#fff",
												backgroundColor: "#2B08A0",
												fontFamily: "Muli",
												fontSmooth: "antialiased",
												WebkitFontSmoothing: "antialiased",
												MozOsxFontSmoothing: "antialiased",
											},
										}}
									/>

									{/* <Route exact path="/plans">
                    <Plan importGetProfileInfo={importGetProfileInfo} />
                  </Route> */}
									{/* <Route exact path="/checkout/">
										<Checkout
											{...props}
											importGetProfileInfo={importGetProfileInfo}
											handleLogOut={handleLogOut}
										/>
									</Route> */}
									<Route exact path="/contacts">
										<Contact
											importGetProfileInfo={importGetProfileInfo}
											handleLogOut={handleLogOut}
										/>
									</Route>
									<Route exact path="/history">
										<HistoryMain importGetProfileInfo={importGetProfileInfo} />
									</Route>
									<Route exact path="/profile">
										<Profile
											importGetProfileInfo={importGetProfileInfo}
											handleLogOut={handleLogOut}
										/>
									</Route>
									<Route
										exact
										path={"/contacts/:id"}
										component={ContactFullPage}
									/>
									<Route exact path="/addperson">
										<ImportSingle importGetProfileInfo={importGetProfileInfo} />
									</Route>
								</Layout>
							</Switch>
						)}
					/>
					{/* <Redirect to="/" /> */}
				</Router>
			</Suspense>
		</div>
	);
}

export default Routes;
