export default {
  // buttonGroup: {
  //   marginTop: "2vw ",
  //   marginLeft: "1vh ",
  //   display: "flex",
  // },

  buttonGroup: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    font: "inherit",
  },

  bold: {
    color: "#1F1E1E",
    fontSize: "14px",
    fontWeight: "650",
    marginBottom: "2vw",
  },

  textField: {
    marginTop: "8.5px",
    width: "850px",
    marginBottom: "1vw",
  },

  wrapper: {
    width: "850px",
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    display: "flex",
    background: "#fff",
    flexDirection: "column",
  },

  title: {
    display: "flex",
    justifyContent: "flex-start",
    fontSize: "20px",
    marginBottom: "25px",
    width: "850px",
  },

  titlename: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },

  arrowBack: {
    "&:hover": {
      color: "rgba(48, 32, 174, 0.5)",
    },
    marginRight: "30px",
    textDecoration: "none",
    color: "#1F1E1E",
  },
};
