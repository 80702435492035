import React from 'react'
import { Container } from "react-bootstrap";


function ContainerLayout(props) {
    return <Container>{props.children}</Container>;

}

export default ContainerLayout
