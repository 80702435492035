export default {
  wrapper: {
    width: "900px",
    height: "fit-content",
    display: "flex",
    background: "#fff",
    // boxShadow: "0 0 9px 0 rgba(0, 0, 0, 0.15)",
    // borderRadius: "4px",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "10px",
  },

  title: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "20px",
    width: "900px",
    marginBottom: "20px",
  },

  titlename: {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },

  buttonGroup: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    font: "inherit",
  },

  box: {
    width: "900px",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "4px",
  },

  contactContainer: {
    padding: "20px 20px 10px ",
    width: "900px",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.35)",
    borderRadius: "5px",
  },

  nameText: {
    fontWeight: "bold",
    marginBottom: "10px",
  },

  phoneText: {
    marginBottom: "10px",
  },

  emailText: {
    marginBottom: "10px",
  },

  addressContainer: {
    marginTop: "15px",
    padding: "20px 20px 10px",
    width: "900px",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.35)",
    borderRadius: "5px",
  },

  clientText: {
    marginBottom: "10px",
  },

  delivery_addressText: {
    marginBottom: "10px",
  },
  arrowBack: {
    "&:hover": {
      color: "rgba(48, 32, 174, 0.5)",
    },
    marginRight: "30px",
    textDecoration: "none",
    color: "#1F1E1E",
  },

  textField1: {
    marginTop: "8.5px",
    marginBottom: "5px",
    width: "850px",
  },

  textField2: {
    marginTop: "8.5px",
    marginBottom: "5px",
    width: "850px",
    backgroundColor: "rgba(255,255,0,0.25)",
  },

  bold: {
    fontSize: "14px",
    fontWeight: "650",
  },
};
