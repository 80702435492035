import React from "react";
import { withStyles } from "@material-ui/styles";
import { Box, Grid, Modal, Typography, Fade } from "@material-ui/core";
import sharedStyles from "../../Components/SharedStyles.module.css"

const ModalConfirmation = (props) => {
  const { open, toggleModal, toggleForm, saveChanges } = props;

  const toggleSaveSubmission = async () => {
    await saveChanges();
    toggleForm(false);
    toggleModal(!open);
  };

  return (
    <>
      <Modal open={open}>
        <Fade in={open}>
          <div className="modal-back">
            <div className="modal-body">
              <Box display="flex" alignItems="center" p={2} m={3}>
                <Grid container justify="center" spacing={3}>
                  <Grid item>
                    <Typography variant="h6" color="textPrimary">
                      Would you like to save changes?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <button
                      className={sharedStyles.primaryButton}
                      onClick={() => {
                        toggleModal(!open);
                      }}
                      style={{
                        backgroundColor: "transparent",
                        color: "#2B08A0",
                      }}
                    >
                      Close
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      className={sharedStyles.primaryButton}
                      // style={{
                      //   backgroundColor: "#2B08A0",
                      //   color: "white",
                      // }}
                      onClick={() => toggleSaveSubmission()}
                    >
                      Save
                    </button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default withStyles(sharedStyles)(ModalConfirmation);
