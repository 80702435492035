import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { usePromiseTracker, trackPromise } from "react-promise-tracker";

import styles from "./GiftCheckOut.module.css";

import SelectDeliveryDate from "./SelectDeliveryDate";
import SelectSmartSendDate from "./SelectSmartSendDate";
import SelectClients from "./SelectClients";
import Receipt from "./Receipt.js";

import { toast } from "react-toastify";

import {
	checkForToken,
	getSkusData,
	handleGETprofileBox,
} from "../../APIManager";
import SelectSenders from "./SelectSenders";

function GiftCheckOut(props) {
	const history = useHistory();

	const [scheduledDateSelected, setScheduledDateSelected] = useState(null);

	const [smartSendScheduledDate, setSmartSendScheduledDate] = useState(null);

	const [clientsData, getClientsData] = useState([]);

	const [notesValue, setNotesValue] = useState("");

	const [customSkus, setCustomSkus] = useState([]);

	const [skuSelected, setSkuSelected] = useState([]);

	const stateObject = useSelector((state) => state);

	useEffect(() => {
		const sp = localStorage.getItem("skuSelected");
		//if (sp) setSkuSelected(sp);
		if (sp) setSkuSelected(JSON.parse(sp));
	}, []);

	useEffect(() => {
		localStorage.setItem("skuSelected", JSON.stringify(skuSelected));
	}, [skuSelected]);

	useEffect(() => {
		const sp = localStorage.getItem("customSkus");
		if (sp) setCustomSkus(JSON.parse(sp));
	}, []);

	// to get custom skus notes on initial load/page refresh from local storage

	// to get custom skus notes on initial load/page refresh from local storage

	useEffect(() => {
		const sp = localStorage.getItem("notesValue");
		if (sp) setNotesValue(JSON.parse(sp));
	}, []);
	const [userInfo, setUserInfo] = useState([]);

	const [data, setData] = useState([]);

	const { promiseInProgress } = usePromiseTracker();

	useEffect(() => {
		let unmounted = false;

		if (!unmounted && !checkForToken()) {
			history.push("/login");

			setTimeout(() => {
				toast.error(
					"You've been logged out. This may have been caused by using more than one device or browser",
					{
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}, 1000);
			return;
		} else {
			trackPromise(importGetSkusData());
			trackPromise(importGetProfileInfo());
		}

		return () => {
			unmounted = true;
		};
	}, []);

	useEffect(() => {
		let unmounted = false;

		if (!unmounted && checkForToken() === "") {
			history.push("/login");
		}

		return () => {
			unmounted = true;
		};
	}, [checkForToken()]);

	const importGetSkusData = async () => {
		const data = await getSkusData();

		var arr = data?.map((d) => d.title);

		var maxLength = 0;

		for (let name of arr) {
			if (name?.length > maxLength) maxLength = name?.length;
		}

		for (var i = 0; i < arr.length; i++) {
			var remainingSpaces = maxLength - arr[i]?.length;

			for (var j = 0; j < remainingSpaces; j++) {
				arr[i] += " ";
			}
		}
		const newData = data.map((obj, i) => ({ ...obj, title: arr[i] }));

		setData(newData);
	};

	const importGetProfileInfo = async () => {
		const data = await handleGETprofileBox();

		setUserInfo(data);
	};

	let counter = 0;
	let price = 0;
	for (let i = 0; i < skuSelected?.length; i++) {
		counter = counter + skuSelected[i]?.count;
		price = price + skuSelected[i]?.count * skuSelected[i]?.price;
	}

	return (
		<div>
			<div className={styles.Title}>
				<div className={styles.titleContainer}>
					{" "}
					<Link to={"/"}>
						<button className={styles.backBTN}> {"<<< Back"}</button>
					</Link>
					<div>
						<h3 className={styles.TitleText}>Who is receiving your Gesture?</h3>
						<a className={styles.UnderTitle}>
							We made it super easy by providing a one page summary
						</a>
					</div>
				</div>

				<div className={styles.middleBlock}>
					<div className={styles.selecters}>
						<SelectDeliveryDate
							scheduledDateSelected={scheduledDateSelected}
							setScheduledDateSelected={setScheduledDateSelected}
						/>
						<SelectSmartSendDate
							scheduledDateSelected={scheduledDateSelected}
							smartSendScheduledDate={smartSendScheduledDate}
							setSmartSendScheduledDate={setSmartSendScheduledDate}
						/>
						<SelectSenders userInfo={userInfo} />
						<SelectClients data={clientsData} getData={getClientsData} />
					</div>
					{/* <Receipt
						skuSelected={stateObject.skuSelected}
						setSkuSelected={stateObject.setSkuSelected}
						contactsSelected={clientsData}
						setContactsSelected={getClientsData}
						scheduledDateSelected={scheduledDateSelected}
						setScheduledDateSelected={setScheduledDateSelected}
						customSkus={stateObject.customSkus}
						setCustomSkus={stateObject.setCustomSkus}
						notesValue={notesValue}
						setNotesValue={setNotesValue}
						userInfo={userInfo}
						data={data}
						importGetProfileInfo={importGetProfileInfo}
						handleLogOut={props.handleLogOut}
						smartSendScheduledDate={smartSendScheduledDate}
						setSmartSendScheduledDate={setSmartSendScheduledDate}
						promiseInProgress={promiseInProgress}
						calculatedPrice={price}
						checkOut={true}
					/> */}
					<Receipt
						skuSelected={skuSelected}
						setSkuSelected={setSkuSelected}
						contactsSelected={clientsData}
						setContactsSelected={getClientsData}
						scheduledDateSelected={scheduledDateSelected}
						setScheduledDateSelected={setScheduledDateSelected}
						customSkus={customSkus}
						setCustomSkus={setCustomSkus}
						notesValue={notesValue}
						setNotesValue={setNotesValue}
						userInfo={userInfo}
						data={data}
						importGetProfileInfo={props.importGetProfileInfo}
						handleLogOut={props.handleLogOut}
						smartSendScheduledDate={smartSendScheduledDate}
						setSmartSendScheduledDate={setSmartSendScheduledDate}
						promiseInProgress={promiseInProgress}
						calculatedPrice={price}
						stateObject={stateObject}
						checkOut={true}
					/>
				</div>
			</div>
		</div>
	);
}

export default GiftCheckOut;
