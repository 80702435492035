import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import styles from "../Styles/ContactFullPageStyles";
import validator from "validator";
import libphonenumber from "google-libphonenumber";
import { BtnLoader } from "../../../Loader";
import { ToastContainer, toast } from "react-toastify";
import sharedStyles from "../../SharedStyles.module.css";
import { handleDELETE, handlePatch, checkForToken } from "../../../APIManager";

class ContactFullPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      saveAnimation: false,
      delete: false,
      edit: false,
      tempfirst: "",
      templast: "",
      tempfullname: "",
      tempphone: "",
      tempemail: "",
      tempcompany: "",
      temptitle: "",
      tempid: "",
      first: "",
      last: "",
      fullname: "",
      phone: "",
      email: "",
      id: "",
      company: "",
      title: "",
      error: "",
    };
    this.handleSaveAnimation = this.handleSaveAnimation.bind(this);
    this.deleteWindow = this.deleteWindow.bind(this);
    this.toggleCancel = this.toggleCancel.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSaveWrapper = this.handleSaveWrapper.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChangeFirst = this.handleChangeFirst.bind(this);
    this.handleChangeLast = this.handleChangeLast.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
  }

  componentDidMount() {
    if (!checkForToken()) {
      this.props.history.push("/login");
      return 0;
    } else {
      this.setState({
        first: this.props.location.state.first,
        last: this.props.location.state.last,
        fullname: this.props.location.state.fullname,
        phone: this.props.location.state.phone,
        email: this.props.location.state.email,
        id: this.props.location.state.id,
        company: this.props.location.state.company,
        title: this.props.location.state.title,
      });
    }
  }

  handleChangeLast(value) {
    this.setState({
      last: value.target.value,
    });
  }

  handleChangeFirst(value) {
    this.setState({
      first: value.target.value,
    });
  }

  handleChangeEmail(value) {
    this.setState({
      email: value.target.value,
    });
  }
  handleChangePhone(value) {
    this.setState({
      phone: value.target.value,
    });
  }

  handleChangeCompany(value) {
    this.setState({
      company: value.target.value,
    });
  }
  handleChangeTitle(value) {
    this.setState({
      title: value.target.value,
    });
  }

  handleSaveAnimation() {
    this.setState({
      saveAnimation: !this.saveAnimation,
    });
  }

  deleteWindow() {
    this.setState({
      delete: !this.state.delete,
    });
  }

  toggleEdit() {
    this.setState({
      edit: !this.state.edit,
      tempfirst: this.state.first,
      templast: this.state.last,
      tempfullname: this.state.fullname,
      tempphone: this.state.phone,
      tempemail: this.state.email,
      tempid: this.state.id,
      tempcompany: this.state.company,
      temptitle: this.state.title,
    });
  }

  // toggleSave() {
  //   this.setState({
  //     edit: !this.state.edit,
  //     saveAnimation: true,
  //   });

  // setTimeout(
  //   this.setState({ saveAnimation: !this.state.saveAnimation }),
  //   5000
  // );
  // }

  toggleCancel() {
    this.setState({
      edit: !this.state.edit,
      first: this.state.tempfirst,
      last: this.state.templast,
      fullname: this.state.tempfullname,
      phone: this.state.tempphone,
      email: this.state.tempemail,
      id: this.state.tempid,
    });
  }

  async handleDelete() {
    await handleDELETE([this.props.location.state.id]);
    localStorage.setItem("contactsReload", "true");
  }

  handleSaveWrapper(event) {
    event.preventDefault();
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
    var email = this.state.email;
    var first = this.state.first;
    var last = this.state.last;
    if (!first) {
      toast.error("Please enter a valid first name!", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return 0;
    }
    if (!last) {
      toast.error("Please enter a valid last name!", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return 0;
    }
    if (!validator.isEmail(email)) {
      toast.error("Please enter a valid email!", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return 0;
    }

    if (removeExtraSpace(this.state.phone) === "") {
      this.state.phone = "N/A";
    } else if (
      // this.state.phone <= 17 &&
      this.state.phone.match(/^(?=.*[0-9])[- +()0-9]+$/) != null

    ) {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const PNF = libphonenumber.PhoneNumberFormat;
      const number = phoneUtil.parseAndKeepRawInput(removeExtraSpace(this.state.phone), "US");
      var format = phoneUtil.format(number, PNF.E164);
      this.state.phone = removeExtraSpace(format);
    } else {
      toast.error("Please enter a valid phone number!", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return 0;
    }

    this.setState({ loader: true });
    setTimeout(this.handleSave, 2000);
  }

  async handleSave() {
    this.setState({
      loader: false,
      edit: !this.state.edit,
      saveAnimation: true,
    });

    var tempdata = [
      {
        first: this.state.first,
        last: this.state.last,
        fullname: this.state.fullname,
        phone: this.state.phone,
        email: this.state.email,
        id: this.state.id,
        company: this.state.company,
        title: this.state.title,
      },
    ];

    tempdata?.forEach((arrayItem) => {
      arrayItem["fullname"] = `${arrayItem["first"]} ${arrayItem["last"]}`;
      for (const property in arrayItem) {
        if (arrayItem[property] === "") {
          arrayItem[property] = "N/A";
        }
        this.setState({ arrayItem: arrayItem[property] });
      }
    });

    await handlePatch(tempdata);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={sharedStyles.mainContainer}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {!this.state.loader ? (
          !this.state.edit ? (
            <div className={classes.wrapper}>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <div className={classes.title}>
                <div className={classes.titlename}>
                  <Link to={"/contacts"}>
                    <ArrowBackIcon className={classes.arrowBack} />
                  </Link>

                  <FontAwesomeIcon icon={faUser} alt="people" />
                  <h2 className={sharedStyles.mainTitle}>Contact Detail</h2>
                </div>
                <div className={classes.buttonGroup}>
                  <button
                    className={sharedStyles.primaryButton}
                    style={{ margin: "5px" }}
                    onClick={this.toggleEdit}
                  >
                    Edit
                  </button>
                  <button
                    className={sharedStyles.primaryButton}
                    onClick={this.deleteWindow}
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div>
                <Dialog
                  open={this.state.delete}
                  onClose={this.deleteWindow}
                  aria-labelledby="responsive-dialog-title"
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <DialogTitle id="responsive-dialog-title">
                    Delete Contact?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      This action is irreversible!
                    </DialogContentText>
                    <div className={classes.buttonGroup}>
                      <button
                        className={sharedStyles.primaryButton}
                        style={{ margin: "5px" }}
                        onClick={this.toggleEdit}
                      >
                        Cancel
                      </button>
                      <Link to={"/contacts"}>
                        <button
                          className={sharedStyles.primaryButton}
                          onClick={this.handleDelete}
                          style={{ backgroundColor: "#2B08A0", color: "white" }}
                        >
                          Delete
                        </button>
                      </Link>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>

              <div className={classes.box}>
                <div>
                  <div className={classes.contactContainer}>
                    <b className={classes.bold}>First Name</b>
                    <div className={classes.nameText}>
                      <TextField
                        className={classes.textField1}
                        value={this.state.first}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </div>
                    <b className={classes.bold}>Last Name</b>
                    <div className={classes.nameText}>
                      <TextField
                        className={classes.textField1}
                        value={this.state.last}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </div>
                    <b className={classes.bold}>Phone</b>
                    <div className={classes.phoneText}>
                      <TextField
                        className={classes.textField1}
                        value={this.state.phone}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </div>
                    <b className={classes.bold}>Email</b>
                    <div className={classes.emailText}>
                      <TextField
                        className={classes.textField1}
                        value={this.state.email}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </div>
                    <b className={classes.bold}>Company</b>
                    <div className={classes.companyText}>
                      <TextField
                        className={classes.textField1}
                        value={this.state.company}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </div>
                    <b className={classes.bold}>Title</b>
                    <div className={classes.titleText}>
                      <TextField
                        className={classes.textField1}
                        value={this.state.title}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.wrapper}>
              {/* <form onSubmit={this.handleSave}> */}
              <form onSubmit={this.handleSaveWrapper}>
                <div className={classes.title}>
                  <div className={classes.titlename}>
                    <Link to={"/contacts"}>
                      <ArrowBackIcon className={classes.arrowBack} />
                    </Link>

                    <FontAwesomeIcon icon={faUser} alt="people" />
                    <h2 className={sharedStyles.mainTitle}>Contact Detail</h2>
                  </div>

                  <div className={classes.buttonGroup}>
                    <button
                      type="submit"
                      className={sharedStyles.primaryButton}
                      style={{
                        margin: "5px",
                        backgroundColor: "#2B08A0",
                        color: "white",
                      }}
                    >
                      Save
                    </button>
                    <button
                      className={sharedStyles.primaryButton}
                      onClick={this.toggleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div className={classes.box}>
                  <div>
                    <div className={classes.contactContainer}>
                      <b className={classes.bold}>First Name*</b>
                      <div className={classes.nameText}>
                        <TextField
                          className={classes.textField2}
                          value={this.state.first}
                          onChange={this.handleChangeFirst}
                          variant="outlined"
                        />
                      </div>
                      <b className={classes.bold}>Last Name*</b>
                      <div className={classes.nameText}>
                        <TextField
                          className={classes.textField2}
                          value={this.state.last}
                          onChange={this.handleChangeLast}
                          variant="outlined"
                        />
                      </div>
                      <b className={classes.bold}>Phone</b>
                      <div className={classes.phoneText}>
                        <TextField
                          className={classes.textField2}
                          value={this.state.phone}
                          onChange={this.handleChangePhone}
                          variant="outlined"
                          inputProps={{
                            maxLength: 17,
                            minLength:10,
                          }}
                        />
                      </div>
                      <b className={classes.bold}>Email</b>
                      <div className={classes.emailText}>
                        <TextField
                          className={classes.textField2}
                          value={this.state.email}
                          onChange={this.handleChangeEmail}
                          variant="outlined"
                        />
                      </div>

                      <b className={classes.bold}>Company</b>
                      <div className={classes.companyText}>
                        <TextField
                          className={classes.textField2}
                          onChange={this.handleChangeCompany}
                          value={this.state.company}
                          variant="outlined"
                        />
                      </div>

                      <b className={classes.bold}>Title</b>
                      <div className={classes.titleText}>
                        <TextField
                          className={classes.textField2}
                          onChange={this.handleChangeTitle}
                          value={this.state.title}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.title}>
              <div className={classes.titlename}>
                <Link to={"/contacts"}>
                  <ArrowBackIcon className={classes.arrowBack} />
                </Link>

                <FontAwesomeIcon icon={faUser} alt="people" />
                <h2 className={sharedStyles.mainTitle}>Contact Detail</h2>
              </div>

              <div className={classes.buttonGroup}>
                <div
                  className={sharedStyles.primaryButton}
                  style={{
                    margin: "5px",
                    backgroundColor: "#2B08A0",
                    color: "white",
                  }}
                >
                  <BtnLoader />
                </div>

                <button
                  className={sharedStyles.primaryButton}
                  style={{
                    backgroundColor: "grey",
                    borderColor: "grey",
                    color: "white",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>

            <div className={classes.box}>
              <div>
                <div className={classes.contactContainer}>
                  <b className={classes.bold}>First Name*</b>
                  <div className={classes.nameText}>
                    <TextField
                      disabled
                      className={classes.textField2}
                      value={this.state.first}
                      onChange={this.handleChangeFirst}
                      variant="outlined"
                    />
                  </div>
                  <b className={classes.bold}>Last Name*</b>
                  <div className={classes.nameText}>
                    <TextField
                      disabled
                      className={classes.textField2}
                      value={this.state.last}
                      onChange={this.handleChangeLast}
                      variant="outlined"
                    />
                  </div>
                  <b className={classes.bold}>Phone</b>
                  <div className={classes.phoneText}>
                    <TextField
                      disabled
                      className={classes.textField2}
                      value={this.state.phone}
                      onChange={this.handleChangePhone}
                      variant="outlined"
                    />
                  </div>
                  <b className={classes.bold}>Email</b>
                  <div className={classes.emailText}>
                    <TextField
                      disabled
                      className={classes.textField2}
                      value={this.state.email}
                      onChange={this.handleChangeEmail}
                      variant="outlined"
                    />
                  </div>

                  <b className={classes.bold}>Company</b>
                  <div className={classes.companyText}>
                    <TextField
                      disabled
                      className={classes.textField2}
                      onChange={this.handleChangeCompany}
                      value={this.state.company}
                      variant="outlined"
                    />
                  </div>

                  <b className={classes.bold}>Title</b>
                  <div className={classes.titleText}>
                    <TextField
                      disabled
                      className={classes.textField2}
                      onChange={this.handleChangeTitle}
                      value={this.state.title}
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ContactFullPage);
