import React from "react";
import Sidebar from "./Components/Sidebar/Sidebar";


function Layout(props) {
  return (
    <div>
      <Sidebar
        history={props.history}
        profileData={props.profileData}
        importGetProfileInfo={props.importGetProfileInfo}
      />
      {props.children}
    </div>
  );
}

export default Layout;
