import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
	CompanyInfoCard,
	ContactInfoCard,
	PaymentInfoCard,
	PlanInfoCard,
} from "./";
import Senders from "./Senders";
import "./Profile.css";
// import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
// import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import userLogo from "../../Assets/icons/mdi_account-circle-outline_active.png";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import {
	handleGETprofileBox,
	updateUser,
	getPlanDataWithId,
	checkForToken,
} from "../../APIManager";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import ClipLoader from "react-spinners/ClipLoader";
import sharedStyles from "../../Components/SharedStyles.module.css";
// import styles from "./Plan.module.css";
import styles from "../Plans_Page/Plan.module.css";

import { toast } from "react-toastify";

import { storage } from "../../firebase/firebase";

//Profile Main component is the wrapper that holds the majority of the logic.
export default function Profile(props) {
	const [userProfile, setUserProfile] = useState("");
	const [uploadedImage, setUploadedImage] = useState(null);
	const [updatedUserProfile, setUpdatedUserProfile] = useState({});
	const [planInfo, setPlanInfo] = useState({});

	const { promiseInProgress } = usePromiseTracker();

	const history = useHistory();

	const importUserData = async () => {
		const user = await handleGETprofileBox();

		if (user != undefined) {
			const {
				company,
				credit,
				email,
				first,
				last,
				phoneNumber,
				planId,
				planName,
				revenue,
				size_of_company,
				uid,
				sendersForNotification,
			} = user;

			const planData = await getPlanDataWithId(planId);
			setPlanInfo(planData);

			const initialUserObj = {
				company: company,
				credit: credit,
				email: email,
				first: first,
				last: last,
				phoneNumber: phoneNumber,
				planId: planId,
				planName: planName,
				revenue: revenue,
				size_of_company: size_of_company,
				uid: uid,
				sendersForNotification: sendersForNotification,
			};
			if (user.company_logo !== undefined)
				initialUserObj.company_logo = user.company_logo;

			if (user.last4 !== undefined) initialUserObj.last4 = user.last4;

			setUserProfile(initialUserObj);
			setUpdatedUserProfile(initialUserObj);
		}
	};

	useEffect(() => {
		let unmounted = false;

		if (!unmounted && !checkForToken()) {
			history.push("/login");
			setTimeout(() => {
				toast.error(
					"You've been logged out. This may have been caused by using more than one device or browser",
					{
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}, 1000);
			return;
		} else {
			trackPromise(importUserData());
		}
	}, []);

	const createAndSetImgURL = async (e) => {
		let reader = new FileReader();

		reader.onload = function (e) {
			setUpdatedUserProfile({
				...updatedUserProfile,
				company_logo: reader.result,
			});
		};
		if (e.target.files[0]) {
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	async function handleFireBaseUpload() {
		if (uploadedImage === null) {
			console.error(
				`not an image, the image file is a ${typeof uploadedImage}`
			);
		}
		try {
			const file = uploadedImage;
			// Create the file metadata
			const metadata = {
				contentType: "image/jpeg",
			};
			const storageRef = storage.ref();

			const fileRef = storageRef.child(`/company_logo/${userProfile.uid}`);

			const uploadTaskSnapshot = await fileRef.put(file, metadata);

			const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

			return downloadURL;
		} catch (error) {
			console.log("ERR ===", error);
			alert("Image uploading failed!");
			return updatedUserProfile.company_logo;
		}
	}

	const mountPhotoToState = async (e) => {
		if (e.target.files[0]) {
			setUploadedImage(e.target.files[0]);
			await createAndSetImgURL(e);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUpdatedUserProfile({
			...updatedUserProfile,
			[name]: value,
		});
	};
	const handleDelete = () => {
		updatedUserProfile.sendersForNotification= ""		
		console.log(updatedUserProfile)


		setUpdatedUserProfile(
			updatedUserProfile,
			
		);

		console.log(updatedUserProfile)
	};

	const discardChanges = () => {
		setUpdatedUserProfile(userProfile);
		setUploadedImage(null);
	};
	const saveChanges = async () => {
		if (uploadedImage !== null) {
			const fireBaseUrl = await trackPromise(handleFireBaseUpload());

			await trackPromise(
				updateUser({
					...updatedUserProfile,
					company_logo: fireBaseUrl,
				})
			);
			await trackPromise(importUserData());
			await props.importGetProfileInfo();

			props.handleLogOut(history);
		} else {
			await trackPromise(updateUser(updatedUserProfile));
			await trackPromise(importUserData());
			await props.importGetProfileInfo();
		}
	};

	useEffect(() => {
		let unmounted = false;

		if (!unmounted && checkForToken() === "") {
			history.push("/login");
			setTimeout(() => {
				toast.error(
					"You've been logged out. This may have been caused by using more than one device or browser",
					{
						position: "top-center",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
			}, 1000);
		}

		return () => {
			unmounted = true;
		};
	}, [checkForToken()]);
	return (
		<Box className={sharedStyles.mainContainer} ml={-10} mr={-15}>
			{promiseInProgress ? (
				<div
					style={{
						height: "90vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						position: "absolute",
					}}
				>
					<ClipLoader
						size={150}
						color={"#123abc"}
						loading={promiseInProgress}
						speedMultiplier={1}
					/>
				</div>
			) : (
				<div>
					<Box display="flex" alignItems="center" mb={-3}>
						<Box className={styles.mainTitleContainer} flexGrow={12}>
							<img src={userLogo} className={sharedStyles.logo} />
							<h2 className={sharedStyles.mainTitle}>Profile</h2>
						</Box>

						<Box mt={-3} flexShrink={1} mr={-3}>
							<button
								onClick={() => {
									localStorage.clear();
									history.push("/login");
								}}
								className={sharedStyles.logoutButton}
								style={{ marginRight: "5px" }}
							>
								Logout
							</button>
						</Box>
					</Box>
					<Box ml={-1} mr={-2}>
						<Senders
							updatedUserProfile={updatedUserProfile}
							handleChange={handleChange}
							discardChanges={discardChanges}
							saveChanges={saveChanges}
							handleDelete={handleDelete}
						/>
						<ContactInfoCard
							updatedUserProfile={updatedUserProfile}
							handleChange={handleChange}
							discardChanges={discardChanges}
							saveChanges={saveChanges}
						/>
						<CompanyInfoCard
							mountPhotoToState={mountPhotoToState}
							updatedUserProfile={updatedUserProfile}
							handleChange={handleChange}
							discardChanges={discardChanges}
							saveChanges={saveChanges}
						/>
						{/* <PlanInfoCard
              updatedUserProfile={updatedUserProfile}
              planInfo={planInfo}
            /> */}
						<PaymentInfoCard
							updatedUserProfile={updatedUserProfile}
							handleChange={handleChange}
							discardChanges={discardChanges}
							saveChanges={saveChanges}
						/>
					</Box>
				</div>
			)}
		</Box>
	);
}

{
	/* <Box display="flex" alignItems="center" flexGrow={1}>
                <FontAwesomeIcon
                  style={{
                    color: "3020ae",
                  }}
                  icon={faUserCircle}
                  alt="user icon"
                  size="2x"
                />

                <Typography variant="h4" className={sharedStyles.mainTitle}>
                  Profile
                </Typography>
              </Box> */
}
